import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, Validators, FormBuilder, NgControl, AbstractControl } from '@angular/forms';
import { MatFormFieldControl, MAT_FORM_FIELD, MatFormField } from '@angular/material/form-field';
import { Subject } from 'rxjs';


export class Tel {
    constructor(public area: string, public exchange: string, public subscriber: string) { }
}

/** Custom `MatFormFieldControl` for telephone number input. */
@Component({
    selector: 'app-tel-input',
    templateUrl: './tel-input.component.html',
    styleUrls: ['./tel-input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: TelInputComponent }],
    host: {
        '[class.floating]': 'shouldLabelFloat',
        '[id]': 'id',
    },
})
export class TelInputComponent implements ControlValueAccessor, MatFormFieldControl<Tel>, OnDestroy {
    static nextId = 0;
    @ViewChild('area') areaInput: HTMLInputElement;
    @ViewChild('exchange') exchangeInput: HTMLInputElement;
    @ViewChild('subscriber') subscriberInput: HTMLInputElement;
    @Input("invalid") invalid: boolean;

    parts = this._formBuilder.group({
        area: ['', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(3),
            Validators.pattern('[0-9]+'),
        ]],
        exchange: ['', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(3),
            Validators.pattern('[0-9]+'),
        ]],
        subscriber: ['', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.pattern('[0-9]+'),
        ]],
    });
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'tel-input';
    id = `tel-input-${TelInputComponent.nextId++}`;
    onChange = (_: any) => { };
    onTouched = () => { };

    get empty() {
        const {
            value: { area, exchange, subscriber },
        } = this.parts;

        return !area && !exchange && !subscriber;
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input('aria-describedby') userAriaDescribedBy: string;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.parts.disable() : this.parts.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): Tel | null {
        if (this.parts.valid) {
            const {
                value: { area, exchange, subscriber },
            } = this.parts;
            return new Tel(area!, exchange!, subscriber!);
        }
        return null;
    }
    set value(tel: Tel | null) {
        const { area, exchange, subscriber } = tel || new Tel('', '', '');
        this.parts.setValue({ area, exchange, subscriber });
        this.stateChanges.next();
    }

    get errorState(): boolean {
        return (this.parts.invalid && this.touched) || this.invalid;
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
        if (!control.errors && nextElement) {
            this._focusMonitor.focusVia(nextElement, 'program');
        }
    }

    autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
        if (control.value.length < 1) {
            this._focusMonitor.focusVia(prevElement, 'program');
        }
    }

    setDescribedByIds(ids: string[]) {
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.tel-input-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick() {
        if (this.parts.controls.subscriber.valid) {
            this._focusMonitor.focusVia(this.subscriberInput, 'program');
        } else if (this.parts.controls.exchange.valid) {
            this._focusMonitor.focusVia(this.subscriberInput, 'program');
        } else if (this.parts.controls.area.valid) {
            this._focusMonitor.focusVia(this.exchangeInput, 'program');
        } else {
            this._focusMonitor.focusVia(this.areaInput, 'program');
        }
    }

    writeValue(tel: Tel | null): void {
        this.value = tel;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
        this.autoFocusNext(control, nextElement);
        this.onChange(this.value);
    }
}
