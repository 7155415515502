import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-tutorial',
  templateUrl: './ticket-tutorial.component.html',
  styleUrls: ['./ticket-tutorial.component.scss']
})
export class TicketTutorialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
