import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '../../utils/index';
import { CustomErrorStateMatcher } from '../register/register.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public formError: string = '';
    public formSuccess: string = '';
    public form: UntypedFormGroup;
    public matcher = new CustomErrorStateMatcher();
    public savingText: string = "Sending request";
    public okText: string = "OK";

    constructor(
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthService
    ) {
        this.translate.get([this.savingText, this.okText], {}).subscribe((trans) => {
            this.savingText = trans[this.savingText];
            this.okText = trans[this.okText];
        });
        this.clearErrors();
    }

    private clearErrors() {
        this.formError = '';
    }

    ngOnInit() {
        const token = this.activatedRoute.snapshot.params['token'];
        const email = this.activatedRoute.snapshot.queryParams['email'];
        this.form = this.fb.group({
            token: [token, Validators.required],
            email: [email, Validators.email],
            password: ['', Validators.required],
            password_confirmation: ['', Validators.required],
        }, {
            validators: [this.passwordConfirmedValidator]
        });
        document.getElementsByTagName('body')[0].classList.add('login-page');
    }

    ngOnDestroy() {
        document.getElementsByTagName('body')[0].classList.remove('login-page');
    }

    private passwordConfirmedValidator(form: UntypedFormGroup) {
        const matches = form.get('password').value === form.get('password_confirmation').value;
        return !matches ? { passwordNotConfirmed: true } : null;
    }

    public onSubmit(): void {
        this.clearErrors();
        if (!this.form.valid) {
            return;
        }
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.savingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        let data = this.form.value;
        this.authenticationService.resetPassword(data).then((response) => {
            this.formSuccess = response.message;
        })
        .catch((response) => {
            if (response.status === 422) {
                this._snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
            else if (response.message === 'Not token') {
                let snackBarRef = this._snackBar.open("No fue posible enviar la petición debido a un problema de autenticación, por favor recargue la página y vuelva a intentarlo", this.okText, {
                    duration: 15000
                });
                snackBarRef.afterDismissed().subscribe(null, null, () => {
                    window.location.reload();
                });
            }
        })
        .then(() => {
            ref.close();
        });
    }
}
