import {Component, ChangeDetectorRef, OnDestroy, AfterViewInit, ViewChild, OnInit, Inject} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';
import {Subscription, filter, map} from 'rxjs';
import {MatSidenav} from '@angular/material/sidenav';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BROWSER_STORAGE } from 'src/app/storage';
import { Utils } from 'src/app/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('snav', {static: false}) sidenav: MatSidenav;
    public user: User;
    public subscription: Subscription;
    public routerSubscription: Subscription;
    public env: string;
    public badgeHeight: number = 43;
    public headerHeight: number = 56;

    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    public currentUrl: string;
    public enterText: string = "At Moneo we are concerned with responsible consumption and we prevent the sale of alcohol to minors. Please answer responsibly.";
    public cancelButtonText: string = "Leave, I'm under 18";
    public confirmButtonText: string = "Enter, I'm over 18";

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        public changeDetectorRef: ChangeDetectorRef,
        public media: MediaMatcher,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        public googleAnalyticsService: GoogleAnalyticsService,
        public translate: TranslateService,
        public dialog: MatDialog,
    ) {
        this.env = environment.env;
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.user = this.authService.getCurrentUser();
        // Escuchar cambios al usuario autenticado
        this.subscription = this.userService.authUserUpdated$.subscribe(user => this.user = user);
    }

    ngOnInit() {
        this.currentUrl = this.router.url;
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map((event: NavigationEnd) => event.url)
        ).subscribe((name: string) => {
            this.currentUrl = name;
            this.showAgeGateDialog();
        });
        this.showAgeGateDialog();
    }

    private showAgeGateDialog() {
        if (['/auth/login', '/auth/register'].indexOf(this.currentUrl) === -1) {
            return;
        }
        if (this.storage.getItem('isOver18') == '1') {
            return;
        }
        this.translate.get([this.enterText, this.cancelButtonText, this.confirmButtonText, "OK"], {}).subscribe((trans) => {
            this.enterText = trans[this.enterText];
            this.cancelButtonText = trans[this.cancelButtonText];
            this.confirmButtonText = trans[this.confirmButtonText];
            Utils.confirm(this.dialog, {
                disableClose: true,
                data: {
                    message: this.enterText,
                    confirmButtonText: this.confirmButtonText,
                    cancelButtonText: this.cancelButtonText,
                    onCancel: () => {
                        window.location.href = 'http://www.alcoholinformate.org.mx';
                    },
                    callback: () => {
                        this.storage.setItem('isOver18', '1');
                    }
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.env !== 'prod') {
            var ele = document.getElementsByClassName('dev-badge')[0] as any;
            this.badgeHeight = ele.offsetHeight;
            setTimeout(() => {
                this.headerHeight = 56 + this.badgeHeight;
            }, 500);
        }
    }

    public toggleSidenav() {
        this.sidenav.toggle();
        if (this.sidenav.opened) {
            this.googleAnalyticsService.eventEmitter("open_menu", "Menu");
        }
    }
}
