import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-questionnaires',
    templateUrl: './questionnaires.component.html',
    styleUrls: ['./questionnaires.component.scss']
})
export class QuestionnairesComponent implements OnInit {
    public user: User;
    public userSubscription: Subscription;
    
    constructor(
        private authService: AuthService,
        private userService: UserService,
    ) {
        this.user = this.authService.getCurrentUser();
    }

    ngOnInit(): void {
        this.userSubscription = this.userService.authUserUpdated$.subscribe(user => this.user = user);
    }

}
