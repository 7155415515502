import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { PrivacyComponent } from '../../pages/privacy/privacy.component';

@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {
    private COOKIE_VALUE :number = 1;
    private COOKIE_NAME :string = 'moneo_cookie_consent';
    private COOKIE_LIFETIME :number = 365 * 20;
    private COOKIE_DOMAIN :string = environment.env === 'local' ? 'localhost' : (new URL(environment.webAppUrl)).hostname;
    public showCookieConsentMessage :boolean = true;
    private dialogRef :any = null;

    constructor(public dialog: MatDialog) {
        if (this.cookieExists(this.COOKIE_NAME)) {
            this.hideCookieDialog();
        }
    }
    ngOnInit(): void {
        
    }
    
    public consentWithCookies() {
        this.setCookie(this.COOKIE_NAME, this.COOKIE_VALUE, this.COOKIE_LIFETIME);
        this.hideCookieDialog();
    }
    
    private cookieExists(name :string) {
        return (document.cookie.split('; ').indexOf(name + '=' + this.COOKIE_VALUE) !== -1);
    }
    
    private hideCookieDialog() {
        this.showCookieConsentMessage = false;
    }

    private showCookieDialog() {
        this.showCookieConsentMessage = true;
    }
    
    private setCookie(name :string , value :number, expirationInDays :number) {
        const date = new Date();
        date.setTime(date.getTime() + (expirationInDays * 24 * 60 * 60 * 1000));
        document.cookie = name + '=' + value
                + ';expires=' + date.toUTCString()
                + ';domain=' + this.COOKIE_DOMAIN
                + ';path=/'
                + ';samesite=lax';
    }

    public showPrivacy() {   
        if (this.dialogRef !== null) {
            return;
        }     
        this.dialogRef = this.dialog.open(PrivacyComponent, {
            data: {}
        });
        this.hideCookieDialog();
        this.dialogRef.afterClosed().subscribe(result => {
            this.dialogRef = null;
            this.showCookieDialog();
        });
    }
}




