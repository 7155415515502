import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { WindowService } from '../../services/window.service';
import { MatomoService } from '../../services/matomo.service';
import { User } from './../../models/user';

@Component({
    selector: 'app-key-actions',
    templateUrl: './key-actions.component.html',
    styleUrls: ['./key-actions.component.scss']
})
export class KeyActionsComponent implements OnInit {
    @Input("user") public user: User;

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private router: Router,
        private dialog: MatDialog,
        public windowService: WindowService,
        private matomoService: MatomoService,
    ) { }

    ngOnInit(): void {
    }

    public redeem() {
        this.googleAnalyticsService.eventEmitter("click_redeem_on_key_actions", "Cashbacks");
        this.router.navigate(['redeem']);
    }

    public profile() {
        this.googleAnalyticsService.eventEmitter("click_profile_on_key_actions", "engagement");
        this.matomoService.trackEvent('Profile', 'show_from_key_actions');
        this.router.navigate(['profile']);
    }

    public showTutorial() {
        this.googleAnalyticsService.eventEmitter("click_show_tutorial_on_key_actions", "Tutorial");
        this.router.navigate(['tutorial']);
    }

    public inviteFriends() {
        this.googleAnalyticsService.eventEmitter("click_invite_friends_on_key_actions", "Referral");
        this.router.navigate(['invite-friends']);
    }
}
