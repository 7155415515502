<mat-card appearance="raised" class="register-content">
    <mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title class="p-0">{{ "Request transfer" | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field class="no-fill">
                <mat-label>{{ "Account clabe" | translate }}</mat-label>
                <input type="text" matInput formControlName="account_clabe" required [errorStateMatcher]="matcher">
                <mat-error *ngIf="form.get('account_clabe').errors?.['required']">
                    {{ 'Please fill this field' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('account_clabe').errors?.['pattern']">
                    {{ 'Please enter a valid CLABE. CLABE must be a string of 18 digits' | translate }}
                </mat-error>
                <mat-error *ngIf="form.hasError('clabeNotConfirmed')">
                    {{ "The CLABE confirmation does not match" | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('account_clabe').errors?.['serverError']">
                    {{ form.get('account_clabe').errors?.['serverError'] }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="no-fill">
                <mat-label>{{ "Account clabe confirmation" | translate }}</mat-label>
                <input type="text" matInput formControlName="account_clabe_confirmation">
                <mat-error *ngIf="form.get('account_clabe_confirmation').errors?.['required']">
                    {{ 'Please fill this field' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('account_clabe_confirmation').errors?.['pattern']">
                    {{ 'Please enter a valid CLABE. CLABE must be a string of 18 digits' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('account_clabe_confirmation').errors?.['serverError']">
                    {{ form.get('account_clabe_confirmation').errors?.['serverError'] }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="no-fill">
                <mat-label>{{ "Account full name" | translate }}</mat-label>
                <input type="text" matInput formControlName="account_fullname">
                <mat-error *ngIf="form.get('account_fullname').errors?.['required']">
                    {{ 'Please fill this field' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('account_fullname').errors?.['serverError']">
                    {{ form.get('account_fullname').errors?.['serverError'] }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="no-fill">
                <mat-label>{{ "Account bank" | translate }}</mat-label>
                <mat-select formControlName="account_bank" required>
                    <mat-option *ngFor="let bank of banks" [value]="bank.name">
                        {{ bank.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('account_bank').errors?.['required']">
                    {{ 'Please fill this field' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('account_bank').errors?.['serverError']">
                    {{ form.get('account_bank').errors?.['serverError'] }}
                </mat-error>
            </mat-form-field>
            <p class="text-info">Necesitas al menos $25 pesos de ganancias para solicitar una transferencia.</p>
            <div class="row">
                <div class="col-12 pt-2">
                    <ul class="pl-3 mb-4 text-left">
                        <li>Es necesario que el titular de la cuenta bancaria y el nombre del Usuario coincidan.</li>
                        <li>No podemos enviar tus ganancias a ningún tercero. Consulta los Términos y Condiciones para más información.</li>
                    </ul>
                    <p class="accept-terms">
                        <mat-checkbox matInput formControlName="accept_terms" name="accept_terms" required>
                            {{ "I give Moneo my consent to use my financial data for the service purposes" | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="form.get('accept_terms').errors?.['required']" style="padding: 0 16px;">
                            {{ 'You must accept terms.' | translate }}
                        </mat-error>
                    </p>
                    <div class="text-center"  *ngIf="balance && balance.available >= 25">
                        <button type="submit" mat-raised-button color="primary" [disabled]="transferRequested">
                            {{ 'Send me my money!' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>