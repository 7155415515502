import { IPhoneRechargeProduct, PhoneRechargeProduct } from "./phone-recharge-product";
import { IUser, User } from "./user";
import moment from 'moment';

export interface IRequestPhoneRecharge {
    phone: string;
    phone_confirmation?: string;
    phone_recharge_product_id: number;
    accept_terms: boolean;
}

export interface IPhoneRecharge {
    id: number;
    phone: string;
    amount: number;
    transaction_number: string;
    authorization_number: string;
    source: string;
    status: string;
    details: string;
    created_at: string;
    user?: IUser;
    phone_recharge_product?: IPhoneRechargeProduct;
}

export class PhoneRecharge {
    public constructor(
        public id: number,
        public phone: string,
        public amount: number,
        public transactionNumber: string,
        public authorizationNumber: string,
        public source: string,
        public status: string,
        public details: string,
        public createdAt: Date,
        public user?: User,
        public phoneRechargeProduct?: PhoneRechargeProduct,
    ) { }

    public static fromJson(data: IPhoneRecharge): PhoneRecharge {
        return new PhoneRecharge(
            data.id,
            data.phone,
            data.amount,
            data.transaction_number,
            data.authorization_number,
            data.source,
            data.status,
            data.details,
            new Date(data.created_at),
            data.user ? new User(data.user) : null,
            data.phone_recharge_product ? PhoneRechargeProduct.fromJson(data.phone_recharge_product) : null,
        );
    }

    public get datetime(): string {
        return this.createdAt ? moment(this.createdAt).format('DD/MM/YYYY HH:mm:ss') : '';
    }

    public get statusName(): string {
        const result = {
            approved: "Aprobado",
            error: "Error",
            rejected: "Rechazado"
        };
        return result[this.status] ?? '';
    }
}