<div class="adverts position-relative" #advertsDiv (swipe)="onSwipe($event)">
    <div class="adverts__close_btn position-absolute p-2" (click)="close()">
        <i class="fas fa-times"></i>
    </div>
    <section class="adverts__body">
        <ngb-carousel #carousel [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="0" class="h-100" (slide)="onSlide($event)">
            <ng-template ngbSlide [id]="'slide-' + i" *ngFor="let ad of data; index as i">
                <div class="ad-img-wrapper h-100">
                    <img class="ad-img d-block mw-100 mh-100 mx-auto" [src]="ad.image" alt="Imagen">
                    <div class="ad-action">
                        <a href="#" (click)="$event.preventDefault(); dontShowAgain(ad);">
                            {{ "Don't show again" | translate }}
                        </a>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
        <div class="adverts__indicators d-flex align-items-center justify-content-center" *ngIf="data.length > 1">
            <div class="adverts__indicator"
                [class]="{'active': activeSlide === 'slide-' + i}"
                [class.mx-1]="i === 1"
                (click)="select(i)"
                *ngFor="let ad of data; index as i">
            </div>
        </div>
    </section>
</div>
