<h5 class="fw-bold mt-4 mb-2 px-3">Por favor completa la siguiente información:</h5>


<form [formGroup]="form" class="py-3 px-3">

    <mat-form-field class="no-fill" style="width: 100%;">
        <mat-label class="fs-18px">{{ 'Gender' | translate }}</mat-label>
        <mat-select formControlName="gender" class="fs-16px">
            <mat-option value="">{{ 'Select an option' | translate }}</mat-option>
            <mat-option value="female">{{ 'female' | translate }}</mat-option>
            <mat-option value="male">{{ 'male' | translate }}</mat-option>
            <mat-option value="rather not say">{{ 'rather not say' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="no-fill" style="width: 100%;">
        <mat-label class="fs-18px">{{ 'Birthday' | translate }}</mat-label>
        <input matInput class="fs-16px" [max]="maxBirthday" [matDatepicker]="picker" formControlName="birthday">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>
            {{ 'Enter a date' | translate }}. Ej. 04/12/1993
        </mat-hint>
        <mat-error *ngIf="form.get('birthday').errors?.['serverError']">
            {{ form.get('birthday').errors?.['serverError'] }}
        </mat-error>
    </mat-form-field>
    <div class="mt-2">
        <mat-checkbox color="primary" [(ngModel)]="notShowPage" [ngModelOptions]="{standalone: true}">
            <span class="fw-bold" style="color: #ED028C;">No volver a mostrar</span>
        </mat-checkbox>
    </div>
    <div class="mt-2">
        <button type="button" mat-raised-button color="primary" (click)="save()" [disabled]="notShowPage">
            {{ "Save" | translate }}
        </button>
        <button type="button" class="ms-3" mat-raised-button color="secondary" (click)="skip()">
            {{ "Skip" | translate }}
        </button>
    </div>
</form>