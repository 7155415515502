import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WindowService } from '../../services/window.service';
import { Offer } from '../../models/offer';
import { Commerce } from './../../models/commerce';
import { OfferService } from '../../services/offer.service';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
    @Output() commerceLoaded: EventEmitter<Commerce> = new EventEmitter();
    @Output() cardClick: EventEmitter<Offer> = new EventEmitter();
    @Output() shareClick: EventEmitter<Offer> = new EventEmitter();
    @Input("commerceId") public commerceId?: string;
    @Input("commerceName") public commerceName?: string;
    @Input("offerId") public offerId?: string;
    @Input("sortBy") public sortBy: string;
    @Input("isGuestUser") public isGuestUser?: boolean;
    @Input("appendMore") public appendMore?: boolean;
    @Input("numItems") public numItems: number;
    @Input("isAmy") public isAmy: boolean;
    @Input("showCommerceLogo") public showCommerceLogo: boolean = false;
    @Input("reload") public reload?: boolean;
    @Input("limitCommerceOffers") public limitCommerceOffers?: number;
    public offers: Offer[][] = [];
    public offersPerRow: number = 2;
    public loadingItems: number[][] = [];
    public loading: boolean = false;
    public commerce: Commerce = null;

    constructor(
        private offerService: OfferService,
        private windowService: WindowService,
    ) {
        this.isGuestUser = false;
        if (this.windowService.innerWidth >= 1200) {
            this.offersPerRow = 6;
        }
        else if (this.windowService.innerWidth >= 992) {
            this.offersPerRow = 4;
        }
        else if (this.windowService.innerWidth >= 768) {
            this.offersPerRow = 3;
        }
        for (let i = 0; i < 8; i++) {
            if (this.loadingItems.length === 0 || this.loadingItems[this.loadingItems.length - 1].length === this.offersPerRow) {
                this.loadingItems.push([]);
            }
            this.loadingItems[this.loadingItems.length - 1].push(i);
        }
    }

    ngOnInit(): void {
        this.fetchOffers(this.reload);
    }

    public refresh(commerceId: string) {
        this.commerceId = commerceId;
        this.offers = [];
        this.fetchOffers(true);
    }

    private fetchOffers(reload: boolean = false): void {
        this.loading = true;
        let params = {
            "no-paginate": 1,
            is_active: 1,
            sort: this.sortBy ? this.sortBy : "percent|desc",
            no_hybrid: 1,
            include: 'commerce.logo,commerce.photo,product.photo,product.masterProduct.photo'
        };
        if (this.commerceId) {
            params['commerce'] = this.commerceId;
        }
        if (this.commerceName) {
            params['commerce_name'] = this.commerceName;
        }
        if (this.offerId) {
            params['offer'] = this.offerId;
        }
        if (this.appendMore) {
            params['append_more'] = 1;
        }
        if (this.numItems) {
            params['take'] = this.numItems;
        }
        if (this.limitCommerceOffers) {
            params['limit_commerce_offers'] = this.limitCommerceOffers;
        }
        let offers: Promise<Offer[]>;
        if (!this.isGuestUser) {
            offers = this.offerService.getOffers(params, reload);
        } else {
            offers = this.offerService.getGuestOffers(params);
        }
        offers.then((result) => {
            if (result.length > 0) {
                this.commerceLoaded.emit(result[0].commerce);
                if (this.commerceId || this.commerceName) {
                    this.commerce = result[0].commerce;
                }
            }
            for (let i = 0; i < result.length; i++) {
                if (this.offers.length === 0 || this.offers[this.offers.length - 1].length === this.offersPerRow) {
                    this.offers.push([]);
                }
                this.offers[this.offers.length - 1].push(result[i]);
            }
            this.loading = false;
        }).catch(error => {});
    }

    public showDetails(event: any, offer: Offer) {
        if (this.isShareButton(event.target)) {
            return;
        }
        this.cardClick.emit(offer);
    }

    public shareWa(event: any, offer: Offer) {
        event.preventDefault();
        this.shareClick.emit(offer);
    }

    public isShareButton(ele: any) {
        if (ele.tagName === 'BODY') {
            return false;
        }
        if (ele.tagName === 'A' && ele.classList.contains('btn-share')) {
            return true;
        }
        return this.isShareButton(ele.parentElement);
    }
}
