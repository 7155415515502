import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    constructor() { }

    public eventEmitter(
        action: string,
        category: string,
        label: string = null,
        value: number = null
    ) {
        if (environment.env !== 'local') {
            gtag('event', action, {
                event_category: category,
                event_label: label,
                value: value
            });

            gtag('event', action, {
                sent_to: environment.analytics.trackingCodeGA4,
                event_category: category,
                event_label: label,
                value: value
            });
        }
    }

    public setCurrentUser(userId: number) {
        if (environment.env !== 'local') {
            gtag('set', {'user_id': userId});
            gtag('set', {'sent_to': environment.analytics.trackingCodeGA4, 'user_id': userId});
        }
    }
}