import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WindowService } from 'src/app/services/window.service';
import { NgbSlideEvent, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit, AfterViewInit {
    @ViewChild('benefitsDiv', {static: false}) benefitsDiv: ElementRef;
    @ViewChild('carousel', {static: false}) carousel: NgbCarousel;
    public activeSlide: string = 'slide-0';
    public tutorialIdx: number = 0;

    constructor(
        public dialogRef: MatDialogRef<BenefitsComponent>,
        public windowService: WindowService,
        @Inject(MAT_DIALOG_DATA) public data: { [k: string]: any }
    ) { }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.setRootDivWidth();
    }

    @HostListener('window:resize')
    onResize() {
        this.setRootDivWidth();
    }

    public closeModal() {
        this.dialogRef.close();
    }

    private setRootDivWidth() {
        let divWidth = this.benefitsDiv.nativeElement.offsetWidth;
        this.benefitsDiv.nativeElement.style.width = divWidth + 'px';
    }

    public onSlide(payload: NgbSlideEvent) {
        this.activeSlide = payload.current;
    }

    public next() {
        this.carousel.next();
    }

    public select(index: number) {
        this.tutorialIdx = index;
        this.carousel.select(`slide-${index}`);
    }

    public tutorialNext() {
        if (this.tutorialIdx == 2) {
            this.dialogRef.close();
        } else {
            this.tutorialIdx++;
            this.carousel.next();
        }
    }

    public tutorialPrevious() {
        if (this.tutorialIdx > 0) {
            this.tutorialIdx--;
            this.carousel.prev();
        }
    }

    public onSwipe(evt) {
        const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
        const y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
        if (x === 'left') {
            this.tutorialNext();
        }
        else {
            this.tutorialPrevious();
        }
    }
}
