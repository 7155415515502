import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TermsConditionsComponent } from '../../pages/terms-conditions/terms-conditions.component';
import { PrivacyComponent } from '../../pages/privacy/privacy.component';
import { TutorialComponent } from '../../pages/tutorial/tutorial.component';
import { BenefitsComponent } from '../../pages/benefits/benefits.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { WindowService } from '../../services/window.service';
import { User } from '../../models/user';
import { Balance } from '../../models/balance';
import { Utils } from '../../utils/index';
import { MatomoService } from '../../services/matomo.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
    @Input("user") public user: User;
    public balance: Balance;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        public translate: TranslateService,
        public windowService: WindowService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private matomoService: MatomoService,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        let showTutorial = this.activatedRoute.snapshot.queryParams['show-tutorial'];
        if (showTutorial == 1) {
            this.showTutorial(true);
        }

        this.userService.myBalance$.subscribe((balance) => {
            this.balance = balance;
        });
    }

    public logout(): void {
        const self = this;
        let title = "Logout";
        let msg = "Are you sure to finish your session?";
        this.translate.get(["Yes", "Cancel", "OK", "Logging out", title, msg], {}).subscribe((trans) => {
            Utils.confirm(this.dialog, {
                data: {
                    title: trans[title],
                    message: trans[msg],
                    confirmButtonText: trans["Yes"],
                    cancelButtonText: trans["Cancel"],
                    callback: () => {
                        let ref = Utils.alert(this.dialog, {
                            data: {
                                message: trans["Logging out"] + '...',
                                showAcceptButton: false,
                                type: 'loading',
                            },
                            disableClose: true
                        });
                        self.authService.logout().finally(() => {
                            self.googleAnalyticsService.eventEmitter("logout", "Auth");
                            self.userService.resetBalance();
                            self.authService.requestToken().catch(error => { });
                            self.router.navigate(['auth/login'], { replaceUrl: true });
                            setTimeout(() => ref.close(), 200);
                        });
                    }
                },
                disableClose: true,
            });
        });
    }

    public goTo(path: string): void {
        this.toggleMenuSidebar.emit();
        let extras: NavigationExtras = {};
        if (path === 'home') {
            extras.replaceUrl = true;
        }
        if (['home', 'profile'].indexOf(path) !== -1) {
            this.googleAnalyticsService.eventEmitter("click_menu_item", "Menu", path);
            if (path === 'profile') {
                this.matomoService.trackEvent('Profile', 'show_from_menu');
            }
        }
        if (path === 'tutorial') {
            this.matomoService.trackEvent('Tutorial', 'show');
        }
        this.router.navigate([path], extras);
    }

    public requestTransfer() {
        if (this.balance.available < 25) {
            Utils.alert(this.dialog, {
                data: {
                    message: "Necesitas acumular al menos MX $25 para solicitar una transferencia.<br>¡Sigue subiendo tus tickets!",
                    type: 'info'
                }
            });
        } else {
            this.toggleMenuSidebar.emit();
            this.router.navigate(['transfer']);
        }
    }

    public showTerms() {
        this.googleAnalyticsService.eventEmitter("click_menu_item", "Menu", "terms_conditions");
        this.toggleMenuSidebar.emit();
        this.dialog.open(TermsConditionsComponent, {
            data: { isGuestUser: this.user ? false : true }
        });
    }

    public showPrivacy() {
        this.googleAnalyticsService.eventEmitter("click_menu_item", "Menu", "privacy");
        this.toggleMenuSidebar.emit();
        this.dialog.open(PrivacyComponent, {
            data: {}
        });
    }

    public showTutorial(isAuto: boolean = false) {
        const dialogConfig = new MatDialogConfig();
        if (!isAuto) {
            this.googleAnalyticsService.eventEmitter("click_menu_item", "Menu", "tutorial");
            this.toggleMenuSidebar.emit();
        }
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-component-tutorial";
        dialogConfig.maxWidth = (this.windowService.innerWidth - 30) + "px";
        this.dialog.open(TutorialComponent, dialogConfig);
    }

    public showBenefits() {
        const dialogConfig = new MatDialogConfig();
        this.googleAnalyticsService.eventEmitter("click_menu_item", "Menu", "benefits");
        this.toggleMenuSidebar.emit();
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-component-benefits";
        dialogConfig.maxWidth = (this.windowService.innerWidth - 30) + "px";
        this.dialog.open(BenefitsComponent, dialogConfig);
    }

    public contact() {
        this.googleAnalyticsService.eventEmitter("click_menu_item", "Menu", "contact");
        window.location.href = "mailto:soporte@moneo.mx";
    }
}
