<div *ngIf="commerce && showCommerceLogo" class="container-fluid mb-3">
    <img src="{{ commerce.logo }}" alt="Commerce logo" class="d-block mx-auto commerce-logo">
</div>
<ng-container *ngFor="let arrOffers of offers">
    <div class="row m-0">
        <div class="col-offer"
                [class.col-6]="offersPerRow === 2"
                [class.col-4]="offersPerRow === 3"
                [class.col-3]="offersPerRow === 4"
                *ngFor="let offer of arrOffers">
            <div class="card card-offer mb-3" (click)="!isAmy && showDetails($event, offer)" matRipple>
                <img [src]="offer.product.masterProduct && offer.product.masterProduct.photo ? offer.product.masterProduct.photo : (offer.product.photo ? offer.product.photo : '/assets/img/no-image.png')"
                    class="card-img-top" alt="Photo"
                    *ngIf="offer && offer.product">
                <img [src]="offer.commerce.photo ? offer.commerce.photo : '/assets/img/no-image.png'"
                    class="card-img-top" alt="Photo"
                    *ngIf="offer && !offer.product">
                <div class="card-body text-center">
                    <h3>{{ offer.name }}</h3>
                    <p>en {{ offer.commerce.name }}</p>
                    <h2 class="percent">{{ offer.percent }}% {{ "Cashback" | translate }}</h2>
                    <div class="row mt-3">
                        <div class="col-12 align-items-center">
                            <a mat-raised-button class="btn-share" (click)="shareWa($event, offer)" href="#">
                                <i class="fab fa-whatsapp mr-1"></i> {{ "Share" | translate }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="loading">
    <ng-container *ngFor="let arrItems of loadingItems">
        <div class="row m-0">
            <div class="col-offer"
                    [class.col-6]="offersPerRow === 2"
                    [class.col-4]="offersPerRow === 3"
                    [class.col-3]="offersPerRow === 4"
                    *ngFor="let item of arrItems">
                <div class="card card-offer offer-loading border-0 mb-3 p-0" >
                    <div class="card-img-top img-box">
                        <span class="skeleton-box" style="width: 100%; height: 100%"></span>
                    </div>
                    <div class="card-body">
                        <h3 class="mb-1"><span class="skeleton-box" style="width: 87%"></span></h3>
                        <p class="mb-1">
                            <span class="skeleton-box" style="width: 80%"></span>
                        </p>
                        <h2 class="mb-2"><span class="skeleton-box" style="width: 90%"></span></h2>
                        <p class="mb-0 text-center">
                            <span class="skeleton-box" style="width: 97%; height: 30px"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>