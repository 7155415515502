
<div class="d-grid mb-3">
    <button type="button" class="btn btn-block btn-social btn-social--facebook"
            (click)="signInWithFacebook()" [disabled]="buttonFacebookDisabled">
        <i class="fab fa-facebook-f"></i>
        {{ textButton | translate }} Facebook
    </button>
</div>
<div class="mb-3">
    <div id="googleButton"></div>
</div>