<div class="message">
    <div class="container">
        <div class="message__content min-vh-100 pt-3 d-flex flex-column justify-content-between">
            <div class="message__logo">
                <img src="assets/img/logo-full.png" alt="Logo" class="d-block mw-100">
            </div>
            <div class="mt-3">
                <p class="message__text">¡Hola!</p>
                <p class="message__text">Parece que estás tratando de ingresar desde tu computadora.</p>
                <p class="message__text">Por favor, intenta otra vez con tu smartphone.</p>
                <p class="message__text">¡Gracias!</p>
            </div>
            <div class="row">
                <div class="col-md-6 order-1 order-md-0 message__img-container">
                    <img src="assets/img/desktop-user-ilustracion.svg" alt="Imagen del mensage" class="d-block mw-100">
                </div>
                <div class="col-md-6 d-flex justify-content-center justify-content-md-end align-items-end">
                    <button type="button" class="message__btn mb-3" (click)="accept()">
                        Entiendo, pero aún así quiero entrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
