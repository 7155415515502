import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { BROWSER_STORAGE } from '../storage';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(
        private snackBar: MatSnackBar,
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((e: HttpErrorResponse) => {
                if (!window.navigator.onLine) {
                    this.showCustomError("Verifica tu conexión a internet.");
                    throw e;
                }
                switch (e.status) {
                    case 401:
                        this.storage.removeItem('app-token');
                        this.router.navigate(['auth/login']);
                        break;
                    case 400:
                        this.showCustomError(e.error.message);
                        break;
                    case 403:
                        if (e.error.code !== 'phone_verification_required') {
                            this.showCustomError(e.error.message);
                        }
                        break;
                    case 429:
                        this.showCustomError(
                            "Se excedió el límite de peticiones, vuelve a intentarlo en 1 minuto."
                        );
                        break;
                    case 500:
                        this.showCustomError(
                            "Ocurrió un error inesperado en el servidor al procesar tu solicitud, "
                            + "lo resolveremos a la mayor brevedad posible."
                        );
                        break;
                    case 0:
                    case 504:
                        this.showCustomError(
                            "No fue posible establecer comunicación con el servidor, "
                            + "vuelve a intentarlo más tarde."
                        );
                        break;
                    default:
                        break;
                }
                throw e;
            })
        );
    }

    private showCustomError(message?: string) {
        if (!message) {
            return;
        }
        this.snackBar.open(message, "Aceptar", {
            duration: 5000,
        });
    }
}