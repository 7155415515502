import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-desktop-message',
    templateUrl: './desktop-message.component.html',
    styleUrls: ['./desktop-message.component.scss']
})
export class DesktopMessageComponent implements OnInit {

    @Output() continueOnDesktop = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    public accept() {
        this.continueOnDesktop.emit();
    }
}
