import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UrlService {
    private previousUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public previousUrl$: Observable<string> = this.previousUrlSubject.asObservable();
    public previousUrl: string;

    constructor() { }

    setPreviousUrl(previousUrl: string) {
        this.previousUrlSubject.next(previousUrl);
        this.previousUrl = previousUrl;
    }
}