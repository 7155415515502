import { Commerce, ICommerce } from './commerce';
import { IProduct, Product } from './product';

export interface IOffer {
    id: number;
    uuid: string;
    name: string;
    commerce?: ICommerce;
    product?: IProduct;
    percent: number;
    max_products: number;
    type: string;
    description: string;
    is_active: number;
}

export class Offer {
    public id: number;
    public uuid: string;
    public name: string;
    public commerce: Commerce;
    public product: Product;
    public percent: number;
    public maxProducts: number;
    public type: string;
    public description: string;
    public isActive: boolean;

    constructor(data: IOffer) {
        this.uuid = data.uuid;
        this.name = data.name;
        this.percent = data.percent;
        this.maxProducts = data.max_products;
        this.type = data.type;
        this.description = data.description;
        this.commerce = data.commerce ? new Commerce(data.commerce) : null;
        this.product = data.product ? new Product(data.product) : null;
        this.isActive = data.is_active == 1 ? true : false;
    }

    public getNameForGA() {
        if (this.type === "total" && this.name.trim().toLowerCase() === "cuenta total") {
            return `Cuenta total en ${this.commerce.name}`;
        }
        return this.name;
    }
}