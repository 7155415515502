import { Injectable, Inject } from '@angular/core'

import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router'
import { ApiService } from '../services/api.service'
import { AuthService } from '../services/auth.service'

@Injectable({
    providedIn: 'root',
})
export class PhoneVerifiedGuard implements CanActivate, CanActivateChild {
    constructor(public authService: AuthService, public router: Router) {}

    canActivate(
        _route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        let url: string = state.url
        return this.checkPhoneVerified(url)
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        return this.canActivate(route, state)
    }

    checkPhoneVerified(url: string): boolean {
        const me = this.authService.getCurrentUser()

        if (url === '/verify-phone') {
            return true
        }

        if (me.phoneVerifiedAt === null && me.mustVerifyPhone === true) {
            this.router.navigate(['/verify-phone'])
        }

        return true
    }
}
