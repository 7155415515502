import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { IQuestionSettings } from 'src/app/models/question';

@Component({
    selector: 'app-ranking',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RankingComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: RankingComponent
        }
    ]
})
export class RankingComponent implements ControlValueAccessor, Validator {
    @Input("settings") public settings: IQuestionSettings;
    @Input("type") public type: string;
    public onChange = (option) => { };
    public onTouched = () => { };
    public touched: boolean = false;
    public disabled: boolean = false;
    public selectedOption: number = 0;

    public onSelectOption(option: number) {
        this.markAsTouched();
        if (!this.disabled) {
            this.selectedOption = option;
            this.onChange(this.selectedOption);
        }
    }

    public writeValue(option: number) {
        this.selectedOption = option;
    }

    public registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const startedFromZero: boolean = this.settings.num_options_started_zero ?? false;
        const option = control.value;
        if (startedFromZero && option === 0) {
            return null;
        }
        if (option > 0) {
            return null;
        }
        return {
            min: {
                option
            }
        };
    }

  public makeOptions(): number[] {
      const startedZero: boolean = this.settings.num_options_started_zero;
      const from: number =  startedZero ? 0 : 1;
      const to: number = startedZero ? this.settings.num_options -1 : this.settings.num_options;

      let options: number[] = [];
      for (let i: number = from; i <= to; i++) {
        options.push(i);
      }
      return options;
  }
}
