import { IUser, User } from './user';

export interface IAuth {
    token_type: string;
    access_token: string;
    expires_in: number;
    refresh_token?: string;
    expires_at?: number;
    user?: IUser;
    created?: boolean;
}

export class Auth {
    tokenType: string;
    accessToken: string;
    refreshToken?: string;
    expiresIn: number;
    expiresAt?: number;
    user?: User;
    created?: boolean;

    constructor(data: IAuth) {
        this.tokenType = data['token_type'];
        this.accessToken = data['access_token'];
        this.refreshToken = data['refresh_token'] ? data['refresh_token'] : null;
        this.expiresIn = data['expires_in'];
        this.expiresAt = data['expires_at'] ?? Date.now() + (this.expiresIn * 1000);
        this.user = data['user'] ? new User(data['user']) : null;
        this.created = data.created;
    }

    public toJson(): IAuth {
        return {
            token_type: this.tokenType,
            access_token: this.accessToken,
            expires_in: this.expiresIn,
            refresh_token: this.refreshToken,
            expires_at: this.expiresAt,
            user: this.user.toJson(),
        }
    }
}

export interface ISocialRedirectResponse {
    provider_url: string;
}

export interface ISocialResponse {
    first_name: string;
    last_name: string;
    email: string;
    created: boolean;
}

export interface IDeviceResponse {
    device: string;
}