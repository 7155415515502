import { User, IUser } from './user';
import { Cause, ICause } from './cause';

export interface IStatus {
    id: string;
    name: string;
    slug: string;
    cashback_pivot?: {
        comments?: string;
        cause?: ICause;
        user?: IUser;
        auto?: boolean;
    },
}

export class Status {
    id: string;
    name: string;
    slug: string;
    comments?: string;
    cause?: Cause;
    user?: User;
    auto?: boolean;

    constructor(data: IStatus) {
        this.id = data['id'];
        this.name = data['name'];
        this.slug = data['slug'];
        if (data['cashback_pivot']) {
            this.comments = data['cashback_pivot'].comments;
            if (data['cashback_pivot'].user) {
                this.user = new User(data['cashback_pivot'].user);
            }
            if (data['cashback_pivot'].cause) {
                this.cause = Cause.fromJson(data['cashback_pivot'].cause);
            }
            this.auto = data['cashback_pivot'].auto;
        }
    }
}
