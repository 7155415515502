import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CommerceService } from '../../services/commerce.service';
import { Commerce } from '../../models/commerce';

@Component({
    selector: 'app-commerce-detail',
    templateUrl: './commerce-detail.component.html',
    styleUrls: ['./commerce-detail.component.scss']
})
export class CommerceDetailComponent implements OnInit {
    public commerce: Commerce;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private commerceService: CommerceService
    ) { }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe(params => {
            if (params.get("id")) {
                this.commerceService.getCommerce(params.get("id"), {})
                    .then(response => this.commerce = response)
                    .catch(error => {
                        if ([400, 404].indexOf(error.status) !== -1) {
                            this.router.navigate(['not-found'], { replaceUrl: true });
                        }
                    });
            }
        });
    }

    public redeem(commerce: Commerce) {
        let navigationExtras: NavigationExtras = {
            queryParams: { 'commerce': commerce.uuid }
        };
        this.router.navigate(['redeem'], navigationExtras);
    }

    public gotoPhone(commerce: Commerce) {
        window.location.href = 'tel:' + commerce.publicPhone;
    }
}
