import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Offer } from '../../models/offer';
import { Commerce } from '../../models/commerce';
import { environment } from './../../../environments/environment';
import { OffersComponent } from '../../components/offers/offers.component';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';

@Component({
    selector: 'app-offer-detail',
    templateUrl: './offer-detail.component.html',
    styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit, OnDestroy {
    @ViewChild('offers', {static: false}) listOffers: OffersComponent;
    public commerceId: string;
    public commerce: Commerce;
    public spacecrr = 0;
    public spacefn = '';
    public loading: boolean = false;
    public routerSubscription$: Subscription;
    public isAmy = false;

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        public googleAnalyticsService: GoogleAnalyticsService,
    ) { }

    ngOnDestroy(): void {
        if (this.routerSubscription$) {
            this.routerSubscription$.unsubscribe();
        }
    }

    ngOnInit() {
        this.commerceId = this.activatedRoute.snapshot.params['id'];
        this.loading = true;
        this.routerSubscription$ = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.commerceId = this.activatedRoute.snapshot.paramMap.get('id');
                if (this.commerceId) {
                    this.loading = true;
                    this.commerce = null;
                    this.listOffers.refresh(this.commerceId);
                }
            }
        });
    }

    public redeemCommerce(commerce: Commerce) {
        this.googleAnalyticsService.eventEmitter("click_redeem_on_commerce_details", "Cashbacks", commerce.name);
        let navigationExtras: NavigationExtras = {
            queryParams: { 'commerce': commerce.uuid }
        };
        this.router.navigate(['redeem'], navigationExtras);
    }

    public redeemOffer(offer: Offer) {
        this.googleAnalyticsService.eventEmitter("click_redeem_on_offer_details", "Cashbacks", offer.getNameForGA());
        let navigationExtras: NavigationExtras = {
            queryParams: { 'commerce': offer.commerce.uuid }
        };
        this.router.navigate(['redeem'], navigationExtras);
    }

    public shareWa(offer: Offer) {
        this.googleAnalyticsService.eventEmitter("click_share_offer_whatsapp", "Offers", offer.getNameForGA());
        let url = window.encodeURI(`${environment.webAppUrl}/guest/offers?offer=${offer.uuid}`);
        let msg = window.encodeURIComponent("Checa esta oferta en Moneo, la mejor app de cashback en México.");
        window.location.href = `whatsapp://send?text=${msg} ${url}`;
    }

    public setPageTitle() {
        this.titleService.setTitle(this.commerce.name + ' | Moneo');
    }

    public onCommerceLoaded(commerce: Commerce) {
        this.commerce = commerce;
        this.setPageTitle();
        this.loading = false;
        if (['Amy'].includes(this.commerce.name)) {
            this.isAmy = true;
        }
    }

    public showTerms() {
        this.dialog.open(TermsConditionsComponent, {
            data: {}
        });
    }
}
