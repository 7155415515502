import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiSaveResponse } from '../models/api-response';
import { IPhoneRecharge, IRequestPhoneRecharge, PhoneRecharge } from '../models/phone-recharge';

@Injectable({
    providedIn: 'root'
})
export class PhoneRechargeService {

    constructor(
        private apiService: ApiService
    ) { }

    public requestPhoneRecharge(data: Partial<IRequestPhoneRecharge>): Promise<PhoneRecharge> {
        return this.apiService.request<ApiSaveResponse<IPhoneRecharge>>('post', 'phone-recharges', data, true).then(response => {
            return PhoneRecharge.fromJson(response.data);
        });
    }
}
