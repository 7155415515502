<mat-card appearance="raised" class="commerces-list">
    <mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>Regresar</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="!campaign">
        <h3>En este momento no tenemos campañas.</h3>
    </mat-card-content>
    <img mat-card-image [src]="campaign.photo ? campaign.photo : '/assets/img/no-image.png'" alt="Imágen" *ngIf="campaign">
    <mat-card-content *ngIf="campaign">
        <div class="text-center">
            <h3 class="mb-4 mt-3" style="font-size: 24px;">{{ campaign.name }}</h3>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div *ngIf="balance">
                    El saldo en tu cuenta es de:
                    <span class="font-monospace fw-bold">{{ balance.available | currency }}</span>
                </div>
                <mat-form-field>
                    <mat-label>Ingresa la cantidad a donar</mat-label>
                    <input type="text" matInput formControlName="donation_amount" required>
                    <mat-error *ngIf="form.get('donation_amount').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('donation_amount').errors?.['serverError']">
                        {{ form.get('donation_amount').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <div>
                    <button type="submit" mat-raised-button color="primary" [disabled]="donationRequested">
                        Donar
                    </button>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>

