import { IFile } from './file';
import { IOffer, Offer } from './offer';
import { Commerce, ICommerce } from './commerce';
import { User, IUser } from './user';
import { IStatus, Status } from './status';

export interface ICashback {
    id: number;
    ticket_id: string;
    commerce?: ICommerce;
    user?: IUser;
    status?: IStatus;
    amount: number;
    percent: number;
    purchase_amount: number;
    purchase_payment_type: string;
    receipt?: IFile;
    created_at: string;
    store_id: number;
    purchase_total_amount: number;
    purchased_at: string;
    offers?: IOffer[];
}

export class Cashback {
    public id: number;
    public ticketID: string;
    public commerce: Commerce;
    public user: User;
    public status: Status;
    public amount: number;
    public percent: number;
    public purchaseAmount: number;
    public purchasePaymentType: string;
    public receipt: string;
    public createdAt: Date;
    public storeId: number;
    public purchaseTotalAmount: number;
    public purchasedAt: Date;
    public offers: Offer[];

    constructor(data: ICashback) {
            this.id = data['id'];
            this.ticketID = data['ticket_id'];
            this.commerce = data['commerce'] ? new Commerce(data['commerce']) : null;
            this.user = data['user'] ? new User(data['user']) : null;
            this.status = data['status'] ? new Status(data['status']) : null;
            this.amount = data['amount'];
            this.percent = data['percent'];
            this.purchaseAmount = data['purchase_amount'];
            this.purchasePaymentType = data['purchase_payment_type'];
            this.receipt = data['receipt'] ? data['receipt'].path : (data['receiptPath'] ? data['receiptPath'] : null);
            this.createdAt = data['created_at'] ? new Date(data['created_at'].replace(' ', 'T')) : null;
            this.storeId = data['store_id'];
            this.purchaseTotalAmount = data['purchase_total_amount'];
            this.purchasedAt = data['purchased_at'] ? new Date(data['purchased_at']) : null;
            this.offers = data['offers'] ? data['offers'].map(r => new Offer(r)) : [];
    }
}
