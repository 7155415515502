import {Injectable, Inject} from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {BROWSER_STORAGE} from '../storage';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    
    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        public authService: AuthService,
        public router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
    
    checkLogin(url: string): boolean {
        if (!this.authService.isLoggedIn()) {
            this.storage.removeItem('app-token');
            let navigationExtras: NavigationExtras = {
                queryParams: {'redirect': url}
            };
            this.authService.requestToken().catch(error => {});
            this.router.navigate(['auth/login'], navigationExtras);
            return false;
        }
        return true;
    }
}
