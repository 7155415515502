interface HistoryCB {
    cashback_id: string;
    created_at: string;
    file_storage_path: string;
}

export interface IHistory {
    count_requested: number;
    count_accepted: number;
    count_refused: number;
    total_requested: number;
    total_accepted: number;
    total_refused: number;
    last_five_requested_cashbacks: HistoryCB[];
    last_five_accepted_cashbacks: HistoryCB[];
    last_five_refused_cashbacks: HistoryCB[];
}

export class History {
    public count_requested: number;
    public count_accepted: number;
    public count_refused: number;
    public total_requested: number;
    public total_accepted: number;
    public total_refused: number;
    public lastFiveRequestedCashbacks: HistoryCB[];
    public lastFiveAcceptedCashbacks: HistoryCB[];
    public lastFiveRefusedCashbacks: HistoryCB[];

    constructor(data: IHistory) {
        this.count_requested = data['count_requested'];
        this.count_accepted = data['count_accepted'];
        this.count_refused = data['count_refused'];
        this.total_requested = data['total_requested'];
        this.total_accepted = data['total_accepted'];
        this.total_refused = data['total_refused'];
        this.lastFiveRequestedCashbacks = data['last_five_requested_cashbacks'];
        this.lastFiveAcceptedCashbacks = data['last_five_accepted_cashbacks'];
        this.lastFiveRefusedCashbacks = data['last_five_refused_cashbacks'];
    }
}
