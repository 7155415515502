import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-questionnaire-card',
    templateUrl: './questionnaire-card.component.html',
    styleUrls: ['./questionnaire-card.component.scss']
})
export class QuestionnaireCardComponent implements OnInit {
    @Input() public title: string;
    @Input() public content: string;
    @Input() public btnText: string;
    @Input() public url: string[];
    @Input() public showCloseBtn: boolean = false;

    public showQuestionnaireBanner: boolean = true;

    constructor(
        private userService: UserService,
        private router: Router,
        ) { }

    ngOnInit(): void {
        this.showQuestionnaireBanner = this.userService.showQuestionnaire;
    }

    public closeBanner() {
        this.showQuestionnaireBanner = false;
        this.userService.showQuestionnaire = false;
    }

    public navigate() {
        this.router.navigate(this.url);
    }
}
