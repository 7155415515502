<div class="ranking-container">
  <ul class="ranking-numbers" *ngIf="type === 'numbers'">
    <li *ngFor="let option of makeOptions()">
      <button
        type="button"
        mat-fab
        [color]="option === selectedOption ? 'accent' : 'primary'"
        (click)="onSelectOption(option)"
      >
        {{ option }}
      </button>
    </li>
  </ul>

  <div class="d-flex mb-2 justify-content-center five-stars" *ngIf="type === 'stars'">
        <span *ngFor="let option of makeOptions()">
            <i [ngClass]="option <= selectedOption ? 'fas' : 'far'" class="star fa-star" (click)="onSelectOption(option)"></i>
        </span>
    </div>
    <div
      class="row"
      [class.justify-content-center]="type === 'stars'"
      [class.pt-4]="type === 'numbers'"
    >
        <div class="col-6" [ngClass]="type === 'numbers' ? 'text-start' : 'text-center'">
            <i class="icon" [ngClass]="settings.start_icon"></i>
            <p class="label">{{ settings.start_label }}</p>
        </div>
        <div class="col-6" [ngClass]="type === 'numbers' ? 'text-end' : 'text-center'">
            <i class="icon" [ngClass]="settings.end_icon"></i>
            <p class="label">{{ settings.end_label }}</p>
        </div>
    </div>
</div>
