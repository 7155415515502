import { ApiPaginatorResponse } from './../models/api-response';
import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { IOffer, Offer } from '../models/offer';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class OfferService {
    public offers: Offer[];

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private authService: AuthService,
        public router: Router
    ) { }

    public getOffers(params: Object, reload: boolean = false): Promise<Offer[]> {
        if (this.offers && !reload) {
            return Promise.resolve(this.offers);
        }
        if (params.hasOwnProperty('no-paginate') && params["no-paginate"] === 1) {
            return this.apiService.request<IOffer[]>('get', 'offers', params).then(result => {
                const offers = result.map((data) => new Offer(data));
                if (!reload) {
                    this.offers = offers;
                }
                return offers;
            });
        }
        else {
            return this.apiService.request<ApiPaginatorResponse<IOffer>>('get', 'offers', params).then(result => {
                const offers = result.data.map((data) => new Offer(data));
                if (!reload) {
                    this.offers = offers;
                }
                return offers;
            });
        }
    }

    public getOffer(id: number): Promise<Offer> {
        return this.apiService.request<IOffer>('get', 'offers/' + id, {}).then(result => {
            return new Offer(result);
        });
    }

    public getGuestOffers(params: { [k: string]: any }): Promise<Offer[]> {
        let auth = this.authService.getAuthData();
        if (auth.user != null) {
            // Hay usuario autenticado redireccionar al home.
            this.router.navigate(['home']);
        }
        const url: string = `${environment.apiUrl}/guest/offers`;
        const options = {
            headers: {
                Authorization: auth.tokenType + ' ' + auth.accessToken
            },
            params
        };
        if (params.hasOwnProperty('no-paginate') && params["no-paginate"] === 1) {
            return firstValueFrom(this.http.get<IOffer[]>(url, options)).then((result) => {
                return result.map((data) => new Offer(data));
            }).catch(error => Promise.reject(error));
        }
        else {
            return firstValueFrom(this.http.get<ApiPaginatorResponse<IOffer>>(url, options)).then((result) => {
                return result.data.map((data) => new Offer(data));
            }).catch(error => Promise.reject(error));
        }
    }
}
