<div>
    <mat-form-field class="w-100" *ngIf="['string', 'integer', 'numeric', 'email', 'phone', 'url', 'age'].indexOf(question.type) !== -1">
        <input matInput [formControl]="control"
            [type]="['integer', 'numeric', 'age'].indexOf(question.type) !== -1 ? 'number' : 'text'"
            [step]="['integer', 'age'].indexOf(question.type) !== -1 ? 1 : 0.1"
            [placeholder]="question.type === 'string' ? 'Escribe tu respuesta aquí' : ''">
        <mat-hint *ngIf="['integer', 'age'].indexOf(question.type) !== -1">
            {{ 'Enter an integer' | translate }}. Ej. 4
        </mat-hint>
        <mat-hint *ngIf="question.type === 'numeric'">
            {{ 'Enter a number' | translate }}. Ej. 3.14
        </mat-hint>
        <mat-hint *ngIf="question.type === 'email'">
            {{ 'Enter an email' | translate }}. Ej. email&#64;test.com
        </mat-hint>
        <mat-hint *ngIf="question.type === 'phone'">
            {{ 'Enter a phone' | translate }}. Ej. 555-122-0599
        </mat-hint>
        <mat-hint *ngIf="question.type === 'url'">
            {{ 'Enter an url' | translate }}. Ej. https://www.website.com
        </mat-hint>
        <mat-error *ngIf="control.errors?.['required']">
            {{ 'Please fill this field' | translate }}
        </mat-error>
        <mat-error *ngIf="control.errors?.['min']">
            {{ 'Please enter a number greater or equal than' | translate }} {{ question.settings['min_value'] }}
        </mat-error>
        <mat-error *ngIf="control.errors?.['max']">
            {{ 'Please enter a number less or equal than' | translate }} {{ question.settings['max_value'] }}
        </mat-error>
        <mat-error *ngIf="control.errors?.['email']">
            {{ 'Please enter a valid e-mail address' | translate }}
        </mat-error>
        <mat-error *ngIf="control.errors?.['pattern'] && question.type === 'phone'">
            {{ 'Please enter a valid phone' | translate }}
        </mat-error>
        <mat-error *ngIf="control.errors?.['pattern'] && question.type === 'url'">
            {{ 'Please enter a valid url' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="question.type === 'date'">
        <mat-label>{{ 'Choose a date' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="control">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>
            {{ 'Enter a date' | translate }}. Ej. 03/05/2021
        </mat-hint>
        <mat-error *ngIf="control.errors?.['required']">
            {{ 'Please fill this field' | translate }}
        </mat-error>
    </mat-form-field>
    <app-material-datetime-picker
        [formControl]="control"
        [labelText]="'Choose a datetime' | translate"
        hintText="Ej. 03/05/2021 14:30:00"
        [required]="question.isRequired"
        *ngIf="question.type === 'datetime'"></app-material-datetime-picker>
    <mat-radio-group class="radio-group" [formControl]="control" *ngIf="question.type === 'boolean'">
        <mat-hint>
            {{ 'Select one option' | translate }}
        </mat-hint>
        <mat-radio-button [value]="true">
            {{ 'Yes' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false">
            {{ 'No' | translate }}
        </mat-radio-button>
        <mat-error *ngIf="control.errors?.['required']">
            <!--{{ 'Please fill this field' | translate }}-->
        </mat-error>
    </mat-radio-group>
</div>