import { UrlService } from './../../services/url.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { interval, take } from 'rxjs';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../services/auth.service';
import { User } from './../../models/user';
import { Tel } from '../../components/tel-input/tel-input.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar'
import { environment } from '../../../environments/environment';
import parsePhoneNumber from 'libphonenumber-js';

@Component({
    selector: 'app-verify-phone',
    templateUrl: './verify-phone.component.html',
    styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent implements OnInit {
    public phoneForm: UntypedFormGroup;
    public verificationCodeform: UntypedFormGroup;
    public user: User;
    private userSubscription: Subscription;
    public waitingForRetry: boolean = false;
    public intervalSubscription: Subscription;
    public secondsToWait: number = environment.verifyPhone.secondsToWait;
    public remainingSeconds: number = this.secondsToWait;
    public verifyButtonEnabled: boolean = true;

    public phoneEntered: boolean = false;
    public codeVerified: boolean = false;
    public verificationCodeHasError: boolean = false;
    public userMustVerifyPhone: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private userService: UserService,
        private urlService: UrlService,
        private _snackBar: MatSnackBar,
        private router: Router
    ) {
        this.user = this.authService.getCurrentUser();
        this.userMustVerifyPhone = this.user.mustVerifyPhone;
    }

    ngOnInit(): void {
        this.userSubscription = this.userService.authUserUpdated$.subscribe(user => {
            this.user = user;
        });
        let tel: Tel = null;

        if (this.user.phoneVerifiedAt !== null) {
            const extras: NavigationExtras = {};
            this.router.navigate(['profile'], extras)
        }

        if (this.user.phone) {
            if (this.user.phone.length === 10) {
                tel = new Tel(
                    this.user.phone.substring(0, 3),
                    this.user.phone.substring(3, 6),
                    this.user.phone.substring(6, 10)
                );
            }
            else if (this.user.phone.length === 12 && this.user.phone.includes('-')) {
                const phone = this.user.phone.split('-');
                tel = new Tel(phone[0], phone[1], phone[2]);
            }
        }
        this.phoneForm = new FormGroup({
            phone: new FormControl(tel, [Validators.required]),
        });
        this.verificationCodeform = this.fb.group({
            code: ['', [
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(6),
                // Validators.pattern('[0-9]{6}'),
            ]]
        });
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    private massagePhone(phone: string) {
        // Por ahora, usamos siempre el prefijo de México, +52.  Es posible
        // que en el futuro queramos permitir números de otros países.
        const countryPrefix = '+52';

        return `${countryPrefix}${phone}`;
    }

    public formatPhone(phone: string) {
        const p = parsePhoneNumber(this.massagePhone(phone))

        return p.formatNational()
    }

    public massageRemainingSeconds() {
        if (this.remainingSeconds == 1) {
            return "1 segundo"
        }

        return `${this.remainingSeconds} segundos`
    }

    public rerequestCode() {
        this.waitingForRetry = false;

        this.requestCode();
    }

    public requestCode() {
        if (this.waitingForRetry) {
            return;
        }
        if (!this.phoneForm.valid) {
            return;
        }

        const tel = this.phoneForm.value.phone;
        const phone = this.massagePhone(`${tel.area}${tel.exchange}${tel.subscriber}`);
        // Iniciar cuenta regresiva para permitir de nuevo la solicitud del código
        this.waitingForRetry = true;
        this.intervalSubscription = interval(1000).pipe(
            take(this.secondsToWait)
        ).subscribe(x => {
            this.remainingSeconds = this.secondsToWait - (x + 1);
            if (x + 1 === this.secondsToWait) {
                this.waitingForRetry = false;
                this.remainingSeconds = this.secondsToWait;
                this.intervalSubscription.unsubscribe();
            }
        });
        this.userService.requestVerificationCode(phone)
            .then(response => {
                this._snackBar.open(response.message, "Aceptar", {
                    duration: 8000,
                });
                this.phoneEntered = true;
            }).catch((response: HttpErrorResponse) => {
                this.waitingForRetry = false;
                this.handleErrorResponse(response, this.phoneForm);
            });
    }

    public validateCode() {
        if (this.codeVerified) {
            return this.continue();
        }
        if (!this.verificationCodeform.valid) {
            return;
        }
        this.verifyButtonEnabled = false;
        this.verificationCodeHasError = false;
        const code = this.verificationCodeform.value.code;

        const tel = this.phoneForm.value.phone;
        const phone = this.massagePhone(`${tel.area}${tel.exchange}${tel.subscriber}`);
        this.userService.submitVerificationCode(code, phone)
            .then(_response => {
                const msg = "Listo, redireccionándote...";
                const snackbar = this._snackBar.open(msg, "Aceptar", {
                    duration: 4000,
                });
                snackbar.afterDismissed().subscribe(() => {
                    this.continue();
                });
                this.codeVerified = true;
            }).catch((response: HttpErrorResponse) => {
                this.handleErrorResponse(response, this.verificationCodeform);
                if (response.status === 422) {
                    this.verificationCodeHasError = true;
                }
            }).then(() => this.verifyButtonEnabled = true);
    }

    public handleErrorResponse(response: HttpErrorResponse, form: UntypedFormGroup) {
        if (response.status === 422) {
            this._snackBar.open(response.error.message, "Aceptar", {
                duration: 8000,
                panelClass: 'snackbar-danger-color'
            });
            if (response.error.errors) {
                Object.keys(response.error.errors).forEach(key => {
                    const formControl = form.get(key);
                    if (formControl) {
                        formControl.setErrors({
                            serverError: response.error.errors[key][0]
                        });
                    }
                });
            }
        }
    }

    public clearForms() {
        this.phoneForm.reset();
        this.verificationCodeform.reset();
        this.phoneEntered = false;
        this.waitingForRetry = false;
    }

    public continue() {
        const y = this.userService.getMe()

        y.then((user: User) => {
            this.user = user;
        }).then(() => {
            const extras: NavigationExtras = {};
            let route = ['home'];
            if (this.urlService.previousUrl === '/profile') {
                extras.replaceUrl = true;
                route = ['profile'];
            }
            else if (this.user.source === 'ojo7' && this.user.questionnaires.some(q => q.slug === 'ojo7-1')) {
                route = ['encuestas', 'ojo7-1'];
            }
            else if (this.userMustVerifyPhone && this.user.source !== 'ojo7' && this.user.mustCompleteProfile) {
                route = ['complete-profile'];
            }
            const ret = this.router.navigate(route, extras);
            ret.then((x) => {
                console.debug(`then x: ${x}`);
            }).catch((y) => {
                console.debug(`catch y: ${y}`);
            }).finally(() => {
                console.debug(`finally`);
            })
        })

    }
}
