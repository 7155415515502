import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Offer } from './../../models/offer';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { environment } from './../../../environments/environment';
import { OffersComponent } from '../../components/offers/offers.component';

@Component({
    selector: 'app-guest-offers',
    templateUrl: './guest-offers.component.html',
    styleUrls: ['./guest-offers.component.scss']
})
export class GuestOffersComponent implements OnInit, OnDestroy {
    @ViewChild('offers', { static: false }) listOffers: OffersComponent;
    public hasToken: boolean = false;
    public commerceId: string;
    public commerceName: string;
    public offerId: string;

    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        private authService: AuthService,
        public googleAnalyticsService: GoogleAnalyticsService,
        public router: Router,
    ) {  }

    ngOnInit(): void {
        document.getElementsByTagName('body')[0].classList.add('guest-offers');
        this.commerceId = this.activatedRoute.snapshot.queryParams['commerce'];
        this.commerceName = this.activatedRoute.snapshot.queryParams['commerce-name'];
        this.offerId = this.activatedRoute.snapshot.queryParams['offer'];
        this.hasToken = false;
        this.authService.clientToken$.subscribe((auth) => {
            if (auth === null) {
                return;
            }
            this.hasToken = true;
        });
    }

    ngOnDestroy() {
        document.getElementsByTagName('body')[0].classList.remove('guest-offers');
    }

    public register(offer: Offer) {
        let queryParams: any = {
            source: 'guest_browsing'
        };
        if (offer) {
            queryParams.redirect = '/redeem?commerce=' +  offer.commerce.uuid;
        }
        this.router.navigate(['auth/register'], {queryParams});
    }

    public shareWa(offer: Offer) {
        this.googleAnalyticsService.eventEmitter("click_share_offer_whatsapp", "Offers", offer.getNameForGA());
        let url = window.encodeURI(`${environment.webAppUrl}/guest/offers?offer=${offer.uuid}`);
        let msg = window.encodeURIComponent("Checa esta oferta en Moneo, la mejor app de cashback en México.");
        window.location.href = `whatsapp://send?text=${msg} ${url}`;
    }

    public onCommerceClick(payload: any) {
        this.listOffers.refresh(payload.uuid);
    }
}
