<mat-nav-list>
    <a mat-list-item href="/#" (click)="$event.preventDefault(); goTo('home')" *ngIf="user !== null">{{ "Home" | translate }}</a>
    <a mat-list-item href="/#" (click)="$event.preventDefault(); goTo('profile')" *ngIf="user !== null">{{ "My profile" | translate }}</a>
    <a mat-list-item href="/#" (click)="$event.preventDefault(); goTo('articulos/como-convertirte-en-un-comprador-inteligente')" *ngIf="user !== null" class="with-badge-text">
        <span matBadge="{{ 'New' | translate }}" matBadgeColor="warn" matBadgeSize="small" matBadgePosition="above after">{{ "Comunidad" | translate }}</span>
    </a>
    <!-- <a mat-list-item href="/#" (click)="$event.preventDefault(); showBenefits();">{{ "Welcome" | translate }}</a> -->
    <a mat-list-item href="/#" (click)="$event.preventDefault(); showTutorial();">{{ "¿How win with Moneo?" | translate }}</a>
    <a mat-list-item href="/#" (click)="$event.preventDefault(); goTo('tutorial')">{{ "How upload my ticket?" | translate }}</a>
    <a mat-list-item href="/#" (click)="$event.preventDefault(); goTo('faq');">{{ "FAQ" | translate }}</a>
    <a mat-list-item href="/#" (click)="$event.preventDefault(); showTerms();">{{ "Terms and conditions" | translate }}</a>
    <a mat-list-item href="/#" (click)="$event.preventDefault(); showPrivacy();">{{ "Notice of privacy" | translate }}</a>
    <a mat-list-item href="/#" (click)="$event.preventDefault(); contact();">{{ "Contact" | translate }}</a>
    <a mat-list-item href="#" *ngIf="user !== null" (click)="$event.preventDefault(); logout()">{{ "Logout" | translate }}</a>
</mat-nav-list>
