<div class="guest-offers">
    <div class="register-message text-center mb-3 mx-auto p-3 px-md-5 py-md-3">
        <p class="mb-4 font-weight-bold h3">¡Sube tu primer ticket!</p>
        <button type="button" mat-raised-button color="primary" (click)="register(null)">
            Regístrate
        </button>
    </div>
    <app-offers *ngIf="hasToken"
        [isGuestUser]="true"
        [commerceId]="commerceId"
        [commerceName]="commerceName"
        [offerId]="offerId"
        [appendMore]="false"
        [showCommerceLogo]="true"
        [reload]="true"
        (cardClick)="register($event)"
        (shareClick)="shareWa($event)"
        #offers>
    </app-offers>
    <app-favorite-commerces *ngIf="hasToken"
        [showMoreOffersButton]="false"
        [numItems]="4"
        [isGuestUser]="true"
        (commerceClick)="onCommerceClick($event)">
    </app-favorite-commerces>
</div>
