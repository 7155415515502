<div class="phone-recharges container pt-4 pb-3">
    <div class="text-center">
        <img src="assets/img/att-logo-2.svg" alt="AT&T Logo" class="phone-recharges__logo d-block mx-auto">
    </div>
    <div class="mt-3" *ngIf="!phoneRecharge && !phoneRechargeError">
        <h3 class="fw-bold mb-4">Intercambia tu cashback por recargas con AT&T.</h3>
        <form [formGroup]="form" (submit)="onSubmit()">
            <section>
                <mat-form-field class=" w-100">
                    <mat-label>Ingresa tu número AT&T</mat-label>
                    <input type="text" matInput formControlName="phone" required [errorStateMatcher]="matcher">
                    <mat-error *ngIf="form.get('phone').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.errors?.['phoneLengthInvalid'] && (form.touched || form.dirty)">
                        {{ 'The field must be {{size}} characters' | translate:{size:10} }}
                    </mat-error>
                    <mat-error *ngIf="form.errors?.['phoneNotConfirmed'] && (form.touched || form.dirty)">
                        {{ "The phone confirmation does not match" | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('phone').errors?.['serverError']">
                        {{ form.get('phone').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="no-fill w-100">
                    <mat-label>Ingresa tu número AT&T de nuevo</mat-label>
                    <input type="text" matInput formControlName="phone_confirmation">
                    <mat-error *ngIf="form.get('phone_confirmation').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('phone_confirmation').errors?.['serverError']">
                        {{ form.get('phone_confirmation').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mb-3" style="width: 100%;" appearance="fill" *ngIf="phoneRechargeProducts">
                    <mat-label>Monto</mat-label>
                    <mat-select formControlName="phone_recharge_product_id">
                        <ng-container *ngFor="let product of phoneRechargeProducts">
                            <mat-option [value]="product.id">
                                ${{ product.cost | number:'1.0-0' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error *ngIf="form.get('phone_recharge_product_id').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('phone_recharge_product_id').errors?.['serverError']">
                        {{ form.get('phone_recharge_product_id').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <p class="">
                    <mat-checkbox matInput formControlName="accept_terms" name="accept_terms" color="primary" required>
                        Otorgo mi consentimiento para que mis datos personales sean tratados conforme a lo señalado en el aviso de privacidad (*).
                    </mat-checkbox>
                    <mat-error *ngIf="form.get('accept_terms').errors?.['required']" style="padding-left: 42px;">
                        {{ 'You must accept terms.' | translate }}
                    </mat-error>
                </p>
                <div class="text-center">
                    <button type="submit" mat-raised-button color="primary" [disabled]="phoneRechargeRequested">
                        Hacer recarga
                    </button>
                </div>
            </section>
            <div class="text-center pt-3">
                <a href="#" (click)="$event.preventDefault(); showTerms()">Términos y Condiciones</a>
            </div>
        </form>
    </div>
    <div class="mt-3" *ngIf="!phoneRechargeError && phoneRecharge && phoneRecharge.status === 'approved'">
        <h3 class="fw-bold mb-2">¡Gracias! Ya hemos abonado MX ${{ phoneRecharge.amount | number:'1.0-0' }} a tu línea AT&T.</h3>
        <p class="fw-bold mb-5">(La recarga puede tardar unos minutos)</p>
        <div class="phone-recharge-ticket">
            <p class="fw-bold mt-4 mb-1">Recarga exitosa</p>
            <div class="phone-recharge-ticket__item row g-0">
                <span class="col">{{ phoneRecharge.datetime }}</span>
                <span class="col col-auto fw-bold">{{ phoneRecharge.amount | currency }}</span>
            </div>
            <p class="fw-bold mt-4 mb-1">Detalle del pago</p>
            <div class="phone-recharge-ticket__item row g-0">
                <span class="col">Monto total</span>
                <span class="col col-auto fw-bold">{{ phoneRecharge.amount | currency }}</span>
            </div>
            <div class="phone-recharge-ticket__item row g-0">
                <span class="col">Número de transacción</span>
                <span class="col col-auto fw-bold">{{ phoneRecharge.transactionNumber }}</span>
            </div>
            <div class="phone-recharge-ticket__item row g-0">
                <span class="col">Categoría</span>
                <span class="col col-auto fw-bold">Tiempo aire</span>
            </div>
            <div class="phone-recharge-ticket__item row g-0">
                <span class="col">Empresa</span>
                <span class="col col-auto fw-bold">AT&T</span>
            </div>
            <div class="phone-recharge-ticket__item row g-0">
                <span class="col">Número de referencia</span>
                <span class="col col-auto fw-bold">{{ phoneRecharge.phone }}</span>
            </div>
            <div class="phone-recharge-ticket__item row g-0">
                <span class="col">Comisión</span>
                <span class="col col-auto fw-bold">$0.00</span>
            </div>
            <div class="phone-recharge-ticket__item row g-0">
                <span class="col">Método de pago</span>
                <span class="col col-auto fw-bold">Cashback</span>
            </div>
        </div>
    </div>
    <div class="mt-3" *ngIf="phoneRechargeError">
        <h3 class="fw-bold mb-2">No pudimos procesar tu recarga.</h3>
        <p class="fw-bold mb-5 ">No te hemos cobrado, tu saldo Moneo sigue igual.</p>
        <div class="text-center">
            <button type="button" mat-raised-button color="primary" (click)="goToProfile()">
                Intenta otra vez
            </button>
        </div>
        <p class="mt-5">Si no funciona, por favor escríbenos a <a href="mailto:soporte@moneo.mx">soporte&#64;moneo.mx</a></p>
    </div>
</div>
