
<mat-form-field class="no-fill">
    <mat-label *ngIf="labelText">{{ labelText }}</mat-label>
    <input matInput [value]="displayValue" [placeholder]="placeholderText" readonly>
    <input matInput class="fs-16px" [max]="max" [matDatepicker]="datePicker" style="width: 0;" [(ngModel)]="selectedDate">
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker>
        <mat-datepicker-actions>
            <div>
                <ngx-timepicker-field #timePicker [controlOnly]="true" [format]="24" [(ngModel)]="selectedTime"
                    ></ngx-timepicker-field>
            </div>
            <div class="ms-3">
                <button mat-raised-button color="primary" matDatepickerApply (click)="applySelection();">Aceptar</button>
            </div>
        </mat-datepicker-actions>
    </mat-datepicker>
    <mat-hint *ngIf="hintText">{{ hintText }}</mat-hint>
</mat-form-field>