import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-verification-code-input',
    templateUrl: './verification-code-input.component.html',
    styleUrls: ['./verification-code-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: VerificationCodeInputComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: VerificationCodeInputComponent
        }
    ]
})
export class VerificationCodeInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

    public onChange = (option) => { };
    public onTouched = () => { };
    public stateChanges = new Subject<void>();
    public focused = false;
    public touched: boolean = false;
    public disabled: boolean = false;

    public parts = this._formBuilder.group({
        one: ['', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
            // Validators.pattern('[0-9]{6}')
        ]],
    });

    @Input()
    get value(): string | null {
        if (this.parts.valid) {
            const {
                value: { one },
            } = this.parts;
            return `${one!}`
        }
        return null;
    }
    set value(code: string | null) {
        this.parts.setValue({
            one: code ? code[0] : '',
        });
        this.stateChanges.next();
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
    ) { }

    public ngOnInit(): void {
    }

    public ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    public onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    public onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    public autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
        if (!control.errors && nextElement) {
            this._focusMonitor.focusVia(nextElement, 'program');
        }
    }

    public autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
        if (control.value.length < 1) {
            this._focusMonitor.focusVia(prevElement, 'program');
        }
    }

    public writeValue(obj: string | null): void {
        this.value = obj;
    }

    public registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }

    public registerOnValidatorChange?(fn: () => void): void {
        //
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
        this.autoFocusNext(control, nextElement);
        this.onChange(this.value);
    }
}
