import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CatalogueService } from '../../services/catalogue.service';

@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent {
    public minRechargeCost = 30;
    public amounts = {
        10: "diez",
        15: "quince",
        20: "veinte",
        30: "treinta",
        50: "cincuenta",
    };

    constructor(
        public dialogRef: MatDialogRef<TermsConditionsComponent>,
        private catalogueService: CatalogueService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        let params = {
            is_active: 1,
            sort: 'cost|asc', //Este orden es importante para posteriormente elegir el primer elemento y será el del costo más chico
        };

        let response = null;
        if (data?.isGuestUser) {
            response = catalogueService.getPhoneRechargeProductsAsGuest(params);
        } else {
            response = catalogueService.getPhoneRechargeProducts(params);
        }        
        response.then(products => {
            if (products.length > 0) {
                this.minRechargeCost = products[0].cost;
            }
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
