export interface ISource {
    id: number;
    slug: string;
    name: string;
}

export class Source {
    public constructor(
        public id: number,
        public slug: string,
        public name: string,
    ) { }

    public static fromJson(data: ISource): Source {
        return new Source(
            data.id,
            data.slug,
            data.name,
        );
    }
}
