import moment from 'moment';
import { IBeneficalCause } from './benefical-cause';

export interface ICampaign {
    id: number;
    name: string;
    type: string;
    details: string;
    is_active: number;
    created_at: string;
    beneficial_cause_id: number;
    number: string;
    from: string;
    to: string;
    beneficial_cause?: IBeneficalCause;
    landing_url?: string;
    source?: string;
}

export class Campaign {
    constructor(
        public id: number,
        public name: string,
        public type: string,
        public details: string,
        public isActive: boolean,
        public createdAt: string,
        public beneficialCauseId: number,
        public number: string,
        public from: Date,
        public to: Date,
        public photo?: string,
        public landingUrl?: string,
        public source?: string,
    ) {}

    public static fromJson(data: ICampaign): Campaign {
        return new Campaign(
            data['id'],
            data['name'],
            data['type'],
            data['details'],
            data['is_active'] == 1,
            data['created_at'],
            data['beneficial_cause_id'],
            data['number'],
            data['from'] ? new Date(`${data['from']}T00:00:00`) : null,
            data['to'] ? new Date(`${data['to']}T00:00:00`) : null,
            data['beneficial_cause']?.photo ? data['beneficial_cause'].photo.path: null,
            data['landing_url'],
            data['source'],
        );
    }

    public getCreationDate(): string {
        return moment(this.createdAt).format('DD/MM/YYYY');
    }
}