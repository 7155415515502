import {Component, Inject, OnInit} from '@angular/core';
import {NgForm, UntypedFormControl, FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {TermsConditionsComponent} from '../terms-conditions/terms-conditions.component';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import {Utils} from '../../utils/index';
import {PrivacyComponent} from '../privacy/privacy.component';
import { BROWSER_STORAGE } from '../../storage';
import { CatalogueService } from './../../services/catalogue.service';
import { WindowService } from '../../services/window.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatomoService } from '../../services/matomo.service';
import { User } from './../../models/user';

/** Error when invalid control is dirty, touched, or submitted. */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        let customError = false;
        if (control === form.form.get('email')) {
            customError = control.parent.hasError('emailNotConfirmed');
        }
        else if (control === form.form.get('password')) {
            customError = control.parent.hasError('passwordNotConfirmed');
        }
        const invalidParent = !!(control && control.parent && customError && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    public formError: string = '';
    public formSuccess: string = '';
    public form: UntypedFormGroup;
    public matcher = new CustomErrorStateMatcher();
    public savingText: string = "Creating user account";
    public okText: string = "OK";
    public errors: {[k: string]: string[]} = {};
    public showForm = false;
    public ofLegalAge: boolean;
    public hidePassword: boolean = true;
    public buttonSubmitDisabled: boolean = false;
    public sourcePage?: string;
    public userCode?: string;
    public clickId?: string;
    public onboarding?: string;
    public validSources: string[] = [];

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public translate: TranslateService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private catalogService: CatalogueService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private activatedRoute: ActivatedRoute,
        public windowService: WindowService,
        private matomoService: MatomoService,
    ) {
        this.translate.get([this.savingText, this.okText], {}).subscribe((trans) => {
            this.savingText = trans[this.savingText];
            this.okText = trans[this.okText];
        });
        this.clearErrors();
    }

    private clearErrors() {
        this.formError = '';
    }

    ngOnInit() {
        this.authService.clientToken$.subscribe((auth) => {
            if (auth && this.validSources.length === 0) {
                this.fetchSources();
            }
        });
        this.sourcePage = this.activatedRoute.snapshot.queryParams['source'];
        this.clickId = this.activatedRoute.snapshot.queryParams['ClickID'];
        this.userCode = this.activatedRoute.snapshot.queryParams['user_code'];
        this.onboarding = this.activatedRoute.snapshot.queryParams['onboarding'];
        // Borrar sourcePage del localstorage
        this.storage.removeItem('sourcePage');
        this.storage.removeItem('clickID');
        this.storage.removeItem('userCode');
        if (this.onboarding && ['laeuropea1'].indexOf(this.onboarding) !== -1) {
            this.storage.setItem('onboarding', this.onboarding);
        }
        if (this.userCode) {
            this.storage.setItem('userCode', this.userCode);
        }
        this.ofLegalAge = this.storage.getItem('isOver18') == '1';
        let controls = {
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            phone: [''],
            email: ['', Validators.email],
            password: ['', Validators.required],
            source: [this.sourcePage],
            onboarding: [this.storage.getItem('onboarding')],
            click_id: [this.storage.getItem('clickID')],
        };
        if (!this.ofLegalAge) {
            controls['ofLegalAge'] = [false, Validators.requiredTrue];
        }
        if (this.userCode) {
            controls['user_code'] = [this.userCode];
        }
        this.form = this.fb.group(controls);
        document.getElementsByTagName('body')[0].classList.add('login-page');
    }

    ngOnDestroy() {
        document.getElementsByTagName('body')[0].classList.remove('login-page');
    }

    private initRegistrationSource() {
        // Guardar el sourcePage en el localstorage
        if (this.sourcePage && this.validSources.indexOf(this.sourcePage) >= 0) {
            this.storage.setItem('sourcePage', this.sourcePage);
            this.form.get('source').setValue(this.sourcePage);
            if (this.sourcePage == 'ojo7') {
                this.storage.setItem('clickID', this.clickId);
            }
        } else {
            this.storage.setItem('sourcePage', 'webapp');
            this.form.get('source').setValue('webapp');
        }
    }

    private fetchSources() {
        this.catalogService.getSources()
            .then(sources => {
                this.validSources = sources.map(s => s.slug);
                this.initRegistrationSource();
            })
            .catch(() => { });
    }

    public onSubmit(): void {
        this.clearErrors();
        if (!this.form.valid) {
            return;
        }
        this.buttonSubmitDisabled = true;
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.savingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        let data = this.form.value;
        data.email_confirmation = data.email;
        data.password_confirmation = data.password;
        data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.authService.register(data).then((response) => {
            this.googleAnalyticsService.eventEmitter("sign_up", "Auth", "email");
            this.matomoService.trackEvent("Auth", "sign_up", "email");
            let redirectUrl = '';
            this.activatedRoute.queryParamMap.subscribe(queryParams => {
                redirectUrl = queryParams.has('redirect') ? queryParams.get("redirect") : '';
            });
            Utils.alert(this.dialog, {
                data: {
                    message: response.message,
                    type: 'success'
                }
            });
            let credentials = {
                email: this.form.get('email').value,
                password: this.form.get('password').value
            };
            this.authService.login(credentials).then((response) => {
                this.authService.firstLogin = true;
                this.fetchUserData();
                this.googleAnalyticsService.setCurrentUser(response.user.id);
                // Redireccionar a verificación SMS
                if (data.source === 'ojo7' && data.click_id) {
                    this.router.navigate(['/verify-phone']);
                }
                else if (redirectUrl !== '') {
                    this.router.navigateByUrl(redirectUrl);
                }
                else {
                    this.router.navigate(['home'], {replaceUrl: true});
                }
            }).catch((response: HttpErrorResponse) => {
                if ([401, 422].indexOf(response.status) !== -1) {
                    this._snackBar.open(response.error.message, this.okText, {
                        duration: 8000
                    });
                    if (response.status === 422) {
                        if (response.error.errors) {
                            Object.keys(response.error.errors).forEach(key => {
                                this.errors[key] = [response.error.errors[key][0]];
                            });
                        }
                    }
                }
            }).finally(() => {
                setTimeout(() => ref.close(), 200);
            });
            // Limpiar variables en local storage
            this.storage.removeItem('sourcePage');
            this.storage.removeItem('clickID');
            this.storage.removeItem('userCode');
            this.storage.removeItem('onboarding');
        })
        .catch((response) => {
            if (response.status === 422) {
                this.formError = response.error.message;
                this._snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
            else if (response.message === 'Not token') {
                let snackBarRef = this._snackBar.open("No fue posible enviar la petición debido a un problema de autenticación, por favor recargue la página y vuelva a intentarlo", this.okText, {
                    duration: 15000
                });
                snackBarRef.afterDismissed().subscribe(null, null, () => {
                    window.location.reload();
                });
            }
            this.buttonSubmitDisabled = false;
        })
        .then(() => {
            ref.close();
        });
    }

    public fetchUserData() {
        this.userService.getMe().catch(error => {});
    }

    public onSocialUserLoggedIn(user: User) {
        this.fetchUserData();
    }

    public showTerms() {
        this.dialog.open(TermsConditionsComponent, {
            data: {
                isGuestUser: true,
            }
        });
    }

    public showPrivacy() {
        this.dialog.open(PrivacyComponent, {
            data: {}
        });
    }

    public login() {
        const queryParams: Params = {};
        if (this.sourcePage) {
            queryParams['source'] = this.sourcePage;
        }
        if (this.userCode) {
            queryParams['user_code'] = this.userCode;
        }
        this.router.navigate(['/auth/login'], { replaceUrl: true, queryParams })
    }
}
