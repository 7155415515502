export interface IBalance {
    available: number;
    requested: number;
    per_transfer: number;
}

export class Balance {
    public available: number;
    public requested: number;
    public perTransfer: number;

    constructor(data: IBalance) {
        this.available = data['available'];
        this.requested = data['requested'];
        this.perTransfer = data['per_transfer'];
    }
}
