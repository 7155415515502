import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { init, BrowserTracing, instrumentAngularRouting } from '@sentry/angular-ivy';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

init({
    dsn: environment.sentry.dsn,
    integrations: [
        new BrowserTracing({
            tracingOrigins: environment.sentry.tracingOrigins,
            routingInstrumentation: instrumentAngularRouting,
        }),
    ],
    tracesSampleRate: environment.sentry.tracesSampleRate,
});

if (['prod', 'staging'].indexOf(environment.env) !== -1) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
