<mat-card appearance="outlined">
    <mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title class="p-0">
            ¿Cómo subir tu ticket y ganar con Moneo?
        </mat-card-title>
    </mat-card-header>
    <div style="width: 50%;">
        <video muted autoplay loop playsinline>
            <source src="assets/img/tutorial/MONEO360x600low.mp4?ngsw-bypass=true" type="video/mp4" />
        </video>
    </div>
</mat-card>

