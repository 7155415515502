import { IFile } from './file';
import { Commerce, ICommerce } from './commerce';
import { IMasterProduct, MasterProduct } from './master-product';

export interface IProduct {
    id: number;
    uuid: string;
    name: string;
    cost: number;
    category: string;
    producer: string;
    description: string;
    commerce?: ICommerce;
    master_product?: IMasterProduct;
    photo?: IFile;
}

export class Product {
    public id: number;
    public uuid: string;
    public name: string;
    public cost: number;
    public category: string;
    public producer: string;
    public description: string;
    public commerce: Commerce;
    public masterProduct: MasterProduct;
    public photo: string;

    constructor(data: IProduct) {
        this.id = data.id;
        this.uuid = data.uuid;
        this.name = data.name;
        this.cost = data.cost;
        this.category = data.category;
        this.producer = data.producer;
        this.description = data.description;
        this.commerce = data.commerce ? new Commerce(data.commerce) : null;
        this.masterProduct = data.master_product ? new MasterProduct(data.master_product) : null;
        if (data.photo) {
            this.photo = data.photo.path;
        }
    }
}