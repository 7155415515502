<div>
    <p class="mb-2 subtitle">Acciones claves</p>
    <div class="key-actions gradient-border px-3 py-4">
        <div class="container p-0 d-flex justify-content-center align-items-center" style="flex-direction: column;">
            <button mat-raised-button color="primary" class="key-actions__btn d-block font-weight-bold p-2 w-75 mb-4 text-uppercase" (click)="redeem()">
                <span>Sube tus tickets</span>
                <i class="ms-2 font-weight-bold fas fa-question-circle" (click)="showTutorial()"></i>
            </button>
            <button mat-raised-button color="primary"
                    class="key-actions__btn d-block font-weight-bold p-2 w-75 mb-4 text-uppercase"
                    (click)="inviteFriends()"
                    *ngIf="user.activeInReferralCampaign">
                Invita a tus amigos
            </button>
            <button mat-raised-button color="primary" class="key-actions__btn d-block font-weight-bold p-2 w-75 mb-0 text-uppercase" (click)="profile()">
                Completa tu perfil
            </button>
        </div>
    </div>
</div>
