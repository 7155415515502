import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { WindowService } from './../../services/window.service';
import { UserService } from './../../services/user.service';
import { Advert } from './../../models/advert';

@Component({
  selector: 'app-adverts',
  templateUrl: './adverts.component.html',
  styleUrls: ['./adverts.component.scss']
})
export class AdvertsComponent implements OnInit {
    @ViewChild('advertsDiv', {static: false}) advertsDiv: ElementRef;
    @ViewChild('carousel', {static: false}) carousel: NgbCarousel;
    public activeSlide: string = 'slide-0';
    public advertIndex: number = 0;

    constructor(
        public dialogRef: MatDialogRef<AdvertsComponent>,
        public userService: UserService,
        public windowService: WindowService,
        @Inject(MAT_DIALOG_DATA) public data: Advert[],
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {
        this.setRootDivWidth();
    }

    @HostListener('window:resize')
    onResize() {
        this.setRootDivWidth();
    }

    public closeModal() {
        this.dialogRef.close();
    }

    private setRootDivWidth() {
        let divWidth = this.advertsDiv.nativeElement.offsetWidth;
        this.advertsDiv.nativeElement.style.width = divWidth + 'px';
    }

    public onSlide(payload: NgbSlideEvent) {
        this.activeSlide = payload.current;
    }

    public select(index: number) {
        this.advertIndex = index;
        this.carousel.select(`slide-${index}`);
    }

    public next() {
        if (this.advertIndex === this.data.length - 1) {
            this.dialogRef.close();
        } else {
            this.advertIndex++;
            this.carousel.next();
        }
    }

    public previous() {
        if (this.advertIndex > 0) {
            this.advertIndex--;
            this.carousel.prev();
        }
    }

    public onSwipe(evt) {
        const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
        if (x === 'left') {
            this.next();
        }
        else {
            this.previous();
        }
    }

    public close() {
        this.dialogRef.close();
    }

    public dontShowAgain(ad: Advert) {
        this.userService.dontShowAdvertAgain(ad.id)
            .then(_ => {
                if (this.data.length === 1) {
                    this.close();
                    return;
                }
                const i = this.data.findIndex(a => a.id == ad.id);
                if (i !== -1) {
                    this.data.splice(i, 1);
                }
            });
    }
}
