import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class QuestionService {

    constructor(
        private apiService: ApiService
    ) { }

    public logViewed(questionId: number): Promise<any> {
        return this.apiService.request('put', `questions/${questionId}/log`, {});
    }
}
