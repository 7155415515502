import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';
import { Campaign } from '../../models/campaign';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Balance } from '../../models/balance';
import { Utils } from '../../utils/index';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-donations',
    templateUrl: './donations.component.html',
    styleUrls: ['./donations.component.scss']
})

export class DonationsComponent implements OnInit {
    public form: UntypedFormGroup;
    public campaign: Campaign = null;
    public routerSubscription$: Subscription;
    public errors: { [k: string]: string[] } = {};
    public balance: Balance;
    public donationRequested: boolean = false;

    constructor(
        public dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private campaignService: CampaignService,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
        // Inicialiar form
        this.form = this.fb.group({
            donation_amount: [null, Validators.required],
            campaign_id: [0]
        });
        // Obtener datos de la campaña benéfica activa
        this.campaignService.getCurrentBeneficialCampaign({ include: 'beneficialCause.photo' })
        .then(response => {
            this.campaign = response;
            this.form.get('campaign_id').setValue(this.campaign.id);
        })
        .catch(error => { });
        // Obtener balance
        this.fetchBalance();
    }

    private fetchBalance() {
        this.userService.getMyBalance().then(balance => {
            this.balance = balance;
        })
        .catch(error => { });
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }
        const data = this.form.value;
        if (data['donation_amount'] > this.balance.available) {
            Utils.alert(this.dialog, {
                data: {
                    message: "No cuentas con saldo suficiente para donar la cantidad ingresada.<br>¡Sigue subiendo tus tickets!",
                    type: 'info'
                }
            });
            return;
        }
        this.donationRequested = true;
        Utils.confirm(this.dialog, {
            disableClose: true,
            data: {
                title: "Confirmar donación",
                message: "¿Desea confirmar la donación?",
                confirmButtonText: "Sí",
                cancelButtonText: "Cancelar",
                callback: () => {
                    this.campaignService.storeDonation(data).then((response) => {
                        this.fetchBalance();
                        this.form.get('donation_amount').setValue(null);
                        Utils.alert(this.dialog, {
                            data: {
                                message: response.message,
                                type: 'info'
                            }
                        });
                    }).catch((error: HttpErrorResponse) => {
                        if (error.status === 422) {
                            Utils.alert(this.dialog, {
                                data: {
                                    message: error.error.message,
                                    type: 'info'
                                }
                            });
                        }
                    }).finally(() => this.donationRequested = false);
                },
                onCancel: () => this.donationRequested = false,
            }
        });
    }
}
