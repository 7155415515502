<div role="group" class="tel-input-container" [formGroup]="parts"
    [attr.aria-labelledby]="_formField?.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
    <input type="tel" digitOnly class="tel-input-element fs-24px" formControlName="area" size="3" maxLength="3" aria-label="Area code"
        (input)="_handleInput(parts.controls.area, exchange)" #area>
    <span class="tel-input-spacer fs-24px">&ndash;</span>
    <input type="tel" digitOnly class="tel-input-element fs-24px" formControlName="exchange" maxLength="3" size="3"
        aria-label="Exchange code" (input)="_handleInput(parts.controls.exchange, subscriber)"
        (keyup.backspace)="autoFocusPrev(parts.controls.exchange, area)" #exchange>
    <span class="tel-input-spacer fs-24px">&ndash;</span>
    <input type="tel" digitOnly class="tel-input-element fs-24px" formControlName="subscriber" maxLength="4" size="4"
        aria-label="Subscriber number" (input)="_handleInput(parts.controls.subscriber)"
        (keyup.backspace)="autoFocusPrev(parts.controls.subscriber, exchange)" #subscriber>
</div>
