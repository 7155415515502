<div class="register-box">
    <div class="auth-content">
        <div class="register-logo mt-4">
            <h3 class="mb-0" *ngIf="sourcePage !== 'ojo7'">{{ 'Register' | translate }}</h3>
            <h1 class="title mb-3" *ngIf="sourcePage === 'ojo7'">
                Gana dinero real por compartir tus tickets de compra y contestar encuestas.
            </h1>
            <h2 class="subtitle mb-5" *ngIf="sourcePage === 'ojo7'">
                ¡Empieza hoy contestando 15 sencillas preguntas y gana $15 pesos de inmediato!
            </h2>
        </div>
        <app-social-login (userLoggedIn)="onSocialUserLoggedIn($event)" [sources]="validSources"></app-social-login>
        <p class="accept-terms">
            Al crear una cuenta, aceptas los
            <a href="#" (click)="$event.preventDefault(); showTerms();">Términos y condiciones</a>
            y el <a href="#" (click)="$event.preventDefault(); showPrivacy();">Aviso de Privacidad</a>
            de Moneo.
        </p>
        <p class="text-center font-weight-bold my-2 mb-4" style="opacity: .7;">
            {{ 'or' | translate }}
        </p>
        <div class="d-grid">
            <button type="button" class="btn btn-block btn-register-email" (click)="showForm = true">
                {{ 'Sign up with' | translate }} email
            </button>
        </div>
        <mat-card appearance="raised" class="register-content" *ngIf="showForm">
            <mat-card-content class="px-0">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <input type="hidden" formControlName="source" name="source" value="webapp">
                    <mat-form-field class="no-fill">
                        <mat-label>{{ "First name" | translate }}</mat-label>
                        <input type="text" matInput formControlName="first_name" name="first_name" required>
                        <mat-error *ngIf="form.get('first_name').errors?.['required']">
                            {{ 'Please fill this field' | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.get('first_name').errors?.['serverError']">
                            {{ form.get('first_name').errors?.['serverError'] }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="no-fill">
                        <mat-label>{{ "Last name" | translate }}</mat-label>
                        <input type="text" matInput formControlName="last_name" required name="last_name">
                        <mat-error *ngIf="form.get('last_name').errors?.['required']">
                            {{ 'Please fill this field' | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.get('last_name').errors?.['serverError']">
                            {{ form.get('last_name').errors?.['serverError'] }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="no-fill">
                        <mat-label>{{ "E-mail" | translate }}</mat-label>
                        <input type="text" matInput formControlName="email" name="email" required [errorStateMatcher]="matcher" autocomplete="new-password">
                        <mat-error *ngIf="form.get('email').errors?.['email']">
                            {{ 'Please enter a valid e-mail address' | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.hasError('emailNotConfirmed')">
                            {{ "The e-mail confirmation does not match" | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.get('email').errors?.['serverError']">
                            {{ form.get('email').errors?.['serverError'] }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="no-fill">
                        <mat-label>{{ "Password" | translate }}</mat-label>
                        <input type="password" matInput formControlName="password" name="password" required [errorStateMatcher]="matcher" autocomplete="new-password">
                        <mat-hint>La contraseña debe tener al menos una letra mayúscula y un número.</mat-hint>
                        <mat-error *ngIf="form.get('password').errors?.['required']">
                            {{ 'Please fill this field' | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.hasError('passwordNotConfirmed')">
                            {{ "The password confirmation does not match" | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.get('password').errors?.['serverError']">
                            {{ form.get('password').errors?.['serverError'] }}
                        </mat-error>
                    </mat-form-field>
                    <input type="hidden" formControlName="phone">
                    <p class="accept-terms mt-4" *ngIf="!ofLegalAge">
                        <mat-checkbox matInput formControlName="ofLegalAge" name="ofLegalAge" required autocomplete="new-password">
                            {{ "I'm of age" | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="form.get('ofLegalAge').errors?.['required']">
                            {{ 'You must indicate that you are of age' | translate }}
                        </mat-error>
                    </p>
                    <div class="row mt-4">
                        <div class="col-12 text-center mt-2">
                            <button type="submit" mat-raised-button color="primary" [disabled]="buttonSubmitDisabled">
                                {{ 'Create my account' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
        <p class="accept-terms">
            Al crear una cuenta, aceptas los
            <a href="#" (click)="$event.preventDefault(); showTerms();">Términos y condiciones</a>
            y el <a href="#" (click)="$event.preventDefault(); showPrivacy();">Aviso de Privacidad</a>
            de Moneo.
        </p>
        <p class="my-4 text-center font-weight-bold">
            {{ "Do you have an account?" | translate }} <br>
            <a href="#" (click)="$event.preventDefault(); login();" class="font-weight-bold">{{ 'Start your session' | translate }}</a>
        </p>
    </div>
</div>
