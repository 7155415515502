import { IFile } from "./file";

export interface IBanner {
    id: number;
    name: string;
    url?: string;
    is_active: boolean;
    created_at?: string;
    image?: IFile;
    image_path?: string;
}

export class Banner {
    public constructor(
        public id: number,
        public name: string,
        public isActive: boolean,
        public image: string,
        public url?: string,
        public createdAt?: Date,
    ) { }

    public static fromJson(data: IBanner): Banner {
        return new Banner(
            data.id,
            data.name,
            data.is_active,
            data.image ? data.image.path : null,
            data.url,
            new Date(data.created_at),
        );
    }
}