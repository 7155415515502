
<mat-card appearance="raised" class="profile-content">
    <!--<mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>{{ "My profile" | translate }}</mat-card-title>
    </mat-card-header>-->
    <mat-card-content>
        <!--<mat-divider></mat-divider>-->
        <div class="container">
            <div *ngIf="showProgress" class="user-data">
                <div class="row">
                    <div class="col-4">
                        <img [src]="user.avatar ?? '/assets/img/avatar.png'" alt="Avatar" class="d-block w-100 avatar rounded-circle mx-md-auto">
                    </div>
                    <div class="col-8 ps-0">
                        <div *ngIf="balance" class="progress-bar-container p-2 pb-3">
                            <div class="balance-progress-bar mb-2">
                                <div [class]="{'active': balance.available >= 0}"></div>
                                <div [class]="{'active': balance.available >= 5}"></div>
                                <div [class]="{'active': balance.available >= 10}"></div>
                                <div [class]="{'active': balance.available >= 15}"></div>
                                <div [class]="{'active': balance.available >= 20}"></div>
                                <div [class]="{'active': balance.available >= 25}"></div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="text-white">
                                    <p class="available-balance fs-20px line-height-normal fw-bold mb-0">
                                        {{ balance.available | currency }}
                                    </p>
                                    <p class="mb-0 fs-16px line-height-normal">
                                        Saldo
                                    </p>
                                </div>
                                <div *ngIf="balance.available >= 25" class="d-flex align-items-end icon-trophy">
                                    <i class="fas fa-trophy"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-md-4">
                        <p class="name fs-18px fw-bold text-md-center mt-3">
                            {{ user.firstName }} {{ user.lastName }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p *ngIf="user.job" class="fs-18px line-height-normal">{{ user.job }}</p>
                    </div>
                    <div class="col-6">
                        <div *ngIf="balance" class="text-end">
                            <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0">
                                {{ balance.perTransfer | currency }}
                            </p>
                            <p class="fs-16px line-height-normal">
                                Transferencia solicitada
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p *ngIf="user.age" class="fs-18px line-height-normal">{{ user.age }} años</p>
                    </div>
                    <div class="col-6">
                        <div *ngIf="balance" class="text-end">
                            <p class="cb-requested fs-18px line-height-normal fw-bold mb-0">
                                {{ balance.requested }}
                            </p>
                            <p class="fs-16px line-height-normal">
                                Tickets por validar
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button type="button" class="mb-2" mat-raised-button color="primary" (click)="enableEditProfile()"
                        style="background-color: #ffffff !important; color: #000;width: 100%;">
                        Editar perfil
                        <span *ngIf="numberEmptyProperties > 0" class="circle-badge d-inline-flex justify-content-center align-items-center mb-1 ms-1">{{ numberEmptyProperties }}</span>
                    </button>
                    <div *ngIf="balance && balance.available > 0">
                        <button type="button" class="mb-2" mat-raised-button  color="primary" (click)="requestTransfer()"
                            style="width: 100%;" [disabled]="!user.phoneVerifiedAt">
                            {{ "Request transfer" | translate }}
                        </button>
                        <button type="button" mat-raised-button class="btn-phone-recharge" color="primary" (click)="rechargePhone()"
                            matBadge="Nuevo" matBadgePosition="before" matBadgeColor="warn"
                            style="width: 100%;" [disabled]="!user.phoneVerifiedAt">
                            Recargas AT&T
                        </button>
                        <p class="text-start mt-2" *ngIf="!user.phoneVerifiedAt">
                            Para poder solicitar una transferencia o una recarga telefónica es necesario validar
                            tu número de celular mediante un código SMS.
                        </p>
                    </div>
                    <!-- <div class="mt-2">
                        <button type="button" class="btn-transfer" mat-raised-button  color="primary" (click)="donations()"
                            style="width: 100%; background-color: #00ADEF;">
                            Hacer donación
                        </button>
                    </div> -->
                    <div class="mt-2" *ngIf="!user.phoneVerifiedAt">
                        <button type="button" mat-raised-button  color="primary" (click)="verifyPhone()"
                            style="width: 100%;">
                            Validar tu número de celular
                        </button>
                    </div>
                </div>

                <div *ngIf="showTicketSection === null">
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-12">
                            <p class="name fs-18px fw-bold mt-3">
                                Tu historial
                            </p>
                            <hr style="height: 1.1px;background-color: black;">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem; color: #007bff;">Por validar</p>
                                <p class="fs-16px line-height-normal">
                                    No. de tickets: {{ history.count_requested}}
                                </p>
                                <button type="button" *ngIf="history.count_requested > 0" mat-flat-button class="px-1" (click)="showTickets('requested')"
                                    style="color: #007bff;">Ver tickets por procesar</button>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem;color: #28a745;">Aceptados o transferidos</p>
                                <p class="fs-16px line-height-normal">
                                    No. de tickets: {{ history.count_accepted}}
                                </p>
                                <button type="button" *ngIf="history.count_accepted > 0" mat-flat-button class="px-1" (click)="showTickets('accepted')"
                                    style="color: #007bff;">Ver tickets aceptados</button>
                            </div>
                        </div>
                        <div class="col-6">
                            <div *ngIf="history" class="text-end">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0">
                                    {{ history.total_accepted | currency }} CB
                                </p>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem;color: #dc3545">Rechazado</p>
                                <p class="fs-16px line-height-normal">
                                    No. de tickets: {{ history.count_refused}}
                                </p>
                                <button type="button" *ngIf="history.count_refused > 0" mat-flat-button class="px-1" (click)="showTickets('refused')"
                                    style="color: #007bff;">Ver tickets rechazados</button>
                            </div>
                        </div>
                        <div class="col-6">
                            <div *ngIf="history" class="text-end">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0">
                                    {{ history.total_refused | currency }} CB
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showTicketSection !== null">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-6">
                        <p *ngIf="showTicketSection === 'requested'" class="name fs-18px fw-bold mt-3">
                            Ticket por procesar
                        </p>
                        <p *ngIf="showTicketSection === 'accepted'" class="name fs-18px fw-bold mt-3">
                            Ticket aceptados o transferidos
                        </p>
                        <p *ngIf="showTicketSection === 'refused'" class="name fs-18px fw-bold mt-3">
                            Ticket rechazados
                        </p>
                    </div>
                    <div class="col-6">
                        <div class="text-end">
                            <p *ngIf="showTicketSection === 'requested'" class="per-transfer-balance fs-18px line-height-normal mb-0">
                                (puede tardar hasta 48 horas)
                            </p>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr style="height: 1.1px;background-color: black;">
                    </div>
                </div>

                <ng-container *ngIf="showTicketSection === 'requested'">
                    <div class="row" *ngFor="let cb of history.lastFiveRequestedCashbacks; let i = index"
                    style="margin-bottom: 10px;">
                        <div class="col-8" *ngIf="i < 5">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem;">{{ i+1 }}.- Ticket {{ cb.cashback_id}}</p>
                                <p class="fs-16px line-height-normal" style="color: #007bff;">
                                    {{ cb.created_at}}
                                </p>
                            </div>
                        </div>
                        <div class="col-4" *ngIf="i < 5">
                            <img [src]="cb.file_storage_path" class="d-block w-100 avatar mx-md-auto">
                        </div>

                        <div *ngIf="i >= 5" class="col-8"
                        [style.display]="showMoreTicketsR">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem;">{{ i+1 }}.- Ticket {{ cb.cashback_id}}</p>
                                <p class="fs-16px line-height-normal" style="color: #007bff;">
                                    {{ cb.created_at}}
                                </p>
                            </div>
                        </div>
                        <div *ngIf="i >= 5" class="col-4" [style.display]="showMoreTicketsR">
                            <img [src]="cb.file_storage_path" class="d-block w-100 avatar mx-md-auto">
                        </div>

                        <ng-container *ngIf="i == 4">
                            <button type="button" mat-flat-button (click)="showMoreTicket()" style="color: #007bff;">
                                Mostrar más
                            </button>
                        </ng-container>
                    </div>
                    <button type="button" mat-flat-button (click)="showTickets(null)" style="color: #007bff;">
                        Regresar a perfil
                    </button>
                </ng-container>
                <ng-container *ngIf="showTicketSection === 'accepted'">
                    <div class="row" *ngFor="let cb of history.lastFiveAcceptedCashbacks; let i = index"
                    style="margin-bottom: 10px;">
                        <div class="col-8" *ngIf="i < 5">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem;">{{ i+1 }}.- Ticket {{ cb.cashback_id}}</p>
                                <p class="fs-16px line-height-normal" style="color: #007bff;">
                                    {{ cb.created_at}}
                                </p>
                            </div>
                        </div>
                        <div class="col-4" *ngIf="i < 5">
                            <img [src]="cb.file_storage_path" class="d-block w-100 avatar mx-md-auto">
                        </div>
                        <div *ngIf="i >= 5" class="col-8"
                            [style.display]="showMoreTicketsR">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem;">{{ i+1 }}.- Ticket {{ cb.cashback_id}}</p>
                                <p class="fs-16px line-height-normal" style="color: #007bff;">
                                    {{ cb.created_at}}
                                </p>
                            </div>
                        </div>
                        <div *ngIf="i >= 5" class="col-4" [style.display]="showMoreTicketsR">
                            <img [src]="cb.file_storage_path" class="d-block w-100 avatar mx-md-auto">
                        </div>
                        <ng-container *ngIf="i == 4">
                            <button mat-flat-button (click)="showMoreTicket()" style="color: #007bff;">
                                Mostrar más
                            </button>
                        </ng-container>
                    </div>
                    <button mat-flat-button (click)="showTickets(null)" style="color: #007bff;">
                        Regresar a perfil
                    </button>
                </ng-container>
                <ng-container *ngIf="showTicketSection === 'refused'">
                    <div class="row" *ngFor="let cb of history.lastFiveRefusedCashbacks; let i = index" style="margin-bottom: 10px;">
                        <div class="col-8" *ngIf="i < 5">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem;">{{ i+1 }}.- Ticket {{ cb.cashback_id}}</p>
                                <p class="fs-16px line-height-normal" style="color: #007bff;">
                                    {{ cb.created_at}}
                                </p>
                            </div>
                        </div>
                        <div class="col-4" *ngIf="i < 5">
                            <img [src]="cb.file_storage_path" class="d-block w-100 avatar mx-md-auto">
                        </div>
                        <div *ngIf="i >= 5" class="col-8"
                            [style.display]="showMoreTicketsR">
                            <div *ngIf="history">
                                <p class="per-transfer-balance fs-18px line-height-normal fw-bold mb-0"
                                style="margin-bottom: 0.3rem;">{{ i+1 }}.- Ticket {{ cb.cashback_id}}</p>
                                <p class="fs-16px line-height-normal" style="color: #007bff;">
                                    {{ cb.created_at}}
                                </p>
                            </div>
                        </div>
                        <div *ngIf="i >= 5" class="col-4" [style.display]="showMoreTicketsR">
                            <img [src]="cb.file_storage_path" class="d-block w-100 avatar mx-md-auto">
                        </div>
                        <ng-container *ngIf="i == 4">
                            <button mat-flat-button (click)="showMoreTicket()" style="color: #007bff;">
                                Mostrar más
                            </button>
                        </ng-container>
                    </div>
                    <button mat-flat-button (click)="showTickets(null)" style="color: #007bff;">
                        Regresar a perfil
                    </button>
                </ng-container>
            </div>

            <form [formGroup]="form" class="pt-3 pb-3" *ngIf="showProgress == false">
                <div class="text-center mb-3">
                    <!--<img [src]="avatar" alt="avatar" class="w-25 rounded-circle"/>-->
                    <image-cropper [src]="avatar.contents ? avatar.contents : '/assets/img/avatar.png'"
                    (imageCropped)="onImageCropped($event)" height="350"
                    preview-class="w-25 rounded-circle" [hide-input]="!editing"
                    [ratio]="1" #cropper></image-cropper>
                </div>
                <mat-form-field class="no-fill">
                    <mat-label class="fs-18px">{{ "First name" | translate }}</mat-label>
                    <input type="text" class="fs-16px" matInput formControlName="first_name" required>
                    <mat-error *ngIf="form.get('first_name').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('first_name').errors?.['serverError']">
                        {{ form.get('first_name').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="no-fill">
                    <mat-label class="fs-18px">{{ "Last name" | translate }}</mat-label>
                    <input type="text" class="fs-16px" matInput formControlName="last_name" required>
                    <mat-error *ngIf="form.get('last_name').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('last_name').errors?.['serverError']">
                        {{ form.get('last_name').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="no-fill">
                    <mat-label class="fs-18px">{{ "Phone" | translate }}</mat-label>
                    <input type="text" class="fs-16px" matInput formControlName="phone">
                    <mat-error *ngIf="form.get('phone').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('phone').errors?.['pattern']">
                        {{ 'Please enter a valid phone' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('phone').errors?.['serverError']">
                        {{ form.get('phone').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="no-fill">
                    <mat-label class="fs-18px">{{ "E-mail" | translate }}</mat-label>
                    <input type="text" class="fs-16px" matInput formControlName="email">
                </mat-form-field>
                <mat-form-field class="no-fill">
                    <mat-label class="fs-18px">{{ 'Gender' | translate }}</mat-label>
                    <mat-select formControlName="gender" class="fs-16px">
                        <mat-option value="">{{ 'Select an option' | translate }}</mat-option>
                        <mat-option value="female">{{ 'female' | translate }}</mat-option>
                        <mat-option value="male">{{ 'male' | translate }}</mat-option>
                        <mat-option value="rather not say">{{ 'rather not say' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="no-fill">
                    <mat-label class="fs-18px">{{ 'Birthday' | translate }}</mat-label>
                    <input matInput class="fs-16px" [max]="maxBirthday" [matDatepicker]="picker" formControlName="birthday">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-hint>
                        {{ 'Enter a date' | translate }}. Ej. 04/12/1993
                    </mat-hint>
                    <mat-error *ngIf="form.get('birthday').errors?.['serverError']">
                        {{ form.get('birthday').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="no-fill">
                    <mat-label class="fs-18px">¿A qué te dedicas?</mat-label>
                    <input type="text" class="fs-16px" matInput formControlName="job">
                </mat-form-field>
                <mat-form-field class="no-fill">
                    <mat-label class="fs-18px">{{ "Password" | translate }}</mat-label>
                    <input type="password" class="fs-16px" matInput formControlName="password"
                    [errorStateMatcher]="matcher">
                    <mat-error *ngIf="form.get('password').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('password').errors?.['minlength']">
                        {{ 'The password must contain at least 8 characters' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.hasError('passwordNotConfirmed')">
                        {{ "The password confirmation does not match" | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('password').errors?.['serverError']">
                        {{ form.get('password').errors?.['serverError'] }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="no-fill" *ngIf="editing">
                    <mat-label class="fs-18px">{{ "Password confirmation" | translate }}</mat-label>
                    <input type="password" class="fs-16px" matInput formControlName="password_confirmation"
                    [required]="form.get('password').value.length > 0 && form.get('password').value !== '********'">
                    <mat-error *ngIf="form.get('password_confirmation').errors?.['required']">
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                </mat-form-field>
                <div class="row mt-3">
                    <div class="col-auto ms-auto" *ngIf="editing">
                        <button type="button" mat-raised-button color="secondary" (click)="cancelEditing()">
                            {{ 'Cancel' | translate }}
                        </button>
                    </div>
                    <div class="col-auto ms-0">
                        <button type="button" mat-raised-button color="primary" (click)="editProfile()">
                            {{ (editing ? 'Save' : 'Edit') | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
