import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppInstallService {
    private installEventSource = new BehaviorSubject<Event>(null);
    public installEvent$ = this.installEventSource.asObservable();
    private isStandaloneSource = new BehaviorSubject<boolean>(false);
    public isStandalone$ = this.isStandaloneSource.asObservable();
    public isStandalone: boolean = false;

    constructor() {
        window.addEventListener("load", () => {
            window.addEventListener('beforeinstallprompt', (e) => {
                this.installEventSource.next(e);
            });
            if (navigator && navigator.hasOwnProperty("standalone")) {
                this.isStandalone = true;
            }
            else if (matchMedia("(display-mode: standalone)").matches) {
                this.isStandalone = true;
            }
            else {
                this.isStandalone = false;
            }
            this.isStandaloneSource.next(this.isStandalone);
        });
    }
}
