
<mat-card appearance="raised" class="commerces-list">
    <mat-card-header>
        <div mat-card-avatar class="m-0">
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title class="px-0">Regresar</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h3 class="mb-2 mt-3">Da click para ver las ofertas</h3>
        <div class="row">
            <ng-container *ngFor="let commerce of commerces">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-2">
                    <div (click)="showDetails(commerce)" class="card-commerce" matRipple>
                        <img [src]="commerce.logo" alt="Logo" class="d-block mx-auto">
                        <h3 class="mt-3">{{ commerce.name }}</h3>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="loading">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-2" *ngFor="let item of loadingItems">
                    <div class="card-commerce">
                        <div class="d-block mx-auto">
                            <span class="skeleton-box rounded-circle" style="width: 120px; height: 120px"></span>
                        </div>
                        <p class="mt-3">
                            <span class="skeleton-box" style="width: 80%;"></span>
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>
