<mat-toolbar color="primary" class="app-toolbar"
        [class.innerlogo]="user !== null || ['register', 'guest.offers'].indexOf(currentRoute) !== -1">
    <button mat-icon-button class="header-icon"
            (click)="toggleMenuSidebar.emit()"
            aria-label="Menu"
            *ngIf="['register'].indexOf(currentRoute) === -1">
        <i class="fas fa-bars d-block"></i>
    </button>
    <button mat-icon-button class="header-icon" aria-label="Profile button"
            (click)="goToProfile()"
            *ngIf="['login', 'register', 'password.email', 'password.reset', 'guest.offers'].indexOf(currentRoute) === -1">
        <i class="fas fa-user d-block"></i>
    </button>
    <span class="spacer"></span>
    <button mat-icon-button class="header-icon" aria-label="Redeem button"
            (click)="redeem()"
            *ngIf="['login', 'register', 'password.email', 'password.reset', 'guest.offers'].indexOf(currentRoute) === -1">
        <i class="fas fa-camera d-block"></i>
    </button>
</mat-toolbar>
<div *ngIf="env !== 'prod'" class="dev-badge">
    <span *ngIf="['local', 'dev'].indexOf(env) !== -1">Estás navegando la versión de desarrollo de la aplicación.</span>
    <span *ngIf="env === 'staging'">Estás navegando la versión staging de la aplicación.</span>
</div>
