import { IFile } from './file';

export interface IAdvert {
    id: number;
    name: string;
    type: string;
    target_audience: string;
    is_active?: boolean;
    start_at?: string;
    end_at?: string;
    image?: IFile;
    image_path?: string;
}

export class Advert {
    constructor(
        public id: number,
        public name: string,
        public type: string,
        public targetAudience: string,
        public isActive: boolean,
        public startAt?: Date,
        public endAt?: Date,
        public image?: string,
    ) {}

    public static fromJson(data: IAdvert): Advert {
        return new Advert(
            data.id,
            data.name,
            data.type,
            data.target_audience,
            data.is_active,
            data.start_at ? new Date(data.start_at) : null,
            data.end_at ? new Date(data.end_at) : null,
            data.image ? data.image.path : null,
        );
    }
}