<mat-card appearance="outlined" class="commerce-card">
    <mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>{{ commerce ? commerce.name : '' }}</mat-card-title>
    </mat-card-header>
    <img mat-card-image [src]="commerce.photo ? commerce.photo : '/assets/img/no-image.png'"
         alt="Photo" *ngIf="commerce">
    <mat-card-content *ngIf="commerce">
        <!--<span class="text-muted d-block commerce-detail">{{ commerce.businessType }}</span>-->
        <p class="space-pre-wrap" [innerHTML]="commerce.description"></p>
        <div *ngIf="commerce.address">
            <span class="font-weight-bold d-block commerce-detail">{{ 'Address' | translate }}</span>
            <span class="text-muted d-block commerce-detail space-pre-wrap">{{ commerce.address }}</span>
        </div>
        <div (click)="gotoPhone(commerce)" *ngIf="commerce.publicPhone"
               class="mr-5 commerce-detail mb-2">
            <div class="row ml-0">
                <i class="fas fa-phone-alt phone-small"></i>
                <span class="float-left">{{ commerce.publicPhone }}</span>
            </div>
        </div>
        <span class="font-weight-bold d-block commerce-detail">{{ 'Hours' | translate }}</span>
        <span class="text-muted d-block commerce-detail">{{ commerce.hours }}</span>
        <div class="d-flex justify-content-center">
            <button mat-raised-button color="primary" (click)="redeem(commerce)" class="btn-transfer mt-2 mb-2">
                {{ "Request your cashback!" | translate }}
            </button>
        </div>
    </mat-card-content>
    <mat-card-actions class="text-center" *ngIf="commerce">
        <a mat-fab [href]="'mailto:' + commerce.publicEmail" *ngIf="commerce.publicEmail"
               color="primary">
           <mat-icon>email</mat-icon>
        </a>
    </mat-card-actions>
</mat-card>
