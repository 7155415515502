<div class="container p-0">
    <div class="home-offers">
        <app-latest-winners></app-latest-winners>

        <app-questionnaire-card *ngIf="user.questionnaires"
            [title]="quesCardData.title"
            [content]="quesCardData.content"
            [btnText]="quesCardData.btnTxt"
            [url]="quesCardData.url">
        </app-questionnaire-card>

        <div class="mt-3 mb-3">
            <app-favorite-commerces></app-favorite-commerces>
        </div>

        <div class="mb-3" (swipe)="onSwipeBannerCarousel($event)" *ngIf="banners">
            <ngb-carousel #bannerCarousel
                    [showNavigationArrows]="false"
                    [showNavigationIndicators]="false"
                    [pauseOnFocus]="false"
                    [pauseOnHover]="false"
                    [interval]="6000">
                <ng-template ngbSlide [id]="'banner-' + i" *ngFor="let banner of banners; index as i">
                    <div class="h-100">
                        <img class="d-block mw-100 mx-auto"
                                [src]="banner.image" alt="Banner"
                                (click)="handlerBannerClick(banner)">
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>

        <div class="mb-3">
            <app-key-actions [user]="user"></app-key-actions>
        </div>

        <div class="offers mt-3 mb-3">
            <p class="mb-2 subtitle">Las mejores ofertas especiales</p>
            <app-offers
                sortBy="random"
                [numItems]="16"
                [reload]="false"
                (cardClick)="showDetails($event)"
                (shareClick)="shareWa($event)"></app-offers>
        </div>
    </div>
</div>
