import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../utils/index';
import { filter, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppUpdateService {
    public headerText: string = "App update available";
    public msgText: string = "Choose OK to update";
    public okBtnText: string = "OK";
    public cancelBtnText: string = "Cancel";

    constructor(
        private readonly updates: SwUpdate,
        public translate: TranslateService,
        public dialog: MatDialog
    ) {
        this.updates.versionUpdates.subscribe(evt => {
            if (evt.type === 'VERSION_READY') {
                this.showAppUpdateAlert();
            }
        })
    }

    public showAppUpdateAlert() {
        let self = this;
        this.translate.get([this.headerText, this.msgText, this.okBtnText, this.cancelBtnText], {}).subscribe((trans) => {
            this.headerText = trans[this.headerText];
            this.msgText = trans[this.msgText];
            this.okBtnText = trans[this.okBtnText];
            this.cancelBtnText = trans[this.cancelBtnText];
            Utils.confirm(this.dialog, {
                data: {
                    title: this.headerText,
                    message: this.msgText,
                    confirmButtonText: this.okBtnText,
                    cancelButtonText: this.cancelBtnText,
                    callback: () => {
                        self.doAppUpdate();
                    }
                },
                disableClose: true,
            });
        });
    }

    doAppUpdate() {
        this.updates.activateUpdate().then(() => document.location.reload());
    }
}