<div class="invitation h-100" *ngIf="!showTerms">
    <div class="container h-100 d-flex flex-column justify-content-evenly py-3">
        <img src="assets/img/moneo-app.png" alt="App Moneo" class="invitation__app-img d-block mb-4 mx-auto">
        <div class="invitation__card p-3 pt-4 rounded-2">
            <h1 class="mb-4 text-center">¡Invita a tus amigos y gana más!</h1>
            <p class="text-center fw-bold">
                Comparte tu código y gana ${{ user.referralRewardAmount }} pesos cada vez que uno de tus
                invitados suba su primer ticket aceptado.
            </p>
            <p class="text-center mb-1 fw-bold">Tu código</p>
            <div class="invitation__link-box rounded-2 p-2">
                <p class="invitation__link mb-0 d-flex align-items-center">
                    <span class="w-100">
                        {{ shareLink }}
                    </span>
                </p>
                <button class="invitation__copy-btn d-flex align-items-center" type="button" (click)="copyCode()">
                    <span class="w-100">
                        <i class="fas fa-copy d-block"></i>
                        Copiar
                    </span>
                </button>
            </div>
            <p class="invitation__note font-weight-bold text-center mt-4 mb-0 fw-bold">
                ¡Entre más amigos invites, más ganarás!
            </p>
        </div>
        <p class="text-center mb-0">
            <a href="#" (click)="$event.preventDefault(); toggleTerms()" class="invitation__tyc-btn fw-bold text-white mt-3 d-block p-3 rounded-2">
                Términos y Condiciones
            </a>
        </p>
    </div>
</div>
<mat-card *ngIf="showTerms" style="box-shadow: none;">
    <mat-card-header>
        <div mat-card-avatar>
            <a href="#" (click)="$event.preventDefault(); toggleTerms();" class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>Regresar</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p class="mt-3 mb-2 font-weight-bold">Términos y Condiciones Programa de Referidos</p>
        <p class="font-italic">Última actualización: 2 de agosto de 2022</p>
        <p class="font-weight-bold">
            Introducción
        </p>
        <p>
            Este acuerdo estabilice los términos y condiciones para participar en el Programa de Referidos
            (“Programa”) de Yulah Ventures, SA. de CV. (junto con sus filiales,"Moneo").
        </p>
        <p>
            El Programa de Referidos es una forma de aumentar tus ganancias de Moneo al invitar a tus amigos
            o familiares que usen el servicio de Moneo. Puede ser una excelente manera de ganar un poco más
            y de ayudarnos a hacer crecer la comunidad de Moneo.
        </p>
        <p>
            Las reglas del Programa de Referidos (“Reglas”) se aplican a los programas administrados ú
            nicamente por Moneo.
        </p>
        <p>
            Al aceptar y compartir su enlace o código de referido, aceptas cumplir con estas Reglas. Cualquier
            incumplimiento te impedirá participar en otros programas de referidos de Moneo (en el presente o
            futuro) y podría causar la pérdida o reducción de ganancias obtenidas a través del programa e incluso
            la desactivación de tu cuenta.
        </p>
        <p>
            Estas Reglas complementan los Términos y Condiciones generales de Moneo que están disponibles aquí.
        </p>
        <p class="font-weight-bold">
            1. ¿Quiénes cumplen con los requisitos para referir/invitar?
        </p>
        <p>
            En este momento, la participación en el Programa es sólo por invitación, la cual será enviada
            por Moneo a una persona que ya es un usuario registrado de Moneo. Es estrictamente prohibido
            participar o tratar de participar si tú no has recibido una invitación personalizada por correo
            electrónico de Moneo.
        </p>
        <p class="font-weight-bold">
            2. ¿Quiénes cumplen con los requisitos para ser referidos/invitados?
        </p>
        <p>
            Cualquier persona referida necesita cumplir con las siguientes condiciones:
        </p>
        <ol>
            <li>Ser mayor de 18 años</li>
            <li>Ser un NUEVO usuario de Moneo</li>
            <li>No estar registrado en la aplicación de Moneo </li>
            <li>No haber estado registrado en la aplicación de Moneo</li>
        </ol>
        <p class="font-weight-bold">
            3. ¿Cómo puedo usar mi enlace?
        </p>
        <p>
            Al aceptar participar en el Programa, recibirás un enlace que podrás compartir con tus amigos
            y familiares. Moneo desea que compartas este enlace con tus amigos y familiares y que los
            invites a que usen Moneo.
        </p>
        <p>
            Moneo tiene el derecho de limitar el número de veces que puedes compartir tu enlace y puede
            desactivar el enlace sin previo aviso.
        </p>
        <p>
            El enlace sólo puede ser utilizado para propósitos personales y no comerciales. Sólo lo puedes
            compartir con personas que ya conoces. Aceptas lo siguiente:
        </p>
        <ol>
            <li>No duplicar, vender ni transferir tu enlace de ninguna manera ni ponerlo a disposición del público en general (p. ej., al imprimirlo en tarjetas de presentación, publicarlo en un sitio web de cupones o de empleo).</li>
            <li>No intentar referir/invitar a personas a través de mensajes no solicitados o correos electrónicos masivos. Sólo debes enviar el enlace a personas que ya conoces.</li>
            <li>No usar, presentar ni manipular la propiedad intelectual de Moneo (como logotipos, marcas registradas y trabajos protegidos por derechos de autor) de ninguna manera.</li>
            <li>No crear ni registrar ningún (i) negocio, (ii) URL, (iii) dominio, (iv) nombre o título de una app de software o (v) perfiles o identificadores de redes sociales que incluyan la palabra “Moneo” ni ninguna otra asociada con la marca o que pueda confundirse como tal.</li>
            <li>No usar las marcas registradas de Moneo como imagen de perfil o fondo de pantalla de sus redes sociales ni usar los trabajos protegidos por derechos de autor (como gráficos, fotos, imágenes, dibujos y capturas de pantalla del sitio web o de la app de Moneo) sin el permiso expreso y por escrito de Moneo.</li>
            <li>No usar sistemas automatizados ni bots a través de ningún canal para distribuir, publicar o compartir tu enlace.</li>
            <li>No usar secuencias de comandos ni marcadores programados o automáticos para enviar invitaciones o compartir tu enlace.</li>
            <li>No realizar declaraciones engañosas sobre Moneo, usar contenido ofensivo, crear sitios web/páginas web/perfiles de redes sociales/apps falsos, tergiversar su relación con Moneo ni realizar ninguna declaración falsa o engañosa para que los referidos/invitados usen tu enlace.</li>
            <li>No usar tu enlace de manera alguna que infrinja la ley o los derechos de los demás.</li>
        </ol>
        <p class="font-weight-bold">
            4. ¿Cómo obtengo Recompensas por referidos al referir/invitar a alguien?
        </p>
        <p>
            Para que puedas recibir una recompensa la persona referida por ti necesita cumplir con los Términos y
            Condiciones generales de Moneo y los requisitos descritos en la sección 2 de este documento.
        </p>
        <p>
            Además es necesario que la persona referida haga lo siguiente:
        </p>
        <ol>
            <li>Registrarse en la aplicación de Moneo</li>
            <li>Aceptar los Términos y Condiciones</li>
            <li>Subir un ticket de una tienda participante</li>
            <li>Recibir notificación de que el ticket se haya aceptado exitosamente</li>
        </ol>
        <p>
            Una vez que la persona referida haya logrado obtener un ticket aceptado, se clasificará como
        </p>
        <p>
            “Referido Calificado”. Por cada Referido Calificado asociado con tu enlace, recibirás una recompensa
            equivalente a MX $10 (diez pesos) la cual se reflejará en tu cuenta Moneo. Cabe mencionar que
            sólo recibirás esta recompensa la primera vez que el Referido Calificado tiene un ticket aceptado.
        </p>
        <p>
            Antes de ser dispersadas, las Recompensas no tienen ningún valor monetario y no puedes intercambiar o
            canjearlas para obtener dinero u otras formas de valor, salvo de la manera que se explica en la sección
            V.2 de los Términos y Condiciones de Moneo.
        </p>
        <p class="font-weight-bold">
            5. Finalización y cambios
        </p>
        <p>
            Moneo, sus subsidiarias y empresas afiliadas, y cada uno de sus respectivos directores, funcionarios,
            empleados, agentes, representantes, agencias de publicidad, promoción y cumplimiento, y asesores
            legales no son responsables y no serán responsables de (i) fallos telefónicos, electrónicos, de programas
            de hardware o software, de redes, de Internet o de ordenadores, fallos o dificultades de cualquier tipo,
            ya sean causados por el equipo, la programación utilizada en este Programa, el procesamiento humano o
            cualquier otra combinación de los mismos; (ii) transmisiones informáticas fallidas, incompletas,
            confusas o retrasadas; (iii) cualquier condición causada por eventos más allá del control de Moneo
            que puedan causar que el Programa se interrumpa o corrompa; (iv) cualquier lesión, pérdida o daño de
            cualquier tipo que surja en relación con o como resultado de las recompensas, o la aceptación, posesión
            o uso de las recompensas, o de la participación en el Programa; o (v) cualquier error de impresión o
            tipográfico en cualquier material asociado con el Programa.
        </p>
        <p>
            Moneo se reserva el derecho, a su entera discreción, de suspender o cancelar el Programa en
            cualquier momento si un virus informático, un error, una manipulación, una intervención no autorizada
            u otro problema técnico de cualquier tipo corrompe la administración, la seguridad o el correcto
            desarrollo del Programa. Moneo también se reserva el derecho a descalificar, a su entera discreción,
            cualquier persona que manipule el proceso de participación, el funcionamiento del sitio o la aplicación
            de Moneo, o que infrinja de alguna manera estos términos y condiciones. Cualquier intento de dañar el
            sitio o la aplicación de Moneo o de perjudicar el funcionamiento legítimo del Programamconstituye una
            violación de las leyes penales y civiles. En caso de que se produzca dicho intento, Moneo se reserva
            el derecho de perseguir a los infractores con todo el rigor de la ley.
        </p>
        <p class="mb-0">
            Moneo puede actualizar estas Reglas ocasionalmente. Si Moneo realiza una actualización, la publicará
            en la aplicación de Moneo. Continuar con tu participación en cualquier programa de referidos
            después de la actualización implica que aceptas los cambios.
        </p>
    </mat-card-content>
</mat-card>