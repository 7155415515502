<div class="container py-4">
    <h1 class="h3 font-weight-bold mb-0">¡Gracias por participar en nuestra trivia!</h1>
    <p class="my-3">Las respuestas correctas son las siguientes:</p>
    <div class="d-flex mb-2">
        <p class="mb-0 pr-1 font-weight-bold">1.</p>
        <p class="mb-0">
            <strong class="d-block">¿Cómo se llama el presidente de Ucrania?</strong>
            <em class="d-block">Volodímir Zelenski</em>
        </p>
    </div>
    <div class="d-flex mb-2">
        <p class="mb-0 pr-1 font-weight-bold">2.</p>
        <p class="mb-0">
            <strong class="d-block">¿Qué novela lanzó a la fama a Thalía?</strong>
            <em class="d-block">Quinceañera</em>
        </p>
    </div>
    <div class="d-flex mb-2">
        <p class="mb-0 pr-1 font-weight-bold">3.</p>
        <p class="mb-0">
            <strong class="d-block">¿Cuántos óscares ha ganado Johnny Depp?</strong>
            <em class="d-block">Ninguno</em>
        </p>
    </div>
    <div class="d-flex mb-2">
        <p class="mb-0 pr-1 font-weight-bold">4.</p>
        <p class="mb-0">
            <strong class="d-block">¿Quién ha anotado más goles en un mundial?</strong>
            <em class="d-block">Ronaldo Nazario da Lima</em>
        </p>
    </div>
</div>