<div>
    <p class="mb-2 subtitle d-flex align-items-center">
        <img src="assets/img/icono-mini-ganadores.svg" alt="Ícono ganadores" class="d-block me-1">
        Últimos ganadores
    </p>
    <div class="winners-container px-3">
        <div class="list-winners" #listWinners>
            <div *ngFor="let winner of latestWinners" class="list-winners__item d-flex align-items-center" [class.animate]="runAnimation">
                <img [src]="winner.avatar ? winner.avatar : '/assets/img/avatar.png'" alt="Avatar" class="list-winners__user-avatar d-block rounded-circle me-2">
                <p class="list-winners__user-name mb-0 text-white pe-1">{{ winner.fullName }}</p>
                <p class="list-winners__text mb-0 text-white pe-1">acaba de ganar</p>
                <div class="list-winners__user-cb px-2 ms-auto">{{ winner.amount | currency }}</div>
            </div>
            <ng-container *ngIf="!latestWinners">
                <div *ngFor="let num of [1,2,3]" class="list-winners__item-fake d-flex align-items-center">
                    <div class="list-winners__user-avatar-fake d-block rounded-circle me-2"></div>
                    <div class="list-winners__text-fake mb-0 pe-1">Lorem ipsum dolor sit amet</div>
                    <div class="list-winners__user-cb-fake px-2 ms-auto">2.55</div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
