<h1 mat-dialog-title *ngIf="data['title']">{{ data['title'] }}</h1>
<div mat-dialog-content>
    <p [innerHTML]="message"></p>
    <mat-form-field class="" *ngIf="data.showInput" style="width: 100%;">
        <input type="text" class="fs-16px" matInput [formControl]="input" [placeholder]="data.inputLabel" required >
        <mat-error *ngIf="input.invalid">
            {{ getErrorMessage() }}
        </mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>{{ data['confirmButtonText'] | translate }}</button>
    <button mat-button (click)="onNoClick()">{{ data['cancelButtonText'] | translate }}</button>
</div>