import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from './../../utils';
import { FormControl, Validators } from '@angular/forms';

export interface ConfirmDialogOptions {
    title?: string;
    message: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    showInput?: boolean;
    inputLabel?: string;
    onCancel?(): void;
    callback?(value?: any): void;
    inputValidator?(value?: any): Promise<string>;
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    public input = new FormControl('', [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions
    ) { }

    onNoClick(): void {
        if (this.data.onCancel) {
            this.data.onCancel();
        }
        this.dialogRef.close();
    }

    async onOkClick(): Promise<void> {
        let isValid = true;
        let validatorResult: string;
        if (this.data.showInput && this.input.invalid) {
            return;
        }
        if (this.data.inputValidator) {
            validatorResult = await this.data.inputValidator(this.input.value);
            isValid = validatorResult === null || validatorResult === '';
        }
        if (!isValid) {
            this.input.setErrors({
                custom: validatorResult,
            });
            return;
        }
        this.dialogRef.close(this.data.showInput ? this.input.value : 'OK');
    }

    public get message(): string {
        return Utils.nl2br(this.data.message);
    }

    public getErrorMessage(): string {
        if (this.input.hasError('required')) {
          return 'Por favor rellena este campo.';
        }
        return this.input.hasError('custom') ? this.input.errors['custom'] : '';
    }
}
