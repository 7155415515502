import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from './../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { UserService } from './../../services/user.service';
import { User } from '../../models/user';
import { Offer } from '../../models/offer';
import { Balance } from '../../models/balance';
import { Banner } from './../../models/banner';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    @ViewChild('bannerCarousel', {static: false})
    public bannerCarousel: NgbCarousel;
    public user: User;
    public userSubscription: Subscription;
    public balance: Balance;
    public quesCardData = {
        title: null,
        content: null,
        btnTxt: null,
        url: null,
    };
    public banners: Banner[];

    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        public googleAnalyticsService: GoogleAnalyticsService,
    ) {
        this.user = this.authService.getCurrentUser();
        this.fillCardData();
    }

    ngOnInit() {
        this.userSubscription = this.userService.authUserUpdated$.subscribe(user => {
            this.user = user;
            this.fillCardData();
        });
        this.userService.myBalance$.subscribe((balance) => {
            this.balance = balance;
        });
        this.getBanners();
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    private getBanners() {
        this.userService.getBanners()
            .then(banners => this.banners = banners)
            .catch(ex => { });
    }

    private fillCardData() {
        let questionnaires = this.user.questionnaires;
        if (!questionnaires) {
            return;
        }
        this.quesCardData.title = 'Tienes ' + questionnaires.length + ' encuestas por contestar';
        this.quesCardData.url = ['/encuestas'];
        this.quesCardData.btnTxt = 'Ver tus encuestas';
        if (questionnaires.length === 1) {
            // Solo cuando el usuario tenga un cuestionario
            this.quesCardData.title = questionnaires[0].bannerHeading;
            this.quesCardData.content = questionnaires[0].bannerContent;
            this.quesCardData.url.push(questionnaires[0].slug)
            this.quesCardData.btnTxt = questionnaires[0].bannerButtonText;
        };
    }

    public showDetails(offer: Offer) {
        this.googleAnalyticsService.eventEmitter("click_offer_details", "Offers", offer.getNameForGA());
        this.router.navigate(['commerces/', offer.commerce.uuid, 'offers'], {
            queryParams: { 'offer': offer.uuid }
        });
    }

    public shareWa(offer: Offer) {
        this.googleAnalyticsService.eventEmitter("click_share_offer_whatsapp", "Offers", offer.getNameForGA());
        let url = window.encodeURI(`${environment.webAppUrl}/guest/offers?offer=${offer.uuid}`);
        let msg = window.encodeURIComponent("Checa esta oferta en Moneo, la mejor app de cashback en México.");
        window.location.href = `whatsapp://send?text=${msg} ${url}`;
    }

    public onSwipeBannerCarousel(evt) {
        const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
        if (x === 'left') {
            this.nextBanner();
        }
        else {
            this.previousBanner();
        }
    }

    public nextBanner() {
        this.bannerCarousel.next();
    }

    public previousBanner() {
        this.bannerCarousel.prev();
    }

    public handlerBannerClick(banner: Banner) {
        let navigated = false;
        if (!banner.url) {
            return;
        }
        if (banner.url.startsWith(environment.webAppUrl)) {
            const path = banner.url.replace(environment.webAppUrl, '')
            if (/\/commerces\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\/offers$/.test(path)) {
                this.router.navigate(['commerces', path.replace('/commerces/', '').replace('/offers', ''), 'offers']);
                navigated = true;
            }
        }
        if (!navigated) {
            window.open(banner.url);
        }
    }
}
