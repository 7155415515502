import { IFile } from './file';

export class IMasterProduct {
    id: number;
    name: string;
    photo?: IFile;
}

export class MasterProduct {
    public id: number;
    public name: string;
    public photo: string;

    constructor(data: IMasterProduct) {
        this.id = data.id;
        this.name = data.name;
        if (data.photo) {
            this.photo = data.photo.path;
        }
    }
}