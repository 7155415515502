import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import Cropper from "cropperjs";
import { WindowService } from './../../services/window.service';
import { IFile } from './../../models/file';

@Component({
    selector: 'image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
    @ViewChild('image', { static: false }) image: ElementRef;
    @Input("src") public imageDestination: string;
    @Input("ratio") public aspectRatio?: number;
    @Input("has-error") public hasError?: boolean;
    @Input("preview-class") public previewClass?: string;
    @Input("hide-input") public hideInput?: boolean;
    @Input("zoomable") public zoomable?: boolean;
    @Input("drag-mode") public dragMode?: Cropper.DragMode;
    @Input("fullscreen") public fullScreen?: boolean;
    @Input("height") public height: string;
    @Output() imageCropped: EventEmitter<IFile> = new EventEmitter();

    public imageUrl: string = '';
    private cropper: Cropper;
    public showCropper: boolean = false;
    public hidePreview: boolean = false;
    public data: IFile = {
        name: null,
        contents: null
    }
    public MAX_WIDTH = 4000;
    public MAX_HEIGHT = 4000;
    public MIME_TYPE = "image/jpeg";
    public QUALITY = 0.8;

    constructor(public windowService: WindowService) {
        this.imageDestination = "";
    }

    ngOnInit() {
    }

    public readFile(e: any) {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.imageUrl = e.target.result;
                this.image.nativeElement.src = this.imageUrl;
                this.initCropper();
            };
            reader.readAsDataURL(e.target.files[0]);
            this.data.name = e.target.files[0].name;
        }
    }

    public initCropper() {
        let container = document.getElementsByClassName('crop-wrapper')[0] as any;
        this.showCropper = true;
        this.cropper = new Cropper(this.image.nativeElement, {
            aspectRatio: this.aspectRatio ? this.aspectRatio : NaN,
            dragMode: this.dragMode ? this.dragMode : 'move',
            movable: true,
            rotatable: true,
            scalable: true,
            zoomable: this.zoomable != null ? this.zoomable : true,
            checkCrossOrigin: true,
            guides: true,
            viewMode: 1,
            autoCropArea: .5,
            minContainerWidth: container.offsetWidth,
            minContainerHeight: 300,
            background: true
        });
    }

    public crop() {
        this.imageDestination = this.cropper.getCroppedCanvas({
            maxWidth: this.MAX_WIDTH,
            maxHeight: this.MAX_HEIGHT
        }).toDataURL(this.MIME_TYPE, this.QUALITY);
        this.data.contents = this.imageDestination;
        const i = this.data.name.indexOf(".");
        this.data.name = (i !== -1 ? this.data.name.substr(0, i) : this.data.name) + "." + (this.MIME_TYPE.split('/')[1]);
        this.showCropper = false;
        this.imageCropped.emit(this.data);
        this.cropper.destroy();
    }

    public destroy() {
        this.showCropper = false;
        this.cropper.destroy();
    }
}
