import { Component, OnInit } from '@angular/core';
import { NgForm, UntypedFormControl, FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TransferService } from '../../services/transfer.service';
import { CatalogueService } from '../../services/catalogue.service';
import { UserService } from '../../services/user.service';
import { WindowService } from '../../services/window.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Bank } from '../../models/bank';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from '../../utils/index';
import { Balance } from '../../models/balance';
import { HttpErrorResponse } from '@angular/common/http';

/** Error when invalid control is dirty, touched, or submitted. */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        let customError = false;
        if (control === form.form.get('account_clabe')) {
            customError = control.parent.hasError('clabeNotConfirmed');
        }
        const invalidParent = !!(control && control.parent && customError && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

@Component({
    selector: 'app-transfer',
    templateUrl: './transfer.component.html',
    styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {
    public form: UntypedFormGroup;
    public banks: Bank[];
    public matcher = new CustomErrorStateMatcher();
    public savingText: string = "Sending...";
    public okText: string = "OK";
    public balance: Balance;
    public transferRequested: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        public translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private transferService: TransferService,
        private catalogueService: CatalogueService,
        private userService: UserService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private windowService: WindowService,
    ) {
        this.translate.get([this.savingText, this.okText], {}).subscribe((trans) => {
            this.savingText = trans[this.savingText];
            this.okText = trans[this.okText];
        });
    }

    ngOnInit() {
        this.userService.myBalance$.subscribe((balance) => {
            this.balance = balance;
        });
        // Obtener bancos
        this.catalogueService.getBanks({}).then(result => {
            this.banks = result;
        }).catch(error => { }).finally(() => {
            //
        });
        const authCode = this.activatedRoute.snapshot.queryParams['authcode'];
        this.form = this.fb.group({
            account_fullname: ['', Validators.required],
            account_clabe: ['', [Validators.required, Validators.pattern(/^\d{18}$/)]],
            account_clabe_confirmation: ['', [Validators.required, Validators.pattern(/^\d{18}$/)]],
            account_bank: ['', Validators.required],
            accept_terms: [false, Validators.requiredTrue],
            authcode: [authCode ?? ''],
        }, {
            validators: [this.clabeConfirmedValidator]
        });
    }

    private clabeConfirmedValidator(form: UntypedFormGroup) {
        const matches = form.get('account_clabe').value === form.get('account_clabe_confirmation').value;
        return !matches ? { clabeNotConfirmed: true } : null;
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }
        this.transferRequested = true;
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.savingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        let data = this.form.value;
        this.transferService.store(data).then((response) => {
            this.googleAnalyticsService.eventEmitter("request_transfer", "Transfers");
            this.userService.getMyBalance().catch(error => { });
            Utils.alert(this.dialog, {
                data: {
                    message: response.message,
                    type: 'success'
                }
            });
            this.router.navigate(['home']);
        }).catch((response: HttpErrorResponse) => {
            if (response.status === 422) {
                this._snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
            this.transferRequested = false;
        }).then(() => {
            ref.close();
        });
    }
}
