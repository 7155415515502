import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { MatomoService } from '../../services/matomo.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
    @Input("user") public user: User;
    public currentRoute: string;
    public env: string;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public googleAnalyticsService: GoogleAnalyticsService,
        private matomoService: MatomoService,
    ) {
        this.env = environment.env;
    }

    ngOnInit() {
        let activeRoute = this.activatedRoute.firstChild;
        this.currentRoute = this.getRouteName(activeRoute);
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                return this.getRouteName(child);
            })
        ).subscribe((name: string) => {
            this.currentRoute = name;
        });
    }

    private getRouteName(child: ActivatedRoute) {
        while (child.firstChild) {
            child = child.firstChild;
        }
        if (child.snapshot.data.hasOwnProperty('name') && child.snapshot.data['name']) {
            return child.snapshot.data['name'];
        }
        return null;
    }

    public redeem() {
        let extras: NavigationExtras = {};
        if (this.currentRoute !== 'home') {
            extras.replaceUrl = true;
        }
        this.googleAnalyticsService.eventEmitter("click_redeem_on_header", "Cashbacks");
        this.router.navigate(['redeem'], extras);
    }

    public goToProfile() {
        this.matomoService.trackEvent('Profile', 'show_from_header');
        this.router.navigate(['profile']);
    }
}
