export interface IPhoneRechargeProduct {
    id: number;
    name: string;
    sku: string;
    carrier: string;
    cost: number;
    is_active?: boolean;
}

export class PhoneRechargeProduct {
    public constructor(
        public id: number,
        public name: string,
        public sku: string,
        public carrier: string,
        public cost: number,
        public isActive: boolean,
    ) { }

    public static fromJson(data: IPhoneRechargeProduct): PhoneRechargeProduct {
        return new PhoneRechargeProduct(
            data.id,
            data.name,
            data.sku,
            data.carrier,
            data.cost,
            data.is_active,
        );
    }

    public get fullName(): string {
        return `${this.sku} - ${this.name}`;
    }

    public patch(data: Partial<IPhoneRechargeProduct>) {
        this.name = data.name ?? this.name;
        this.sku = data.sku ?? this.sku;
        this.carrier = data.carrier ?? this.carrier;
        this.cost = data.cost ?? this.cost;
    }
}