import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MatomoService } from '../../services/matomo.service';
import { Commerce } from '../../models/commerce';
import { CommerceService } from '../../services/commerce.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
    selector: 'app-favorite-commerces',
    templateUrl: './favorite-commerces.component.html',
    styleUrls: ['./favorite-commerces.component.scss']
})
export class FavoriteCommercesComponent implements OnInit, AfterViewInit {
    @Input("numItems") public numItems: number;
    @Input("isGuestUser") public isGuestUser: boolean = false;
    @Input("showMoreOffersButton") public showMoreOffersButton: boolean = true;
    @Output() commerceClick: EventEmitter<Commerce> = new EventEmitter();
    @ViewChild('imgMore', { static: false }) imgMore: ElementRef;

    public loading: boolean = false;
    public loadingItems: number[] = [0, 1, 2];
    public commerces: Commerce[] = [];
    public iconMoreFs: string = "3.5rem";
    public columnsClass: string = 'col-3';

    constructor(
        private router: Router,
        public googleAnalyticsService: GoogleAnalyticsService,
        private commerceService: CommerceService,
        private matomoService: MatomoService,
    ) {
        if (!this.numItems) {
            this.numItems = 3;
        }        
    }
    ngAfterViewInit(): void {
        this.changeIconMoreFontSize();
    }

    ngOnInit(): void {
        if (this.showMoreOffersButton) {
            if (this.numItems === 4) {
                this.columnsClass = 'col-20p';
            }
        } else if (this.numItems === 3) {
            this.columnsClass = 'col-4';
        }
        this.fetchCommerces();
    }

    @HostListener('window:resize')
    onResize() {
        this.changeIconMoreFontSize();
    }

    private fetchCommerces(): void {
        this.loading = true;
        let params = {
            has_active_offers: 1,
            "no-paginate": 1,
            is_active: 1,
            sort: "slot|asc",
            include: 'logo'
        }
        let offers: Promise<Commerce[]>;
        if (this.isGuestUser) {
            offers = this.commerceService.getGuestCommerces(params)
        } else {
            offers = this.commerceService.getCommerces(params)
        }
        offers.then((commerces) => {
            this.commerces = commerces.slice(0, this.numItems);
            this.loading = false;
        })
        .catch(error => {});
    }

    public showCommerces() {
        this.googleAnalyticsService.eventEmitter('click_view_more_commerces', 'Commerces');
        this.matomoService.trackEvent('Commerces', 'click_view_more_commerces');
        this.router.navigate(['/commerces']);
    }

    public showCommerceDetails(commerce: Commerce) {
        this.commerceClick.emit(commerce);
        if (this.isGuestUser) {
            this.googleAnalyticsService.eventEmitter("click_commerce_guest_offers", "Commerces", commerce.name);
            this.router.navigate(['guest/offers'], {queryParams: {commerce: commerce.uuid}});
        } else {
            this.googleAnalyticsService.eventEmitter("click_commerce_details", "Commerces", commerce.name);
            this.router.navigate(['commerces/', commerce.uuid, 'offers']);
        }
    }

    private changeIconMoreFontSize() {
        if (this.imgMore) {
            Promise.resolve().then(() => this.iconMoreFs = `${this.imgMore.nativeElement.width / 1.3}px`);
        }
    }
}
