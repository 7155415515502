
<!--<app-header [parent]="self"
    (toggleMenuSidebar)="toggleMenuSidebar()"
    (toggleCustomSidebar)="toggleCustomSidebar()"
    [user]="user"></app-header>
<app-menu-sidebar></app-menu-sidebar>
-->
<div class="app-container" [class.is-mobile]="mobileQuery.matches">
    <app-header (toggleMenuSidebar)="toggleSidenav()" [user]="user"></app-header>

    <mat-sidenav-container class="app-sidenav-container"
            [style.marginTop.px]="headerHeight">
        <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="true" [fixedTopGap]="headerHeight">
            <app-sidebar [user]="user" (toggleMenuSidebar)="snav.toggle()"></app-sidebar>
        </mat-sidenav>

        <mat-sidenav-content>
            <!--<p *ngFor="let content of fillerContent">{{content}}</p>-->
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<!--<app-footer></app-footer>

<div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>

<app-profile #profile [user]="user" (profileUpdated)="onProfileUpdated($event)"></app-profile>-->
