import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private apiBaseUrl = 'http://localhost:3000/api';

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        public translate: TranslateService,
    ) {
        if (environment.apiUrl) {
            this.apiBaseUrl = environment.apiUrl;
        }
    }

    public downloadExportFile(urlPath: string, type: string, data: { [k: string]: any }): Promise<BlobPart> {
        let url: string = `${this.apiBaseUrl}/${urlPath}?to-excel=1`;
        const headers = this.authService.getHeaderData();
        const httpOptions: { [k: string]: any } = {
            headers: headers,
            responseType: 'blob'
        };
        if (data) {
            url += '&';
            Object.keys(data).forEach(key => {
                if (data[key] != null) {
                    url += key + '=' + data[key] + '&';
                }
            });
        }
        return firstValueFrom(this.http.get<BlobPart>(url, httpOptions));
    }

    public request<T = any>(method: string, urlPath: string, data: Object, bypassNgsw: boolean = false): Promise<T> {
        let url: string = `${this.apiBaseUrl}/${urlPath}`;
        let headers = this.authService.getHeaderData();
        if (bypassNgsw) {
            headers = headers.append('ngsw-bypass', 'true');
        }
        let httpOptions = {
            headers: headers
        };
        let req: Observable<T> = null;
        if (method === 'get') {
            if (data) {
                url += '?';
                Object.keys(data).forEach(key => {
                    if ([null, undefined, ''].indexOf(data[key]) === -1) {
                        url += key + '=' + data[key] + '&';
                    }
                });
            }
            req = this.http.get<T>(url, httpOptions);
        }
        else if (['post', 'put', 'patch'].indexOf(method) !== -1) {
            if (data instanceof FormData) {
                if (httpOptions.headers !== null) {
                    httpOptions.headers = httpOptions.headers.delete('Content-Type');
                }
                req = this.http[method]<T>(url, data, httpOptions);
            }
            else {
                req = this.http[method]<T>(url, data, httpOptions);
            }
        }
        else if (method === 'delete') {
            httpOptions['body'] = data;
            req = this.http.delete<T>(url, httpOptions);
        }
        return firstValueFrom(req);
    }

    public requestWithClientToken<T = any>(urlPath: string): Promise<T> {
        let auth = this.authService.getAuthData();
        if (!auth) {
            return Promise.reject({ message: "Not token" });
        }
        const url: string = `${this.apiBaseUrl}/${urlPath}`;
        return firstValueFrom(
            this.http.get<T>(url, {
                headers: {
                    Authorization: auth.tokenType + ' ' + auth.accessToken
                }
            })
        );
    }

    public downloadFile(filename: string, type: string, data: BlobPart) {
        let mime = 'application/vnd.ms-excel';
        let ext = 'xlsx';
        if (type === 'csv') {
            mime = 'text/csv';
            ext = 'csv';
        }
        else if (type === 'pdf') {
            mime = 'application/pdf';
            ext = 'pdf';
        }
        const blob = new Blob([data], { type: mime });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename + '.' + ext;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
