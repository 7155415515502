
<div class="image-cropper pt-2">
    <div class="text-center">
        <button type="button" mat-raised-button class="mb-2" color="primary"
                (click)="crop()" *ngIf="showCropper">Ajustar foto</button>
    </div>
    <div class="crop-wrapper" [class.hidden]="!showCropper" [class.fullscreen]="fullScreen"
           [style.height]="height ? height : 'inherit'">
        <img #image [src]="imageUrl" crossorigin>
    </div>
    <div class="img-preview" [class.hidden]="showCropper || hidePreview">
        <img *ngIf="imageDestination" [src]="imageDestination"
            [class]="previewClass ? previewClass : ''">
        <div *ngIf="!hideInput" style="padding-bottom: 3px;">
            <label for="cropper-file">
                {{ "Select image" | translate }}
            </label>
            <input id="cropper-file" type="file" class="form-control" accept="image/*"
                   [class.is-invalid]="hasError" (change)="readFile($event)">
        </div>
    </div>
</div>
