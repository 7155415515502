<div class="benefits" #benefitsDiv (swipe)="onSwipe($event)">
    <section class="benefits__body">
        <ngb-carousel #carousel [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="0" class="h-100" (slide)="onSlide($event)">
            <ng-template ngbSlide id="slide-0">
                <div class="picsum-img-wrapper h-100">
                    <img class="img-carousel d-block mw-100 mh-100 mx-auto" src="assets/img/benefits/benefit-2.png" alt="Beneficio 1">
                </div>
            </ng-template>
            <ng-template ngbSlide id="slide-1">
                <div class="picsum-img-wrapper h-100">
                    <img class="img-carousel d-block mw-100 mh-100 mx-auto" src="assets/img/benefits/benefit-1.png" alt="Beneficio 2">
                </div>
            </ng-template>
            <ng-template ngbSlide id="slide-2">
                <div class="picsum-img-wrapper h-100">
                    <img class="img-carousel d-block mw-100 mh-100 mx-auto" src="assets/img/benefits/benefit-3.png" alt="Beneficio 3">
                </div>
            </ng-template>
        </ngb-carousel>
    </section>
    <footer class="benefits__footer px-2 py-3">
        <button type="button" class="btn btn-link text-white font-weight-bold text-uppercase p-1" (click)="closeModal()">
            Saltar
        </button>
        <div class="benefits__indicators d-flex align-items-center justify-content-center">
            <div class="benefits__indicator" [class]="{'active': activeSlide === 'slide-0'}" (click)="select(0)"></div>
            <div class="benefits__indicator mx-1" [class]="{'active': activeSlide === 'slide-1'}" (click)="select(1)"></div>
            <div class="benefits__indicator" [class]="{'active': activeSlide === 'slide-2'}" (click)="select(2)"></div>
        </div>
        <button type="button" class="btn btn-link text-white font-weight-bold text-uppercase p-1" *ngIf="activeSlide !== 'slide-2'" (click)="tutorialNext()">
            Siguiente
        </button>
    </footer>
</div>
