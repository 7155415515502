<div class="onboarding bg-white py-3 h-100">
    <div class="container">
        <img src="assets/img/la-europea-logo.png" alt="La europea logo" class="d-block mw-100 mx-auto">
        <img src="assets/img/logo-full.png" alt="Moneo logo" class="d-block mt-2 mw-100 mx-auto" style="width: 120px;">
        <h1 class="onboarding__title text-center my-5 font-weight-bold">¡Felicidades!</h1>
        <p class="onboarding_text text-center mb-4 font-weight-bold h4">Estás a un paso de ganar cashback con La Europea</p>
        <p class="onboarding_text text-center mb-5 font-weight-bold h4">¡Dinero real, no puntos!</p>
        <a routerLink="/auth/register" mat-raised-button color="primary" class="onboarding__btn text-center d-block w-100 mb-3 mx-auto text-decoration-none">
            ¡Regístrate ya!
        </a>
        <button type="button" mat-raised-button color="blue" (click)="showTutorial()" class="onboarding__btn text-center d-block w-100 text-white mx-auto">
            Muéstrame cómo funciona
        </button>
    </div>
</div>