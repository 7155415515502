export interface ICause {
    id: number;
    slug: string;
    name: string;
    type: string;
}

export class Cause {
    constructor(
        public id: number,
        public slug: string,
        public name: string,
        public type: string,
    ) { }

    public static fromJson(data: ICause): Cause {
        return new Cause(
            data['id'],
            data['slug'],
            data['name'],
            data['type'],
        );
    }
}