<h2 class="blue-color text-center mt-3">Verificación por SMS</h2>
<p class="mt-3 text-center" *ngIf="phoneEntered === false">
    Antes de usar Moneo, necesitas verificar tu número celular.
</p>
<p class="mt-3 text-center" *ngIf="phoneEntered === false">
    Favor de ingresar tu número de celular.<br>
    Sólo números de México, 10 dígitos.
</p>

<p class="mt-3 text-center" *ngIf="phoneEntered === true">
  Tu número:
  {{ formatPhone(
      phoneForm.value.phone.area +
      phoneForm.value.phone.exchange +
      phoneForm.value.phone.subscriber)
  }}
</p>

<div class="verify-phone py-3 px-3">
    <form [formGroup]="phoneForm" (submit)="requestCode()" *ngIf="phoneEntered === false">
        <label>Número de teléfono (Ej. 5512345678)</label>
        <mat-form-field style="width: 100%" appearance="fill">
            <!-- Deactivated: see https://gitlab01.copyleft.no/moneo/webapp/-/issues/43 -->
            <!-- <span matPrefix class="fs-24px">+52 &nbsp;</span> -->
            <app-tel-input formControlName="phone" required [invalid]="phoneForm.get('phone').errors?.['serverError']"></app-tel-input>
            <mat-error *ngIf="phoneForm.get('phone').errors?.['required']">
                {{ 'Please fill this field' | translate }}
            </mat-error>
            <mat-error *ngIf="phoneForm.get('phone').errors?.['serverError']">
                {{ phoneForm.get('phone').errors?.['serverError'] }}
            </mat-error>
        </mat-form-field>
        <div class="text-center mt-2">
            <button type="submit" mat-raised-button color="primary" [disabled]="waitingForRetry">
                Enviar código
            </button>
        </div>
    </form>
    <form
        [formGroup]="verificationCodeform"
        (submit)="validateCode()"
        class="mt-5 text-center"
        *ngIf="phoneEntered === true">
        <label>
            Código de verificación
            <i class="fas fa-check-circle text-success ml-2" *ngIf="codeVerified"></i>
        </label>
        <div class="alert alert-danger" *ngIf="verificationCodeHasError">
            <i class="fas fa-times-circle text-danger ml-2"></i> El código no es válido.
        </div>
        <app-verification-code-input formControlName="code"></app-verification-code-input>
        <mat-error *ngIf="verificationCodeform.get('code').errors?.['serverError']">
            {{ verificationCodeform.get('code').errors?.['serverError'] }}
        </mat-error>
        <div class="mt-2">
            <button type="submit" mat-raised-button color="primary" [disabled]="!verifyButtonEnabled" *ngIf="!codeVerified">
                Verificar código
            </button>
            <button type="button" mat-raised-button color="primary" (click)="continue()" *ngIf="codeVerified">
                Continuar
            </button>
        </div>
    </form>
    <div class="mt-5 text-center" *ngIf="phoneEntered === true">
        <p class="mb-0">¿No te llegó el código?</p>
        <p>
          <button mat-raised-button color="secondary" [disabled]="waitingForRetry" (click)="requestCode()">
            Intenta de nuevo
            <ng-container *ngIf="waitingForRetry"> en {{ massageRemainingSeconds() }}</ng-container>
          </button>
        </p>
        <p class="mb-0">¿Ingresaste mal tu número?</p>
        <p><a href="#" (click)="$event.preventDefault(); clearForms();">Cambia tu número</a></p>
    </div>
</div>
