import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/user.service';
import { User } from './../../models/user';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-invite-friends',
    templateUrl: './invite-friends.component.html',
    styleUrls: ['./invite-friends.component.scss']
})
export class InviteFriendsComponent implements OnInit {
    public user: User;
    public showTerms: boolean = false;
    public userSubscription: Subscription;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        private snackBar: MatSnackBar,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.user = this.authService.getCurrentUser();
        this.userSubscription = this.userService.authUserUpdated$.subscribe(user => {
            this.user = user;
            if (!this.user.activeInReferralCampaign) {
                this.router.navigate(['/not-found']);
            }
        });
    }

    public get shareLink(): string {
        let url = `${environment.websiteUrl}/?user_code=${this.user.referralCode}`;
        if (this.user.activeReferralCampaign?.landingUrl) {
            url = this.user.activeReferralCampaign.landingUrl;
            if (!url.endsWith('?')) {
                url += '?';
            }
            url += `user_code=${this.user.referralCode}`;
        }
        return url;
    }

    public copyCode() {
        navigator.clipboard.writeText(this.shareLink)
            .then(() => {
                this.snackBar.open("Enlace copiado al portapapeles.", "Cerrar", {
                    duration: 8000
                });
            })
            .catch(() => { });
    }

    public toggleTerms() {
        this.showTerms = !this.showTerms;
    }
}
