import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {Utils} from '../../utils/index';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    public formError: string = '';
    public formSuccess: string = '';
    public form: UntypedFormGroup;
    public formFields: {[k: string]: any} = {
        email: ''
    };
    public savingText: string = "Sending request";
    public okText: string = "OK";

    constructor(
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public translate: TranslateService,
        private authenticationService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        this.translate.get([this.savingText, this.okText], {}).subscribe((trans) => {
            this.savingText = trans[this.savingText];
            this.okText = trans[this.okText];
        });
        this.clearErrors();
    }

    private clearErrors() {
        this.formError = '';
    }

    ngOnInit() {
        this.form = this.fb.group({
            email: ['', Validators.email]
        });
        document.getElementsByTagName('body')[0].classList.add('login-page');
    }

    ngOnDestroy() {
        document.getElementsByTagName('body')[0].classList.remove('login-page');
    }

    public onSubmit(): void {
        this.clearErrors();
        if (!this.form.valid) {
            return;
        }
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.savingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        this.formFields['email'] = this.form.get('email').value;
        this.authenticationService.requestPassword(this.formFields).then((response) => {
            this.formSuccess = response.message;
        })
        .catch((response) => {
            if (response.status === 422) {
                this._snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
            else if (response.message === 'Not token') {
                let snackBarRef = this._snackBar.open("No fue posible enviar la petición debido a un problema de autenticación, por favor recargue la página y vuelva a intentarlo", this.okText, {
                    duration: 15000
                });
                snackBarRef.afterDismissed().subscribe(_ => {
                    window.location.reload();
                });
            }
        })
        .then(() => {
            ref.close();
        });
    }

    public login() {
        const sourcePage = this.activatedRoute.snapshot.queryParams['source'];
        const userCode = this.activatedRoute.snapshot.queryParams['user_code'];
        const queryParams: Params = {};
        if (sourcePage) {
            queryParams['source'] = sourcePage;
        }
        if (userCode) {
            queryParams['user_code'] = userCode;
        }
        this.router.navigate(['/auth/login'], { replaceUrl: true, queryParams })
    }
}
