
export interface IWinner {
    id: number;
    first_name: string;
    last_name: string;
    amount: number;
    avatar: string;
    date: Date;
}

export class Winner {
    id: number;
    firstName: string;
    lastName: string;
    amount: number;
    avatar: string;
    date: Date;

    constructor(data: IWinner) {
        this.id = data['user_id'];
        this.firstName = data['first_name'];
        this.lastName = data['last_name'];
        this.amount = data['amount'];
        if (data['avatar']) {
            this.avatar = data['avatar'];
        }
        this.date = data['date'] ? new Date(data['date']) : null;
    }

    get fullName() {
        return this.getFullName();
    }

    public getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }
}
