export interface IRole {
    id: number;
    name: string;
    slug: string;
}

export class Role {
    id: number;
    name: string;
    slug: string;

    constructor(data: IRole) {
        this.id = data['id'];
        this.name = data['name'];
        this.slug = data['slug'];
    }
}