export interface IBank {
    name: string;
}

export class Bank {
    public name: string;

    constructor(data: IBank) {
        this.name = data.name;
    }
}
