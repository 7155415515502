import { Component } from '@angular/core';
import { NgOptimizedImage } from "@angular/common";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription, MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";

@Component({
  selector: 'template-community-simple',
  standalone: true,
  imports: [
    NgOptimizedImage,
    MatExpansionPanel,
    MatAccordion,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader
  ],
  templateUrl: './community-simple.component.html',
  styleUrl: './community-simple.component.scss'
})
export class CommunitySimpleComponent {

}
