import { Directive, HostListener } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';

@Directive({
    selector: '[backButton]'
})
export class BackButtonDirective {
    constructor(
        private location: Location,
        private router: Router
    ) { }

    @HostListener('click')
    onClick() {
        let state = this.location.getState();
        if (state.hasOwnProperty('navigationId')) {
            if (state['navigationId'] == 1) {
                this.router.navigate(['home'], {replaceUrl: true});
                return;
            }
        }
        this.location.back();
    }
}