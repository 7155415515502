import { ApiSaveResponse } from './../models/api-response';
import { ITransfer } from './../models/transfer';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class TransferService {

    constructor(
        private apiService: ApiService
    ) { }

    public store(data: ITransfer): Promise<ApiSaveResponse> {
        return this.apiService.request<ApiSaveResponse>('post', 'transfers', data);
    }
}
