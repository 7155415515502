import {Injectable, HostListener} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WindowService {
    public innerHeight: number;
    public innerWidth: number;

    constructor() {
        this.innerHeight = window.innerHeight;
        this.innerWidth = window.innerWidth;
    }
    
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.innerHeight = window.innerHeight;
        this.innerWidth = window.innerWidth;
    }
}
