import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WindowService } from 'src/app/services/window.service';

@Component({
    selector: 'app-modal',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss']
})

export class TutorialComponent implements OnInit, AfterViewInit {
    @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
    public vHeight: number;

    constructor(
        public dialogRef: MatDialogRef<TutorialComponent>,
        public windowService: WindowService,
        @Inject(MAT_DIALOG_DATA) public data: { [k: string]: any }
    ) {
    }

    ngAfterViewInit(): void {
        this.videoPlayer.nativeElement.onloadeddata = (event) => {            
            if (!event.target.muted) {
                // Un video con audio no se puede reproducir de forma automática, por eso es necesario ponerlo en silencio
                event.target.muted = true;
            }
            if (event.target.paused) {
                // Repoducir el video de forma automática
                event.target.play();
            }
        };
    }

    ngOnInit() {
        // 454 × 842
        const vh = 842;
        const vw = 454;
        const pvDialog = 140;
        const phDialog = 30;
        const padding = 32;
        let h = this.windowService.innerHeight - pvDialog;
        let w = (h * vw) / vh;
        if (w > (this.windowService.innerWidth - phDialog - padding)) {
            h = ((this.windowService.innerWidth - phDialog - padding) * vh) / vw;
        }
        this.vHeight = h;
    }

    public closeModal() {
        this.dialogRef.close();
    }
}