<div class="questionnaire-card gradient-border mt-3" *ngIf="showQuestionnaireBanner">
    <div class="close" *ngIf="showCloseBtn" (click)="closeBanner()">
        <span><i class="far fa-times-circle"></i></span>
    </div>
    <h3 class="mb-3">
        {{ title }}
    </h3>
    <p class="mb-3 content" *ngIf="content">
        {{ content }}
    </p>
    <div class="text-center">
        <button mat-raised-button color="primary" (click)="navigate()">
            {{ btnText }}
        </button>
    </div>
</div>
