import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utils } from '../../utils/index';
import { BROWSER_STORAGE } from '../../storage';
import { BenefitsComponent } from '../benefits/benefits.component';
import { WindowService } from '../../services/window.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatomoService } from '../../services/matomo.service';
import moment from 'moment';
import { User } from './../../models/user';
import { AdvertsComponent } from './../../components/adverts/adverts.component';
import { Advert } from './../../models/advert';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-complete-profile',
    templateUrl: './complete-profile.component.html',
    styleUrls: ['./complete-profile.component.scss']
})
export class CompleteProfileComponent implements OnInit {
    public user: User;
    public form: UntypedFormGroup;
    public maxBirthday: moment.Moment = moment();
    public userSubscription: Subscription;
    public notShowPage: boolean = false;

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public translate: TranslateService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        public googleAnalyticsService: GoogleAnalyticsService,
        public windowService: WindowService,
        private matomoService: MatomoService,
    ) { }

    public ngOnInit(): void {
        this.user = this.authService.getCurrentUser();
        this.userSubscription = this.userService.authUserUpdated$.subscribe(user => {
            this.user = user;
            this.readLocalStorage();
        });
        this.form = this.fb.group({
            gender: [this.user.gender],
            birthday: [this.user.birthday],
        });
        this.readLocalStorage();
    }

    private readLocalStorage() {
        let notShow = this.storage.getItem('show-complete-profile-page-' + this.user.id);
        this.notShowPage = notShow == '0';
    }

    public save(): void {
        if (!this.form.valid) {
            return;
        }
        let ref = Utils.alert(this.dialog, {
            data: {
                message: 'Guardando datos...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        let data = this.form.value;
        if (data.birthday) {
            data.birthday = data.birthday.format('DD/MM/YYYY');
        }
        this.userService.patchMe(data).then((response) => {
            this.googleAnalyticsService.eventEmitter("update_profile", "engagement");
            this.matomoService.trackEvent('Profile', 'update');
            this.storage.removeItem('show-complete-profile-page-' + this.user.id);
            this.goHomePage();
        }).catch((response: HttpErrorResponse) => {
            if (response.status === 422) {
                this._snackBar.open(response.error.message, "Aceptar", {
                    duration: 8000
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
        }).then(() => {
            ref.close();
        });
    }

    public goHomePage() {
        // if (this.authService.firstLogin) {
            // this.showBenefits();
        // }
        // else if (this.user.adverts) {
        // Si se descomenta el if ... else if anterior, borrar la siguiente línea
        if (this.user.adverts) {
            this.showAdverts(this.user.adverts);
        }
        this.router.navigate(['home'], { replaceUrl: true });
    }

    public skip() {
        this.notShowAgain();
        this.goHomePage();
    }

    private showBenefits() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-component-benefits";
        dialogConfig.maxWidth = (this.windowService.innerWidth - 30) + "px";
        this.dialog.open(BenefitsComponent, dialogConfig);
    }

    private showAdverts(adverts: Advert[]) {
        if (adverts.length === 0) {
            return;
        }
        const dialogConfig = new MatDialogConfig<Advert[]>();
        dialogConfig.id = "modal-component-adverts";
        dialogConfig.maxWidth = (this.windowService.innerWidth - 30) + "px";
        dialogConfig.data = adverts;
        this.dialog.open(AdvertsComponent, dialogConfig);
    }

    public notShowAgain() {
        this.storage.setItem('show-complete-profile-page-' + this.user.id, this.notShowPage ? '0' : '1');
    }
}
