import { Component } from '@angular/core';
import {
  CommunitySimpleComponent
} from "../../components/article-templates/community-simple/community-simple.component";

@Component({
  selector: 'app-articles',
  standalone: true,
  imports: [
    CommunitySimpleComponent
  ],
  templateUrl: './articles.component.html',
  styleUrl: './articles.component.scss'
})
export class ArticlesComponent {

}
