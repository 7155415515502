import {Inject, Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {NgForm, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import { CatalogueService } from './../../services/catalogue.service';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import {TranslateService} from '@ngx-translate/core';
import {TermsConditionsComponent} from '../terms-conditions/terms-conditions.component';
import {PrivacyComponent} from '../privacy/privacy.component';
import { AdvertsComponent } from './../../components/adverts/adverts.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BROWSER_STORAGE} from '../../storage';
import {Utils} from '../../utils/index';
import {Auth} from '../../models/auth';
import { WindowService } from './../../services/window.service';
import { MatomoService } from '../../services/matomo.service';
import { User } from './../../models/user';
import { Advert } from './../../models/advert';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginError: string = '';
    public submitted: boolean = false;
    form: UntypedFormGroup;
    public loginInvalid: boolean;
    public okText: string = "OK";

    public formFields: {[k: string]: any} = {
        email: '',
        password: ''
    };

    public errors: {[k: string]: string[]} = {};
    public redirectTo: string;
    public loggingText: string = "Logging in";
    public validSources: string[] = [];

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private fb: UntypedFormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private userService: UserService,
        private catalogService: CatalogueService,
        public googleAnalyticsService: GoogleAnalyticsService,
        public translate: TranslateService,
        private matomoService: MatomoService,
        public windowService: WindowService,
    ) {
        Object.keys(this.formFields).forEach(key => {
            this.errors[key] = [];
        });
        this.translate.get([this.loggingText, this.okText], {}).subscribe((trans) => {
            this.loggingText = trans[this.loggingText];
            this.okText = trans[this.okText];
        });
    }

    public saveAuthData(data: Auth): void {
        this.storage.setItem('app-token', JSON.stringify(data));
    }

    ngOnInit() {
        this.authService.clientToken$.subscribe((auth) => {
            if (auth && this.validSources.length === 0) {
                this.fetchSources();
            }
        });
        this.form = this.fb.group({
            email: ['', Validators.email],
            password: ['', Validators.required]
        });
        document.getElementsByTagName('body')[0].classList.add('login-page');
        this.activatedRoute.queryParamMap.subscribe(queryParams => {
            let session = queryParams.get("session");
            if (session) {
                session = window.atob(session);
                let r = new Auth(JSON.parse(session));
                this.saveAuthData(r);
                window.location.href = '/home';
            }
            this.redirectTo = queryParams.get("redirect")
        });
    }

    ngOnDestroy() {
        document.getElementsByTagName('body')[0].classList.remove('login-page');
    }

    private fetchSources() {
        this.catalogService.getSources()
            .then(sources => {
                this.validSources = sources.map(s => s.slug);
            })
            .catch(() => { });
    }

    public onLoginSubmit(form: NgForm): void {
        this.submitted = true;
        if (!form.form.valid) {
            return;
        }
        this.loginError = '';
        this.doLogin();
    }

    public doLogin() {
        this.loginError = '';
        if (!this.form.valid) {
            return;
        }
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.loggingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        this.formFields['email'] = this.form.get('email').value;
        this.formFields['password'] = this.form.get('password').value;
        this.authService.login(this.formFields).then((response) => {
            // Actualizar timezone del usuario
            let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.userService.updateTimezone(tz).then(response => {}).catch(error => {});
            this.userService.getMyBalance().catch(error => {});
            this.fetchUserData();
            this.googleAnalyticsService.setCurrentUser(response.user.id);
            this.googleAnalyticsService.eventEmitter('login', 'Auth', 'email');
            this.matomoService.setUser(response.user.id);
            this.matomoService.trackEvent('Auth', 'login', 'email');
            if (response.user.showCompleteProfilePage()) {
                this.router.navigate(['complete-profile']);
            }
            else if (this.redirectTo) {
                this.router.navigateByUrl(this.redirectTo, {replaceUrl: true});
            }
            else {
                this.router.navigate(['home'], {replaceUrl: true});
            }
        })
        .catch((response) => {
            if ([401, 422].indexOf(response.status) !== -1) {
                this._snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.status === 422) {
                    if (response.error.errors) {
                        Object.keys(response.error.errors).forEach(key => {
                            this.errors[key] = [response.error.errors[key][0]];
                        });
                    }
                }
            }
        })
        .finally(() => {
            setTimeout(() => ref.close(), 200);
        });
    }

    public fetchUserData() {
        this.userService.getMe()
            .then(response => {
                if (!response.mustCompleteProfile) {
                    this.showAdverts(response.adverts);
                }
            })
            .catch(error => {});
    }

    public onSocialUserLoggedIn(user: User) {
        this.fetchUserData();
    }

    public showTerms() {
        this.dialog.open(TermsConditionsComponent, {
            data: {
                isGuestUser: true,
            }
        });
    }

    public showPrivacy() {
        this.dialog.open(PrivacyComponent, {
            data: {}
        });
    }

    private showAdverts(adverts: Advert[]) {
        if (adverts.length === 0) {
            return;
        }
        const dialogConfig = new MatDialogConfig<Advert[]>();
        dialogConfig.id = "modal-component-adverts";
        dialogConfig.maxWidth = (this.windowService.innerWidth - 30) + "px";
        dialogConfig.data = adverts;
        this.dialog.open(AdvertsComponent, dialogConfig);
    }

    public forgotPassword() {
        this.googleAnalyticsService.eventEmitter('click_forgot_password', 'Auth');
        const queryParams = this.getQueryParams();
        this.router.navigate(['/auth/password/reset'], { queryParams })
    }

    public register() {
        const queryParams = this.getQueryParams();
        this.router.navigate(['/auth/register'], { replaceUrl: true, queryParams })
    }

    private getQueryParams(): Params {
        const sourcePage = this.activatedRoute.snapshot.queryParams['source'];
        const userCode = this.activatedRoute.snapshot.queryParams['user_code'];
        const queryParams: Params = {};
        if (sourcePage) {
            queryParams['source'] = sourcePage;
        }
        if (userCode) {
            queryParams['user_code'] = userCode;
        }
        return queryParams;
    }
}
