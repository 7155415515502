import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiPaginatorResponse } from '../models/api-response';
import { Commerce, ICommerce } from '../models/commerce';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CommerceService {
    public commerces: Commerce[];
    public commerce: Commerce;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private authService: AuthService,
        public router: Router
    ) { }

    public getCommerces(params: Object, reload: boolean = false): Promise<Commerce[]> {
        if (this.commerces && !reload) {
            return Promise.resolve(this.commerces);
        }
        if (params.hasOwnProperty('no-paginate') && params["no-paginate"] === 1) {
            return this.apiService.request<ICommerce[]>('get', 'commerces', params).then(result => {
                return this.commerces = result.map((data) => new Commerce(data));
            })
        }
        else {
            return this.apiService.request<ApiPaginatorResponse<ICommerce>>('get', 'commerces', params).then(result => {
                return this.commerces = result.data.map((data) => new Commerce(data));
            });
        }
    }
    public getGuestCommerces(params: { [k: string]: any}): Promise<Commerce[]> {
        let auth = this.authService.getAuthData();
        if (auth.user != null) {
            // Hay usuario autenticado redireccionar al home.
            this.router.navigate(['home']);
        }
        const url: string = `${environment.apiUrl}/guest/commerces`;
        const options = {
            headers: {
                Authorization: auth.tokenType + ' ' + auth.accessToken
            },
            params
        };
        if (params.hasOwnProperty('no-paginate') && params["no-paginate"] === 1) {
            return firstValueFrom(this.http.get<ICommerce[]>(url, options)).then((result) => {
                return result.map((data) => new Commerce(data));
            }).catch(error => Promise.reject(error));
        }
        else {
            return firstValueFrom(this.http.get<ApiPaginatorResponse<ICommerce>>(url, options)).then((result) => {
                return result.data.map((data) => new Commerce(data));
            }).catch(error => Promise.reject(error));
        }
    }

    public getCommerce(id: string, params: Object, reload: boolean = false): Promise<Commerce> {
        if (this.commerce && `${this.commerce.id}` === id && !reload) {
            return Promise.resolve(this.commerce);
        }
        return this.apiService.request<ICommerce>('get', 'commerces/' + id, params).then(result => {
            return new Commerce(result);
        });
    }
}
