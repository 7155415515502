import { Injectable } from '@angular/core';
import { Bank, IBank } from '../models/bank';
import { ApiService } from './api.service';
import { IPhoneRechargeProduct, PhoneRechargeProduct } from '../models/phone-recharge-product';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { Source, ISource } from '../models/source';

@Injectable({
    providedIn: 'root'
})
export class CatalogueService {
    public banks: Bank[];

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private authService: AuthService,
        public router: Router,
    ) { }

    public getBanks(params: Object): Promise<Bank[]> {
        if (this.banks) {
            return Promise.resolve(this.banks);
        }
        return this.apiService.request<IBank[]>('get', 'catalogues/banks', params).then(response => {
            this.banks = response.map((data) => new Bank(data));
            return this.banks;
        });
    }

    public getPhoneRechargeProducts(params: Object): Promise<PhoneRechargeProduct[]> {
        return this.apiService.request<IPhoneRechargeProduct[]>('get', 'catalogues/phone-recharge-products', params).then(response => {
            return response.map((data) => PhoneRechargeProduct.fromJson(data));
        });
    }

    public getPhoneRechargeProductsAsGuest(params: { [k: string]: any }): Promise<PhoneRechargeProduct[]> {
        let auth = this.authService.getAuthData();
        if (auth.user != null) {
            // Hay usuario autenticado redireccionar al home.
            this.router.navigate(['home']);
        }
        const url: string = `${environment.apiUrl}/guest/catalogues/phone-recharge-products`;
        const options = {
            headers: {
                Authorization: auth.tokenType + ' ' + auth.accessToken
            },
            params
        };
        return firstValueFrom(this.http.get<IPhoneRechargeProduct[]>(url, options)).then((result) => {
            return result.map((data) => PhoneRechargeProduct.fromJson(data));
        }).catch(error => Promise.reject(error));
    }

    public getSources(): Promise<Source[]> {
        return this.apiService
            .request<ISource[]>('get', 'guest/catalogues/sources', {})
            .then(resp => resp.map(r => Source.fromJson(r)));
    }
}
