<div class="px-3 pt-2 pb-3">
    <h1 class="h3 fw-bold text-center">Tus encuestas</h1>
    <ng-container *ngFor="let questionnaire of user.questionnaires">
        <app-questionnaire-card
            [title]="questionnaire.bannerHeading"
            [content]="questionnaire.bannerContent"
            [btnText]="questionnaire.bannerButtonText"
            [url]="['/encuestas', questionnaire.slug]">
        </app-questionnaire-card>
    </ng-container>
    <div class="pt-3" *ngIf="!user.questionnaires">
        <p class="text-center">Por el momento no cuenta con encuestas asignadas.</p>
    </div>
</div>