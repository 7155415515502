import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyComponent } from '../../pages/privacy/privacy.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
@Component({
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    constructor(
        public dialog: MatDialog,
        public translate: TranslateService,
        public googleAnalyticsService: GoogleAnalyticsService
    ) { }

    ngOnInit(): void {
    }

    public showPrivacy() {
        this.googleAnalyticsService.eventEmitter("click_menu_item", "Menu", "privacy");
        this.dialog.open(PrivacyComponent, {
            data: {}
        });
    }
}
