export {}

declare global {
    interface String {
        leftPad(padLength: number, padStr: string): string;
        ucFirst(): string;
    }
}

String.prototype.leftPad = function(padLength: number, padStr: string): string {
    var str = '';
    for (var i = 0; i < padLength; i++) {
        str += padStr;
    }
    str += this;
    return str.slice(-(padStr.length * padLength));
};

String.prototype.ucFirst = function() {
    return this.charAt(0).toUpperCase() + this.substr(1);
};
