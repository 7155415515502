import { IFile } from './file';
export interface ICommerce {
    id: number;
    uuid: string;
    name: string;
    address: string;
    website: string;
    public_email: string;
    public_phone: string;
    hours: string;
    business_type: string;
    description: string;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
    cashback_type: string;
    cashback_percent: string;
    is_active: number;
    logo: IFile;
    photo: IFile;
    created_at: string;
    terms_conditions: string;
}

export class Commerce {
    public id: number;
    public uuid: string;
    public name: string;
    public address: string;
    public website: string;
    public publicEmail: string;
    public publicPhone: string;
    public hours: string;
    public businessType: string;
    public description: string;
    public contactName: string;
    public contactEmail: string;
    public contactPhone: string;
    public cashbackType: string;
    public cashbackPercent: string;
    public isActive: boolean;
    public logo: string;
    public photo: string;
    public createdAt: Date;
    public termsConditions: string;

    constructor(data: ICommerce) {
        this.id = data.id;
        this.uuid = data.uuid;
        this.name = data.name;
        this.address = data.address;
        this.website = data.website;
        this.publicEmail = data.public_email;
        this.publicPhone = data.public_phone;
        this.hours = data.hours;
        this.businessType = data.business_type;
        this.description = data.description;
        this.contactName = data.contact_name;
        this.contactEmail = data.contact_email;
        this.contactPhone = data.contact_phone;
        this.isActive = data.is_active == 1 ? true : false;
        this.cashbackType = data.cashback_type;
        this.cashbackPercent = data.cashback_percent;
        this.createdAt = new Date(data.created_at);
        if (data.logo) {
            this.logo = data.logo.path;
        }
        if (data.photo) {
            this.photo = data.photo.path;
        }
        this.termsConditions = data.terms_conditions;
    }
}
