export const environment = {
    env: 'prod',
    locale: 'es',
    webAppUrl: 'https://app.moneo.cash',
    apiUrl: 'https://admin.moneo.cash/api/v1',
    websiteUrl: 'https://www.moneo.cash',
    apiClient: {
        id: 4,
        secret: 'IXiFtKlweOBdfINdKe2tAruUWWvzgukJXrxSTMSO'
    },
    sentry: {
        dsn: "https://0a651a2b289b4eaa82f7e76896881d84@sentry02.cl.clh.no/26",
        tracingOrigins: ['localhost', 'https://app.moneo.cash'],
        tracesSampleRate: 0.0,
    },
    analytics: {
        trackingCode: 'UA-169219218-1',
        trackingCodeGA4: 'G-5CQ18J1LCH',
    },
    google: {
        clientId: '160403764847-7sgo4hj77fjuk5t5jmp3gba353l2inu7.apps.googleusercontent.com'
    },
    facebook: {
        appId: '694162881461324',
        version: 'v19.0',
    },

    verifyPhone: {
        secondsToWait: 60,
    },
};
