import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { CommerceService } from '../../services/commerce.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Commerce } from '../../models/commerce';
import { MatomoService } from '../../services/matomo.service';

@Component({
    selector: 'app-commerces',
    templateUrl: './commerces.component.html',
    styleUrls: ['./commerces.component.scss']
})
export class CommercesComponent implements OnInit {
    public loading = false;
    public loadingItems: number[] = [0, 1, 2, 3];
    public commerces: Commerce[];

    constructor(
        private router: Router,
        private commerceService: CommerceService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private matomoService: MatomoService,
    ) { }

    ngOnInit() {
        this.getCommerces();
    }

    private getCommerces(): void {
        this.loading = true;
        let params = {
            has_active_offers: 1,
            "no-paginate": 1,
            is_active: 1,
            sort: "slot|asc",
            include: 'logo'
        };
        this.commerceService.getCommerces(params)
            .then(commerces => this.commerces = commerces)
            .catch(error => { })
            .then(() => {
                this.loading = false;
            });
    }

    public redeem(commerce: Commerce) {
        this.googleAnalyticsService.eventEmitter("click_redeem_on_commerce_details", "Cashbacks", commerce.name);
        let navigationExtras: NavigationExtras = {
            queryParams: { 'commerce': commerce.uuid }
        };
        this.router.navigate(['redeem'], navigationExtras);
    }

    public showDetails(commerce: Commerce) {
        this.googleAnalyticsService.eventEmitter("click_commerce_details", "Commerces", commerce.name);
        this.matomoService.trackEvent("Commerces", "click_commerce_details", commerce.name);
        this.router.navigate(['commerces/', commerce.uuid, 'offers']);
    }
}
