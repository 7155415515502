import { ApiSaveResponse } from './../models/api-response';
import { Injectable } from '@angular/core';
import { Campaign, ICampaign } from '../models/campaign';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})

export class CampaignService {
    public campaigns: Campaign[];
    public totalCampaigns: number;

    constructor(
        private apiService: ApiService
    ) { }

    public getCampaign(id: number, params: Object): Promise<Campaign> {
        return this.apiService.request<ICampaign>('get', `beneficial-campaigns/${id}`, params).then(result => {
            return Campaign.fromJson(result);
        });
    }

    public storeDonation(data: Object): Promise<ApiSaveResponse> {
        return this.apiService.request<ApiSaveResponse>('post', 'donations', data);
    }

    public getCurrentBeneficialCampaign(params: Object): Promise<Campaign> {
        return this.apiService.request<ICampaign>('get', `current-beneficial-campaign`, params).then(result => {
            return Campaign.fromJson(result);
        });
    }
}
