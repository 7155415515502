import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-material-datetime-picker',
  templateUrl: './material-datetime-picker.component.html',
  styleUrls: ['./material-datetime-picker.component.scss'],
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          multi: true,
          useExisting: MaterialDatetimePickerComponent
      },
      {
          provide: NG_VALIDATORS,
          multi: true,
          useExisting: MaterialDatetimePickerComponent
      }
  ]
})
export class MaterialDatetimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @Input() displayFormat = "DD/MM/YYYY HH:mm:ss";
    @Input() max?: moment.Moment;
    @Input() labelText?: string;
    @Input() placeholderText?: string;
    @Input() hintText?: string;
    @Input() required: boolean;
    public onChange = (option) => { };
    public onTouched = () => { };
    public touched: boolean = false;
    public disabled: boolean = false;
    public selectedDatetime: moment.Moment = null;
    public displayValue: string = null;
    public selectedDate: moment.Moment = null;
    public selectedTime: string = null;

    ngOnInit(): void { }
    ngOnDestroy(): void { }

    writeValue(val: moment.Moment): void {
        this.selectedDatetime = val;
        if (val) {
            this.selectedDate = val;
            this.selectedTime = `${val.format('HH:mm')}`;
            this.displayValue = this.selectedDatetime.format(this.displayFormat);
        }
    }

    public registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    public validate(control: AbstractControl<any, any>): ValidationErrors | null {
        if (this.required && !control.value) {
            return { required: "Por favor completa este campo" };
        }
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        //
    }

    public applySelection() {
        if (!this.selectedDatetime) {
            this.selectedDatetime = moment();
        }
        // Establecer fecha
        if (this.selectedDate) {
            this.selectedDatetime.year(this.selectedDate.year());
            this.selectedDatetime.month(this.selectedDate.month());
            this.selectedDatetime.date(this.selectedDate.date());
        }
        // Establecer hora
        if (this.selectedTime) {
            const parts = this.selectedTime.split(':').map(n => parseInt(n));
            this.selectedDatetime.hours(parts[0]);
            this.selectedDatetime.minutes(parts[1]);
        }
        this.onChange(this.selectedDatetime);
        this.displayValue = this.selectedDatetime.format(this.displayFormat);
    }
}
