import { Component, Inject, OnInit } from '@angular/core';
import { BROWSER_STORAGE } from '../../storage';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../utils/index';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SliderTutorialComponent } from '../../components/slider-tutorial/slider-tutorial.component';
import { WindowService } from '../../services/window.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

    private enterText: string = "At Moneo we are concerned with responsible consumption and we prevent the sale of alcohol to minors. Please answer responsibly.";
    private cancelButtonText: string = "Leave, I'm under 18";
    private confirmButtonText: string = "Enter, I'm over 18";

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private translate: TranslateService,
        private dialog: MatDialog,
        private windowService: WindowService,
        private activatedRoute: ActivatedRoute,
        ) { }

    ngOnInit(): void {
        let validOnboardingKeys = [
            'laeuropea1'
        ];
        let onboarding = this.activatedRoute.snapshot.queryParams['key'];
        
        // Guardar el onboarding en el localstorage
        if (onboarding && validOnboardingKeys.indexOf(onboarding) >= 0) {
            this.storage.setItem('onboarding', onboarding);
        } else {
            this.storage.setItem('onboarding', 'default');
        }
        if (this.storage.getItem('isOver18') !== '1') {
            this.translate.get([this.enterText, this.cancelButtonText, this.confirmButtonText, "OK"], {}).subscribe((trans) => {
                this.enterText = trans[this.enterText];
                this.cancelButtonText = trans[this.cancelButtonText];
                this.confirmButtonText = trans[this.confirmButtonText];
                Utils.confirm(this.dialog, {
                    disableClose: true,
                    data: {
                        message: this.enterText,
                        confirmButtonText: this.confirmButtonText,
                        cancelButtonText: this.cancelButtonText,
                        onCancel: () => {
                            window.location.href = 'http://www.alcoholinformate.org.mx';
                        },
                        callback: () => {
                            this.storage.setItem('isOver18', '1');
                        }
                    }
                });
            });
        }
    }

    public showTutorial() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = "dialog-slider-tutorial";
        dialogConfig.maxWidth = (this.windowService.innerWidth - 30) + "px";
        this.dialog.open(SliderTutorialComponent, dialogConfig);
    }

}
