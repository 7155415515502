import { ApiSaveResponse } from './../models/api-response';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Cashback, ICashback } from '../models/cashback';

@Injectable({
    providedIn: 'root'
})
export class CashbackService {

    constructor(
        private apiService: ApiService
    ) { }

    public getCashbacks(params: Object): Promise<Cashback[]> {
        return this.apiService.request<ICashback[]>('get', 'cashbacks/latest', params).then(result => {
            return result.map((data) => new Cashback(data));
        });
    }

    public store(data: Object): Promise<ApiSaveResponse> {
        return this.apiService.request<ApiSaveResponse>('post', 'cashbacks', data);
    }
}
