import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MatomoService {

    constructor() {}

    public trackPageView(url: string, title: string, user: User) {
        if (environment.env === 'local' || !this.isInitialized()) {
            return;
        }
        if (user) {
            this.setUser(user.id);
        }
        window['_paq'].push(['setCustomUrl', url]);
        window['_paq'].push(['setDocumentTitle', title]);
        window['_paq'].push(['trackPageView']);
    }

    public trackEvent(category: string, action: string, name?: string, value?: number) {
        if (environment.env === 'local' || !this.isInitialized()) {
            return;
        }
        const args: any[] = [category, action];
        if (name) {
            args.push(name);
        }
        if (value && typeof value === 'number') {
            args.push(value);
        }
        window['_paq'].push(['trackEvent', ...args]);
    }

    public setUser(userId: number) {
        if (environment.env === 'local' || !this.isInitialized()) {
            return;
        }
        window['_paq'].push(['setUserId', userId]);
    }

    private isInitialized(): boolean {
        if (typeof window['_paq'] === 'undefined') {
            console.warn('Matomo has not yet been initialized!');
            return false;
        }
        return true;
    }
}
