import moment from 'moment';
import { IQuestionnaire, Questionnaire } from './questionnaire';
import { IAdvert, Advert } from './advert';
import { Role, IRole } from './role';
import { IFile } from './file';
import { Campaign, ICampaign } from './campaign';

export interface IUser {
    id: number;
    role_id?: number;
    first_name: string;
    last_name: string;
    email: string;
    email_confirmation?: string;
    phone: string;
    role?: IRole;
    avatar?: IFile;
    is_active?: number;
    questionnaires?: IQuestionnaire[];
    created_at?: string;
    birthday?: string;
    gender?: string;
    age?: string;
    job?: string;
    avatar_path?: string;
    password?: string;
    password_confirmation?: string;
    timezone?: string;
    source?: string;
    click_id?: string;
    adverts?: IAdvert[];
    active_in_referral_campaign?: boolean;
    active_referral_campaign?: ICampaign;
    referral_reward_amount?: number;
    referral_code?: string;
    phone_verified_at?: Date | null;
    must_verify_phone?: boolean;
    must_complete_profile?: boolean;
}

export class User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: Role;
    avatar: string;
    isActive: boolean;
    questionnaires: Questionnaire[];
    createdAt: Date;
    birthday: moment.Moment;
    gender: string;
    age: number;
    job: string;
    source: string;
    adverts?: Advert[];
    activeInReferralCampaign?: boolean;
    activeReferralCampaign?: Campaign;
    referralRewardAmount?: number;
    referralCode?: string;
    phoneVerifiedAt?: Date | null;
    mustVerifyPhone?: boolean;
    mustCompleteProfile?: boolean;

    constructor(data: IUser) {
        this.id = data['id'];
        this.firstName = data['first_name'] || data['firstName'];
        this.lastName = data['last_name'] || data['lastName'];
        this.email = data['email'];
        this.phone = data['phone'];
        if (data['role']) {
            this.role = new Role(data['role']);
        }
        if (data['avatar']) {
            if (data['avatar'] instanceof Object) {
                this.avatar = data['avatar'].path;
            }
            else {
                this.avatar = data['avatar'];
            }
        }
        else if (data['avatarPath']) {
            this.avatar = data['avatarPath'];
        }
        this.isActive = data['is_active'] == 1 ? true : false;
        this.questionnaires = data['questionnaires'] && data['questionnaires'].length > 0 ?
                data['questionnaires'].map(questionnaire => Questionnaire.fromJson(questionnaire)) :
                null;
        this.createdAt = new Date(data['created_at'] || data['createdAt'])
        this.birthday = data['birthday'] ? moment(data['birthday']) : null;
        this.gender = data['gender'];
        this.age = data['age'] ? parseInt(data['age']) : null;
        this.job = data['job'];
        this.activeInReferralCampaign = data.active_in_referral_campaign;
        this.referralRewardAmount = data.referral_reward_amount;
        this.referralCode = data.referral_code;
        this.phoneVerifiedAt = data.phone_verified_at;
        this.mustVerifyPhone = data.must_verify_phone;
        this.adverts = data.adverts ? data.adverts.map(ad => Advert.fromJson(ad)) : [];
        this.source = data.source;
        this.mustCompleteProfile = data.must_complete_profile;
        this.activeReferralCampaign = data.active_referral_campaign ? Campaign.fromJson(data.active_referral_campaign) : undefined;
    }

    get fullName() {
        return this.getFullName();
    }

    public getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    public showCompleteProfilePage(): boolean {
        let showCompleteProfilePage = localStorage.getItem('show-complete-profile-page-' + this.id);

        if (this.mustCompleteProfile && (showCompleteProfilePage === null || showCompleteProfilePage == '1')) {
            return true;
        }
        return false;
    }

    public toJson(): IUser {
        return {
            id: this.id,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone: this.phone,
            is_active: this.isActive ? 1 : 0,
            created_at: moment(this.createdAt).format('YYYY-MM-DD\THH:mm:ss'),
            birthday: this.birthday ? this.birthday.format('YYYY-MM-DD') : null,
            gender: this.gender,
            age: `${this.age}`,
            job: this.job,
            source: this.source,
            role: this.role,
            role_id: this.role.id,
            avatar: { path: this.avatar },
            phone_verified_at: this.phoneVerifiedAt,
            must_verify_phone: this.mustVerifyPhone,
            must_complete_profile: this.mustCompleteProfile,
        }
    }
}
